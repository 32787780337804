import React, { useEffect, useState } from "react";
import { InputNumber } from "antd";
// import Balance from "./balance/balance";
import { useCamCam } from "./hooks";
import "./data-upload.less";
import TableCommon from "./table-common";
import ComponentButton from "../../atoms/ComponentButton";
import { uploadDataSource } from "../../api-hooks/report";
import { useDispatch, useSelector } from "react-redux";
import {
  setFirstDataSourceId,
  setSecondDataSourceId,
} from "../../store/report";
import { useRouter } from "../../routes";
import { CheckOutlined } from "@ant-design/icons";

// const fileAttributes = {
//   filename: "JE2021.xlsx",
//   fileSize: "235 MB",
//   lastModified: "03-04-2022 14:00:03",
// };

function CamCam({
  selectedSheet,
  previewDataSource,
  selectedFirstSheet,
  selectedSecondSheet,
  secondPreviewDataSource,
  selectedCurrentFile,
  selectedPreviousFile,
  setStepOneErrorMessage,
  selectedColumns,
  setSelectedColumns,
  rowsToDisable,
  handleRowsChange,
  setCount,
  count,
  currentStep,
}) {
  const {
    // rowsToDisable,
    // handleRowsChange,
    availableRecords,
    totalRecords,
    tableHeaders,
    tableRowsData,
    tableMetadata,
    totalColumns,
  } = useCamCam(
    selectedSheet,
    previewDataSource,
    selectedFirstSheet,
    selectedSecondSheet,
    secondPreviewDataSource,
    rowsToDisable
  );

  //   const [selectedColumns, setSelectedColumns] = useState({
  //     accountCol: {
  //       label: "Account name/code",
  //       id: "accountCol",
  //       col: -1,
  //     },
  //     debitCol: {
  //       label: "Debit",
  //       id: "debitCol",
  //       col: -1,
  //     },
  //     creditCol: {
  //       label: "Credit",
  //       id: "creditCol",
  //       col: -1,
  //     },
  //   });

  console.log(selectedColumns);

  const dispatch = useDispatch();

  const router = useRouter();

  const { query } = router;

  useEffect(() => {
    if (count > 0 && currentStep === 1) {
      const buttonText = getConfirmTitle();
      const isDisabled =
        selectedColumns?.accountCol?.col === -1 ||
        ((selectedColumns?.debitCol?.col === -1 ||
          selectedColumns?.creditCol?.col === -1) &&
          selectedColumns?.debitOrCreditCol?.col === -1);
      console.log(
        "Button text",
        buttonText,
        count,
        currentStep,
        selectedFirstSheet,
        isDisabled
      );
      if (buttonText === "Confirm" && !isDisabled) {
        onConfirm();
      }
    }
  }, [count]);

  const firstDataSourceId = useSelector(
    (state) => state.report.firstDataSourceId
  );

  const secondDataSourceId = useSelector(
    (state) => state.report.secondDataSourceId
  );

  const fileAttributes = {
    filename: selectedFirstSheet
      ? selectedCurrentFile?.name
      : selectedPreviousFile?.name,
    fileSize: selectedFirstSheet
      ? selectedCurrentFile?.size / 1024 + "KB"
      : selectedPreviousFile?.size / 1024 + "KB",
    lastModified: "03-04-2022 14:00:03",
  };

  const onConfirm = () => {
    if (
      selectedColumns?.accountCol?.col !== -1 &&
      ((selectedColumns?.debitCol?.col !== -1 &&
        selectedColumns?.creditCol?.col !== -1) ||
        selectedColumns?.debitOrCreditCol?.col !== -1)
    ) {
      const data = new FormData();
      data.append(
        "file",
        selectedFirstSheet ? selectedCurrentFile : selectedPreviousFile
      );
      data.append("reportPeriod", selectedFirstSheet ? "current" : "previous");
      data.append("startRow", rowsToDisable + 1);
      data.append("reportId", query?.reportId);

      data.append("accountCol", selectedColumns?.accountCol?.col);
      if (
        selectedColumns?.debitCol?.col !== -1 &&
        selectedColumns?.creditCol?.col !== -1
      ) {
        data.append("debitCol", selectedColumns?.debitCol?.col);
        data.append("creditCol", selectedColumns?.creditCol?.col);
      } else {
        data.append("debitOrCreditCol", selectedColumns?.debitOrCreditCol?.col);
      }

      uploadDataSource(data, (data) => {
        console.log("Upload Data Source", data);
        if (selectedFirstSheet) {
          dispatch(setFirstDataSourceId(data?.id));
          setStepOneErrorMessage("");
        } else {
          dispatch(setSecondDataSourceId(data?.id));
        }
      });
    }
  };

  const getConfirmTitle = () => {
    if (selectedFirstSheet) {
      if (firstDataSourceId) {
        return "Confirmed";
      } else {
        return "Confirm";
      }
    } else {
      if (secondDataSourceId) {
        return "Confirmed";
      } else {
        return "Confirm";
      }
    }
  };

  const getConfirmStyle = () => {
    if (selectedFirstSheet) {
      if (firstDataSourceId) {
        return { backgroundColor: "transparent", color: "#4BB354" };
      } else {
        return {};
      }
    } else {
      if (secondDataSourceId) {
        return { backgroundColor: "transparent", color: "#4BB354" };
      } else {
        return {};
      }
    }
  };

  const getConfirmIcon = () => {
    if (selectedFirstSheet) {
      if (firstDataSourceId) {
        return <CheckOutlined />;
      } else {
        // return {};
      }
    } else {
      if (secondDataSourceId) {
        return <CheckOutlined />;
      } else {
        // return {};
      }
    }
  };

  return (
    <>
      <section className={"right-comp"}>
        <header className={"section-header"}>
          <h2>Data Format Settings</h2>
          <ComponentButton
            title={getConfirmTitle()}
            onClick={onConfirm}
            icon={getConfirmIcon()}
            style={getConfirmStyle()}
            disabled={
              selectedColumns?.accountCol?.col === -1 ||
              ((selectedColumns?.debitCol?.col === -1 ||
                selectedColumns?.creditCol?.col === -1) &&
                selectedColumns?.debitOrCreditCol?.col === -1)
            }
          ></ComponentButton>
        </header>
        <main className={"section-body table-height-scrollbar"}>
          <div className={"file-attributes"}>
            <span
              className={"each-attribute"}
            >{`File Name: ${fileAttributes.filename}`}</span>
            <span
              className={"each-attribute"}
            >{`File Size: ${fileAttributes.fileSize}`}</span>
            <span
              className={"each-attribute"}
            >{`Last modified: ${fileAttributes.lastModified}`}</span>
          </div>
          <div className={"disregard-row"}>
            <span>{"Disregard first "}</span>
            <InputNumber
              value={rowsToDisable}
              onChange={handleRowsChange}
              min={0}
              style={{
                margin: "0px 8px",
                width: "49px",
                height: "36px",
              }}
            />
            <span>{" row(s)"}</span>
          </div>
          <div className={"records-count"}>
            {`Showing first`} <b>{availableRecords}</b>{" "}
            {`record${availableRecords > 1 ? "s" : ""} out of`}{" "}
            <b>{totalRecords}</b> {`record${totalRecords > 1 ? "s" : ""}`}
          </div>
          <TableCommon
            tableHeaders={tableHeaders}
            tableRowsData={tableRowsData}
            tableMetadata={tableMetadata}
            totalColumns={totalColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            selectedFirstSheet={selectedFirstSheet}
          />
        </main>
      </section>
      {/* <Balance /> */}
    </>
  );
}

export default CamCam;
