import ContentCard from "../../atoms/ContentCard";
import React, {
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import "./CreateOrEditReport.less";
// import Title from "../common/Title";
import LeftControl from "../../sub-components/LeftControl";
import RightControl from "../../sub-components/RightControl";
import Title from "../../atoms/Title";

import { ReactComponent as ExpandIcon } from "../../assets/svg-icons/expandIcon.svg";
import { ReactComponent as CollapseIcon } from "../../assets/svg-icons/collapseIcon.svg";
import { ReactComponent as SettingIcon } from "../../assets/svg-icons/settingIcon.svg";

import ReportSections from "../../sub-components/ReportSections";
import PreviewHtml from "../../sub-components/PreviewHtml";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentInput from "../../atoms/ComponentInput";
import TextSection from "../../sub-components/TextSection";
import ComponentTextArea from "../../atoms/ComponentTextArea";
import {
  getReportGeneratorHtmlHook,
  updateReportGeneratorHtmlHook,
  updateReportGeneratorsHook,
  updateReportSectionsHook,
} from "../../api-hooks/report";
import { debounce } from "lodash";

import { changePdfCollapsedState } from "../../store/user";
import { Tooltip } from "antd";
import { ReactComponent as PreviewPDFIcon } from "../../assets/svg-icons/previewIcon.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import EquationPreviewSheet from "./EquationPreviewSheet";
import PreviewPDF from "../../sub-components/PreviewPDF";
import { useRouter } from "../../routes";
import expandIcon from "../../assets/svg-icons/expandSidebar.svg";

const StepFive = ({ boxHeight, leftEditKey, leftEdit }) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { query } = router;
  const [activeUnit, setActiveUnit] = useState({}); //选中的'元件'

  const [activePreviewUnit, setActivePreviewUnit] = useState(""); //选中的'元件'

  const [collapsed, setCollapsed] = useState(false); //false展开 | true收起

  const [refreshKey, setRefreshKey] = useState("");
  // right collpase state
  const [rightCollapsed, setRightCollapsed] = useState(false);

  const [isFormulaEnabled, setIsFormulaEnabled] = useState(false);
  const [showPreviewSheet, setShowPreviewSheet] = useState(false);

  const [content, setContent] = useState("");
  const [previewContent, setPreviewContent] = useState("");

  const progressStatusBlock = () => {
    return (
      <div style={{ marginTop: "25px", marginBottom: "28px" }}>
        <div className={"title"} style={{ marginBottom: "8px" }}>
          <b id="id-b-1616">
            Progress Status
            {/* <FormattedMessage id="titles" defaultMessage="标题" /> */}
          </b>
        </div>
        <div className={"input"}>
          <ComponentDropdown
            style={{ width: "100%" }}
            optionList={[
              {
                label: "in progress",
                value: "IN_PROGRESS",
              },
              {
                label: "require review",
                value: "REQUIRE_REVIEW",
              },
              {
                label: "completed",
                value: "COMPLETED",
              },
            ]}
            value={activeUnit?.data?.status}
            handleChange={(value) => {
              let updatedActiveUnit = { ...activeUnit };
              updatedActiveUnit = {
                ...updatedActiveUnit,
                data: {
                  ...updatedActiveUnit.data,
                  status: value,
                },
              };
              // activeUnit.data.name = value;
              setActiveUnit({ ...updatedActiveUnit });
              updateSectionData(
                updatedActiveUnit?.data?.html,
                updatedActiveUnit?.data?.name,
                updatedActiveUnit,
                value
              );
            }}
          />
        </div>
      </div>
    );
  };

  const debounceUpdate = useCallback(
    debounce(
      (html, name, id, type, status) =>
        updateReportSectionsHook(
          {
            id: id,
            type: type,
            html: html,
            name: name,
            status: status,
          },
          (response) => {
            console.log("Section Updated", response);
            setRefreshKey(Math.random());
          }
        ),
      1000
    ),
    [] // will be created only once initially
  );

  const updateSectionData = (html, name, activeUnit, status) => {
    console.log("Active Ubnit", activeUnit, html);
    debounceUpdate(html, name, activeUnit?.id, activeUnit?.type, status);
  };

  const titleBlock = () => {
    return (
      <>
        {progressStatusBlock()}
        <div
          className={"title-block"}
          style={{ marginTop: "25px", marginBottom: "21px" }}
        >
          <div className={"title"}>
            <b id="id-b-1616">
              Title
              {/* <FormattedMessage id="titles" defaultMessage="标题" /> */}
            </b>
          </div>
          <div className={"input"}>
            <ComponentTextArea
              style={{ width: "100%" }}
              value={activeUnit?.data?.name}
              onChange={(value) => {
                let updatedActiveUnit = { ...activeUnit };
                updatedActiveUnit = {
                  ...updatedActiveUnit,
                  data: {
                    ...updatedActiveUnit.data,
                    name: value,
                  },
                };
                // activeUnit.data.name = value;
                setActiveUnit({ ...updatedActiveUnit });
                updateSectionData(
                  updatedActiveUnit?.data?.html,
                  value,
                  updatedActiveUnit,
                  updatedActiveUnit?.data?.status
                );
              }}
              // onBlur={(e) => {
              //   let _val = e.target.value;
              //   let _data = { ...activeUnit, label: _val };
              //   ctrlObj.updateUnitData(store, unitConfigList, _data);
              //   e.preventDefault();
              //   e.stopPropagation();
              //   e.persist();
              // }}
              // onChange={(e) => {
              //   let _val = e.target.value;
              //   setTitleVal(_val);
              //   e.preventDefault();
              //   e.stopPropagation();
              //   e.persist();
              // }}
            />
          </div>
        </div>
      </>
    );
  };

  const isCollapsed = useSelector((state) => state.user.isPdfCollapsed);

  const setIsCollapsed = (value) => {
    dispatch(changePdfCollapsedState(value));
  };

  const onSaveContent = (callback) => {
    callback();
    // updateReportGeneratorHtmlHook(
    //   {
    //     reportId: query?.reportId,
    //     content: content,
    //   },
    //   (response) => {
    //     getReportGeneratorHtmlHook(
    //       { reportId: query?.reportId },
    //       (response) => {
    //         if (response) {
    //           setPreviewContent(response.previewContent);
    //           callback();
    //         }
    //       }
    //     );
    //   }
    // );
  };

  const sections = useSelector(
    (state) => state.report?.selectedReport?.sections
  );

  console.log("Left Report Sections", sections);

  return (
    <div style={{ display: "flex", flex: "1 1 auto" }}>
      <LeftControl
        boxHeight={boxHeight}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        rightCollapsed={rightCollapsed}
      >
        {rightCollapsed && (
          <div>
            {/* tooltip right collapse */}
            <Tooltip placement="left" title="Show Section">
              <div
                style={{ cursor: "pointer", display: "flex",transform: "rotate(180deg)" }}
                onClick={() => {
                  setRightCollapsed(!rightCollapsed);
                }}
              >
                {/* <PreviewPDFIcon style={{ width: "16px", height: "16px" }} /> */}
                <img src={expandIcon} alt="logo" style={{cursor:"pointer"}}/>
              </div>
            </Tooltip>
            {/* tooltip left collape ends */}
          </div>
        )}
        {!rightCollapsed && (
          <>
            <ContentCard
              style={{ padding: 32, minHeight: "unset" }}
              bodyStyle={{ padding: 0 }}
              size="large"
            >
              <div style={{ width: "100%" }}>
                <Title
                  title="Report Sections"
                  titleStyle={{
                    fontSize: "18px",
                    fontWeight: "800",
                    lineHeight: "24px",
                  }}
                  renderRightPartDom={
                    <div className="svg-pos">
                      {/* tooltip right collapse */}
                      <Tooltip placement="left" title="Collpase Section">
                        <div
                          style={{ cursor: "pointer", display: "flex" }}
                          onClick={() => {
                            setRightCollapsed(!rightCollapsed);
                          }}
                        >
                          {/* <PreviewPDFIcon
                            style={{ width: "16px", height: "16px" }}
                          /> */}
                          <img src={expandIcon} alt="logo" style={{cursor:"pointer"}}/>
                        </div>
                      </Tooltip>
                      {/* tooltip left collape ends */}
                      {/* <span
                        onClick={() => {
                          setCollapsed(!collapsed);
                        }}
                      >
                        {collapsed && (
                          <div className={"expand-collpase-container"}>
                            <ExpandIcon />
                            <div style={{ marginLeft: "10.5px" }}>Expand</div>
                          </div>
                        )}
                        {!collapsed && (
                          <div className={"expand-collpase-container"}>
                            <CollapseIcon />
                            <div style={{ marginLeft: "10.5px" }}>Collapse</div>
                          </div>
                        )}
                        <span />
                      </span> */}
                    </div>
                  }
                />
                <div>
                  <ReportSections
                    collapsed={collapsed}
                    activeUnit={activeUnit}
                    setActiveUnit={setActiveUnit}
                    setRefreshKey={setRefreshKey}
                    setActivePreviewUnit={setActivePreviewUnit}
                    activePreviewUnit={activePreviewUnit}
                    onSaveContent={onSaveContent}
                  />
                </div>
              </div>
            </ContentCard>

            {/* <div className={"config-details"}>
              <div>
                <div className={"rich-text-container"}>
                  <TextSection
                    unitElement={activeUnit}
                    isPreview={false}
                    titleBlock={titleBlock()}
                    updateSectionData={updateSectionData}
                    setRefreshKey={setRefreshKey}
                    activePreviewUnit={activePreviewUnit}
                    onDelete={(data) => {
                      // ctrlObj.deleteUnitFun(store, data, unitConfigList);
                    }}
                  />
                </div>
              </div>
            </div> */}
          </>
        )}
      </LeftControl>

      <RightControl
        boxHeight={boxHeight}
        isCollapsed={isCollapsed}
        setIsCollapsed={setIsCollapsed}
        rightCollapsed={rightCollapsed}
      >
        <ContentCard
          style={{ padding: "0px 32px 32px", height: "100%" }}
          bodyStyle={{ padding: 0, display: "block", height: "100%" }}
          size="large"
        >
          <div className={"report-preview-heading"}>
            <div style={{ flex: "1 1 auto" }}>
              {/* Preview */}
              {showPreviewSheet
                ? "Preview"
                : isFormulaEnabled
                ? "Formula"
                : "Edit"}
            </div>
            {!showPreviewSheet && (
              <button
                className="preview-btn"
                style={{ borderColor: "#4BB354", color: "#4BB354" }}
                onClick={() => {
                  setShowPreviewSheet(true);
                  setRefreshKey(Math.random());
                }}
              >
                Preview
              </button>
            )}
            {showPreviewSheet && (
              <button
                className="preview-btn"
                onClick={() => {
                  setShowPreviewSheet(false);
                }}
              >
                Back To Edit
              </button>
            )}
            {!showPreviewSheet && (
              <div
                className="step-five-manage-formula-container"
                onClick={() => {
                  setIsFormulaEnabled(!isFormulaEnabled);
                }}
                style={{ color: isFormulaEnabled ? "#4BB354" : "#696f8c" }}
              >
                <SettingIcon style={{ marginRight: "8px" }} />
                Manage Formula
              </div>
            )}
            <Tooltip placement="left" title="Collpase Preview">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setIsCollapsed(!isCollapsed);
                }}
              >
                {/* <PreviewPDFIcon style={{ width: "16px", height: "16px" }} /> */}
                <img src={expandIcon} alt="logo" style={{cursor:"pointer",transform:"rotate(180deg)"}}/>
              </div>
            </Tooltip>
          </div>
          {!showPreviewSheet && (
            <>
              {isFormulaEnabled && (
                <EquationPreviewSheet
                  type="equation_item"
                  title="Manage Formula"
                  // setIsSheetPreviewEnabled={set}
                />
              )}
              {!isFormulaEnabled && (
                <PreviewHtml
                  refreshKey={refreshKey}
                  setContent={setContent}
                  content={content}
                  setPreviewContent={setPreviewContent}
                  previewContent={previewContent}
                />
              )}
            </>
          )}
          {showPreviewSheet && <PreviewPDF refreshKey={refreshKey} />}
        </ContentCard>
      </RightControl>
    </div>
  );
};

export default StepFive;
