import React from "react";
import { Button, Modal } from "antd";
import "./TutorialVideos.less";

const PopupVideo = ({
  open,
  handleOk,
  handleClose,
  backText,
  submitText,
  title,
  footer,
  videoSrc,
}) => {
  return (
    <Modal
      visible={open}
      title={title}
      style={{ borderRadius: "8px" }}
      width={"900px"}
      onOk={handleOk}
      onCancel={handleClose}
      footer={footer === null}
    >
      <div className="video-popup-iframe">
        <iframe
          src={videoSrc}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; fullscreen;"
        ></iframe>
      </div>
    </Modal>
  );
};

export default PopupVideo;
