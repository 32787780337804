import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { fetchCompaniesHook } from "../../api-hooks/company";
import { fetchReports } from "../../api-hooks/report";
import { getUserInfoHook } from "../../api-hooks/user";
import { fetchYearsHook } from "../../api-hooks/year";
import { setCompanies } from "../../store/company";
import { setReports } from "../../store/report";
import { updateUser } from "../../store/user";
import { setYears } from "../../store/year";
import Header from "../Header";
import Sidebar from "../Sidebar";
import "./MainContainer.less";
import { fetchIndustriesHook } from "../../api-hooks/industry";
import { setIndustries } from "../../store/industry";

const MainContainer = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    getUserInfoHook((response) => {
      dispatch(updateUser(response));
    });
    fetchYearsHook((response) => {
      dispatch(setYears(response));
    });
    fetchCompaniesHook((response) => {
      dispatch(setCompanies(response));
    });

    fetchReports((response) => {
      dispatch(setReports(response));
    });

    fetchIndustriesHook((response) => {
      dispatch(setIndustries(response));
    });
  }, []);
  return (
    <div className="main-container">
      <Sidebar />
      <div
        style={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          width: "calc(100% - 300px)",
        }}
      >
        <Header />
        <div className="main-container-content">{children}</div>
      </div>
    </div>
  );
};

export default MainContainer;
