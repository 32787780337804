// import logo from "./logo.svg";
// import logo from "./logo-cannex.svg";
import "./App.css";

import axios from "axios";
import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  HOME_URL,
  LOGIN_URL,
  SIGN_UP_URL,
  FINANCIAL_REPORT_URL,
  REPORT_DASHBOARD_URL,
  CREATE_FINANCIAL_REPORT_URL,
  NOTES_TEMPLATE_URL,
  EDIT_FINANCIAL_REPORT_URL,
  REPORT_DRIVE_URL,
} from "./routes";

import FinancialReport from "./components/FinancialReport";
import Home from "./components/Home";
import Login from "./components/Login";

import Signup from "./components/Signup";
import ReportDashboard from "./components/ReportDashboard";
import Auth from "./sub-components/Auth";
import CreateOrEditReport from "./components/CreateOrEditReport";
import NoteTemplate from "./components/NoteTemplate";
import ReportDrive from "./components/ReportDrive";

function App() {
  const getFinancialComponent = () => {
    return (
      <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
        <FinancialReport />
      </Auth>
    );
  };

  const createOrEditReportComponent = () => {
    return (
      <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
        <CreateOrEditReport />
      </Auth>
    );
  };

  return (
    // <div>
    <Router>
      <Switch>
        <Route
          path={HOME_URL}
          exact
          render={(routerProps) => (
            <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
              <Home />
            </Auth>
          )}
          // element={
          //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
          //     <Home />
          //   </Auth>
          // }
        />
        <Route
          path={CREATE_FINANCIAL_REPORT_URL}
          component={createOrEditReportComponent}

          // element={
          //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
          //     <CreateOrEditReport />
          //   </Auth>
          // }
        />
        <Route
          path={EDIT_FINANCIAL_REPORT_URL}
          component={createOrEditReportComponent}
          // element={
          //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
          //     <CreateOrEditReport />
          //   </Auth>
          // }
        />
        <Route
          path={FINANCIAL_REPORT_URL}
          component={getFinancialComponent}
          // element={
          //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
          //     <FinancialReport />
          //   </Auth>
          // }
        />
        {/* <Route
            path={FINANCIAL_REPORT_URL}
            // element={
            //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
            //     <FinancialReport />
            //   </Auth>
            // }
            render={(routerProps) => {
              <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
                <FinancialReport />
              </Auth>;
            }}
          /> */}
        <Route
          path={LOGIN_URL}
          component={() => {
            return (
              <Auth allow="$unauthenticated" redirectTo={FINANCIAL_REPORT_URL}>
                <Login />
              </Auth>
            );
          }}
          // element={
          //   <Auth allow="$unauthenticated" redirectTo={FINANCIAL_REPORT_URL}>
          //     <Login />
          //   </Auth>
          // }
        />
        <Route
          path={SIGN_UP_URL}
          component={() => {
            return (
              <Auth allow="$unauthenticated" redirectTo={FINANCIAL_REPORT_URL}>
                <Signup />
              </Auth>
            );
          }}
          // element={
          //   <Auth allow="$unauthenticated" redirectTo={FINANCIAL_REPORT_URL}>
          //     <Signup />
          //   </Auth>
          // }
        />
        <Route
          path={REPORT_DASHBOARD_URL}
          component={() => {
            return (
              <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
                <ReportDashboard />
              </Auth>
            );
          }}
          // element={
          //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
          //     <ReportDashboard />
          //   </Auth>
          // }
        />
        <Route
          path={NOTES_TEMPLATE_URL}
          component={() => {
            return (
              <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
                <NoteTemplate />
              </Auth>
            );
          }}
          // element={
          //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
          //     <NoteTemplate />
          //   </Auth>
          // }
        />
        <Route
          path={REPORT_DRIVE_URL}
          component={() => {
            return (
              <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
                <ReportDrive />
              </Auth>
            );
          }}
          // element={
          //   <Auth allow="$authenticated" redirectTo={LOGIN_URL}>
          //     <ReportDrive />
          //   </Auth>
          // }
        />
      </Switch>
    </Router>
    // </div>
  );
}

export default App;
