import { mergeAttributes, Node } from "@tiptap/core";

export const CustomTableCell = Node.create({
  name: "tableCell",

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  content: "block+",

  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      colwidth: {
        default: null,
        parseHTML: (element) => {
          const colwidth = element.getAttribute("colwidth");
          const value = colwidth ? [parseInt(colwidth, 10)] : null;

          return value;
        },
      },
      style: {
        default: null,
      },
    };
  },

  tableRole: "cell",

  isolating: true,

  parseHTML() {
    return [{ tag: "td" }];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      "td",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      toggleTableCellSingleUnderLine:
        (style) =>
        ({ commands }) => {
          console.log("Table Single Underline");
          return commands.updateAttributes(this.name, {
            style: style,
          });
        },
      toggleTableCellDoubleUnderLine:
        (style) =>
        ({ commands }) => {
          return commands.updateAttributes(this.name, {
            style: style,
          });
        },
      //   toggleUnderline:
      //     () =>
      //     ({ commands }) => {
      //       return commands.toggleMark(this.name);
      //     },
      //   unsetUnderline:
      //     () =>
      //     ({ commands }) => {
      //       return commands.unsetMark(this.name);
      //     },
    };
  },
});
