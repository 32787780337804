/* eslint-disable no-lone-blocks */
import ContentCard from "../../atoms/ContentCard";
import React, { useState } from "react";
import Step from "./Step";
import "./CreateOrEditReport.less";
import { Button } from "antd";
import ComponentButton from "../../atoms/ComponentButton";
import SaveReportModal from "../../sub-components/SaveReportModal";
import { useHistory } from "react-router";
import {
  CREATE_FINANCIAL_REPORT_URL,
  FINANCIAL_REPORT_URL,
  EDIT_FINANCIAL_REPORT_URL,
  useRouter,
} from "../../routes";
import { useSelector } from "react-redux";
const ReportSteps = ({
  currentStep,
  setCurrentStep,
  duplicateId,
  setReportList,
  reportList,
  setStepOneErrorMessage,
  setIsByPassBlock,
  setCount,
  count,
}) => {
  const [isSaveModalOpened, setIsSaveModalOpened] = useState(false);
  const firstDataSourceId = useSelector(
    (state) => state.report.firstDataSourceId
  );

  const history = useHistory();
  // const navigate = useNavigate();
  const router = useRouter();
  const { query } = router;
  const currentUrl = window.location.href;

  const navigateToStep = (stepNumber) => {
    {
      currentUrl.includes("edit") &&
        history.push(
          `${EDIT_FINANCIAL_REPORT_URL}?reportId=${query?.reportId}&step=${stepNumber}`
        );
    }
    {
      !currentUrl.includes("edit") &&
        history.push(
          `${CREATE_FINANCIAL_REPORT_URL}?reportId=${query?.reportId}&step=${stepNumber}`
        );
    }
  };
  return (
    <ContentCard
      style={{ minHeight: "109px", marginBottom: "16px" }}
      className={"report-steps-card"}
      bodyStyle={{ display: "flex", alignItems: "center" }}
    >
      <div
        style={{
          flex: "1 1 auto",
          position: "relative",
          top: "13px",
          marginRight: "68px",
        }}
      >
        <div
          style={{ borderBottom: "3px solid #4BB354", position: "relative" }}
        ></div>
        <div
          className={"steps-list-parent-container"}
          // style={{
          //   display: "flex",
          //   alignItems: "center",
          //   position: "absolute",
          // }}
        >
          <div className={"steps-list-child"}>
            <Step
              stepNumber={1}
              name="Set up Data Source"
              isCurrentSelected={currentStep === 1}
              onClick={() => {
                setCurrentStep(1);
                navigateToStep(1);
              }}
            />
            <Step
              stepNumber={2}
              name="Map FS Item"
              isCurrentSelected={currentStep === 2}
              onClick={() => {
                if (
                  (firstDataSourceId && currentStep === 1) ||
                  currentStep !== 1
                ) {
                  setCurrentStep(2);
                  navigateToStep(2);
                } else {
                  setStepOneErrorMessage(
                    "You must confirm data format settings"
                  );
                }
              }}
            />

            <Step
              stepNumber={3}
              name="Date Period and Currency"
              isCurrentSelected={currentStep === 3}
              onClick={() => {
                setCurrentStep(3);
                navigateToStep(3);
              }}
            />
            <Step
              stepNumber={4}
              name="Cover Page & Page Header"
              isCurrentSelected={currentStep === 4}
              onClick={() => {
                setCurrentStep(4);
                navigateToStep(4);
              }}
            />
            <Step
              stepNumber={5}
              name="Edit Report"
              isCurrentSelected={currentStep === 5}
              onClick={() => {
                setCurrentStep(5);
                navigateToStep(5);
              }}
            />
          </div>
        </div>
      </div>
      <div>
        {/* <Button key="back" style={{ marginRight: "19px" }}>
          Close
        </Button>*/}
        {/* {currentStep !== 2 && currentStep !== 5 && ( */}
        <Button
          style={{
            marginRight: "19px",
            visibility:
              currentStep !== 2 && currentStep !== 5 ? "visible" : "hidden",
          }}
          onClick={() => {
            // setCount(count + 1);

            setIsSaveModalOpened(true);
          }}
        >
          Save Progress
        </Button>
        {/* // )} */}
        {currentStep !== 1 && (
          <Button
            style={{ marginRight: "19px" }}
            onClick={() => {
              if (currentStep > 1) {
                setCurrentStep(currentStep - 1);
                {
                  currentUrl.includes("edit") &&
                    history.push(
                      `${EDIT_FINANCIAL_REPORT_URL}?reportId=${
                        query?.reportId
                      }&step=${currentStep - 1}`
                    );
                }
                {
                  !currentUrl.includes("edit") &&
                    history.push(
                      `${CREATE_FINANCIAL_REPORT_URL}?reportId=${
                        query?.reportId
                      }&step=${currentStep - 1}`
                    );
                }
              } else {
                history.goBack();
              }
            }}
          >
            Back
          </Button>
        )}
        {currentStep === 5 && (
          <ComponentButton
            title="Save"
            onClick={() => {
              {
                currentUrl.includes("edit") && setIsSaveModalOpened(true);
                // window.location.href = EDIT_FINANCIAL_REPORT_URL
              }
              {
                !currentUrl.includes("edit") && setIsSaveModalOpened(true);
                // window.location.href = FINANCIAL_REPORT_URL
              }

              // navigate(FINANCIAL_REPORT_URL);
              // setIsSaveModalOpened(true);
              // open Save modal
            }}
          />
        )}
        {currentStep !== 5 && (
          <ComponentButton
            title="Next"
            onClick={() => {
              if (currentStep === 1 && !firstDataSourceId) {
                setStepOneErrorMessage("You must confirm data format settings");
              } else {
                setCurrentStep(currentStep + 1);
                {
                  currentUrl.includes("edit") &&
                    history.push(
                      `${EDIT_FINANCIAL_REPORT_URL}?reportId=${
                        query?.reportId
                      }&step=${currentStep + 1}`
                    );
                }
                {
                  !currentUrl.includes("edit") &&
                    history.push(
                      `${CREATE_FINANCIAL_REPORT_URL}?reportId=${
                        query?.reportId
                      }&step=${currentStep + 1}`
                    );
                }
              }
            }}

            //   onClick={() => {
            //     setIsPreviousDialogOpened(true);
            //   }}
          />
        )}
      </div>
      {isSaveModalOpened && (
        <SaveReportModal
          open={isSaveModalOpened}
          handleClose={() => {
            setIsSaveModalOpened(false);
          }}
          duplicateId={duplicateId}
          setReportList={setReportList}
          reportList={reportList}
          isEdit
          setIsByPassBlock={setIsByPassBlock}
          setCount={setCount}
          count={count}
        />
      )}
    </ContentCard>
  );
};

export default ReportSteps;
