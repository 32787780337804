import axios from "axios";
import api from "./api-intercepter";

const INDUSTRY_ROUTE = "/industries";

export const fetchIndustriesHook = (callback) => {
  api
    .get(INDUSTRY_ROUTE)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const createIndustryHook = (industryName, callback) => {
  const data = JSON.stringify({
    name: industryName,
  });
  api
    .post(INDUSTRY_ROUTE, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
export const updateIndustryHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
  });
  api
    .patch(`${INDUSTRY_ROUTE}/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const deleteIndustryHook = (values, callback) => {
  api
    .delete(`${INDUSTRY_ROUTE}/${values?.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
