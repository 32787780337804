import React from "react";
import { Input, Space } from "antd";
const { Search } = Input;
const ComponentSearch = ({
  style,
  placeholder,
  onSearch,
  className,
  onChange,
  allowClear,
}) => {
  return (
    <Search
      placeholder={placeholder}
      onSearch={onSearch}
      onChange={onChange}
      style={style}
      allowClear={allowClear}
      className={className}
    />
  );
};

export default ComponentSearch;
