import React, { useState, useEffect } from "react";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import DraftItem from "./DraftItem";
const ExpandableGroup = ({
  item,
  onSelect,
  expandAll,

  fetchCompanyWithReports,
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  useEffect(() => {
    if (expandAll != isExpanded) setIsExpanded(expandAll);
  }, [expandAll]);

  return (
    <div>
      <div
        className={"saved-expandable-group-container"}
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <div className={"saved-expandable-group-title"}>{item.name}</div>
        {isExpanded && <UpOutlined style={{ width: "10px", height: "10px" }} />}
        {!isExpanded && (
          <DownOutlined style={{ width: "10px", height: "10px" }} />
        )}
      </div>
      {isExpanded && (
        <>
          {item?.reports?.map((draftItem, index) => {
            return (
              <DraftItem
                key={index}
                draftItem={draftItem}
                onSelect={onSelect}
                companyItem={item}
                fetchCompanyWithReports={fetchCompanyWithReports}
              />
            );
          })}
        </>
      )}
      {/* {isExpanded && (
        <>
          {item?.reports?.map((draftItem, index) => {
            return (
              <DraftItem
                key={index}
                draftItem={draftItem}
                onSelect={onSelect}
              />
            );
          })}
        </>
      )} */}
    </div>
  );
};

export default ExpandableGroup;
