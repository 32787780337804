import { useEffect } from "react";
import { useRouter } from "../../routes";

const PreviewPDF = ({ refreshKey, type }) => {
  const router = useRouter();
  const { query } = router;

  useEffect(() => {
    if (query?.reportId) {
      getSrc(refreshKey, type);
    }
  }, [refreshKey, type, query?.reportId]);
  const getSrc = async (refreshKey, type) => {
    const token = localStorage.getItem("authToken");
    let url = `${process.env.REACT_APP_API}/report-generators/preview/${query?.reportId}`;
    if (type) {
      url = url + `?mode=coverPage`;
    } else if (refreshKey) {
      url = url + `?mode=refresh`;
    }
    const res = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
        // Here you can set any headers you want
      },
    });

    if (res.status === 401) {
      window.location.href = "/login";
    } else {
      const blob = await res.blob();
      const urlObject = URL.createObjectURL(blob);
      console.log("Url OBj", urlObject);
      document
        .querySelector("iframe")
        .setAttribute("src", `${urlObject}#filename=test`);
    }
  };

  return (
    <div style={{ height: "inherit" }}>
      <iframe width="100%" height="100%" title="Final" />
    </div>
  );
};

export default PreviewPDF;
