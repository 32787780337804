import React, { useState } from "react";
import "./TutorialVideos.less";
import { ReactComponent as TraningScreen } from "../../assets/svg-icons/trainingScreen.svg";
import { ReactComponent as PlayBtn } from "../../assets/svg-icons/playBtn.svg";
import PopupVideo from "./PopupVideo";

const TutorialVideos = () => {
  const [videoModal, setVideoModal] = useState(false);
  const [videoSrc, setVideoSrc] = useState(
    "https://www.youtube.com/embed/0q8e2byyVYY"
  );
  const VideoModalClose = () => {
    setVideoModal(false);
  };
  return (
    <>
      <div className="TutorialVideos">
        <div>
          <div
            className="training-screen"
            onClick={() => {
              setVideoModal(true);
              setVideoSrc("https://www.youtube.com/embed/0q8e2byyVYY");
            }}
          >
            <TraningScreen />
            <div className="play-btn">
              <PlayBtn
                style={{
                  width: "36px",
                  height: "36px",
                  paddingLeft: "8px",
                }}
              />
            </div>
          </div>
          <div className="tutorial-video-titles">
            <h4>Upload Trial Balance </h4>
            <p>
              {" "}
              Lorem ipsum dolor sit amet Lorem Ipsum Ut et massa mi. Aliquam in
              hendrerit urna. Pellentesque sit amet sapi
            </p>
          </div>
        </div>
        <div>
          <div
            className="training-screen"
            onClick={() => {
              setVideoModal(true);
              setVideoSrc("https://www.youtube.com/embed/ZWZFgCl80Wo");
            }}
          >
            <TraningScreen />
            <div className="play-btn">
              <PlayBtn
                style={{
                  width: "36px",
                  height: "36px",
                  paddingLeft: "8px",
                }}
              />
            </div>
          </div>
          <div className="tutorial-video-titles">
            <h4>Data Mapping</h4>
            <p>
              {" "}
              Lorem ipsum dolor sit amet Lorem Ipsum Ut et massa mi. Aliquam in
              hendrerit urna. Pellentesque sit amet sapi
            </p>
          </div>
        </div>
        <div>
          <div
            className="training-screen"
            onClick={() => {
              setVideoModal(true);
              setVideoSrc("https://www.youtube.com/embed/hAOdXqa752Y");
            }}
          >
            <TraningScreen />
            <div className="play-btn">
              <PlayBtn
                style={{
                  width: "36px",
                  height: "36px",
                  paddingLeft: "8px",
                }}
              />
            </div>
          </div>
          <div className="tutorial-video-titles">
            <h4>Date and other settings </h4>
            <p>
              {" "}
              Lorem ipsum dolor sit amet Lorem Ipsum Ut et massa mi. Aliquam in
              hendrerit urna. Pellentesque sit amet sapi
            </p>
          </div>
        </div>
        <div>
          <div
            className="training-screen"
            onClick={() => {
              setVideoModal(true);
              setVideoSrc("https://www.youtube.com/embed/zEcnF00fUKI");
            }}
          >
            <TraningScreen />
            <div className="play-btn">
              <PlayBtn
                style={{
                  width: "36px",
                  height: "36px",
                  paddingLeft: "8px",
                }}
              />
            </div>
          </div>
          <div className="tutorial-video-titles">
            <h4>Add IS, BS and other sections</h4>
            <p>
              {" "}
              Lorem ipsum dolor sit amet Lorem Ipsum Ut et massa mi. Aliquam in
              hendrerit urna. Pellentesque sit amet sapi
            </p>
          </div>
        </div>
      </div>
      {videoModal && (
        <PopupVideo
          open={videoModal}
          handleClose={VideoModalClose}
          backText="Back"
          submitText="Confirm"
          videoSrc={videoSrc}
        />
      )}
    </>
  );
};

export default TutorialVideos;
