import React from "react";

import { Button, Popover } from "antd";

import ComponentSearch from "../ComponentSearch";
import PopupItem from "./PopupItem";

import "./index.css";

const ComponentPopupWithSearch = ({
  open,
  handleClose,
  trigger,
  title,
  className,
  list,
  onSelect,
  onEnter,
  addText,
  onAdd,
  onDelete,
  showReportEdit,
  updateData,
  onBlur,
}) => {
  console.log("getting report edit value 1",showReportEdit)
  const [searchValue, setSearchValue] = React.useState("");
  const getList = () => {
    return list
      .map((item, index) => {
        return (
          <PopupItem
            item={item}
            key={index}
            onSelect={onSelect}
            onDelete={onDelete}
            onEnter={onEnter}
            onBlur={onBlur}
            showReportEdit={showReportEdit}
            updateData={updateData}
          />
        );
      })
      .filter((item) => {
        if (searchValue) {
          console.log("Item name", item.props.item.name);
          if (item?.props?.item?.name?.includes(searchValue)) {
            return item;
          }
        } else {
          return item;
        }
      });
  };
  return (
    <Popover
      content={
        <div
          className="ComponentPopup-search"
          style={{ maxHeight: "300px", overflowY: "auto" }}
        >
          <ComponentSearch
            placeholder="Search or Create New"
            className={"component-popup-search"}
            onSearch={(value) => {
              console.log("On Sewar", value);
              onAdd(searchValue);
              setSearchValue("");
            }}
            onChange={(e) => {
              console.log("On Chnage", e.target.value);
              setSearchValue(e.target.value);
            }}
          />
          {getList()}
          {!getList()?.length && (
            <div
              className="add-new-popup-item-container"
              onClick={() => {
                onAdd(searchValue);
                setSearchValue("");
              }}
            >
              + {addText}
            </div>
          )}
          {/* {list?.map((item, index) => {
            return <PopupItem item={item} onSelect={onSelect} />;
          })} */}
        </div>
      }
      title={title}
      trigger="click"
      visible={open}
      placement="bottom"
      overlayClassName={className}
      onVisibleChange={(visible) => {
        if (!visible) {
          handleClose();
        }
      }}
    >
      {trigger}
    </Popover>
  );
};

export default ComponentPopupWithSearch;
