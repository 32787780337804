import React, { useEffect, useState } from "react";
import MainContainer from "../../sub-components/MainContainer";
import homeShape from "../../assets/svg-icons/homeShape.svg";
import financialReportHome from "../../assets/svg-icons/financialReportColoured.svg";
import noteTemplateHome from "../../assets/svg-icons/noteTemplateColoured.svg";
import reportDriveHome from "../../assets/svg-icons/reportDriveColoured.svg";
import { useHistory } from "react-router";
import "./Home.less";
import WhatsNew from "./WhatsNew";
import TutorialVideos from "./TutorialVideos";
import {
  FINANCIAL_REPORT_URL,
  NOTES_TEMPLATE_URL,
  REPORT_DRIVE_URL,
} from "../../routes";
const Home = ({}) => {
  const history = useHistory();
  return (
    <MainContainer>
      <div
        className="finacial-report-container financial-height-setter"
        style={{ flexDirection: "column" }}
      >
        {/* breadcrumb */}
        <div className="breadcrumb">
          <img src={homeShape} alt="homeShape" />
        </div>
        {/* Main Section */}
        <div className="home-welcome-section">
          <h1>Welcome to Cannex!</h1>
          {/* Quick Aceess Cards */}
          <div>
            <h4>Quick Access</h4>
            <div className="cards-home">
              <div
                className="card-quick-acess"
                onClick={() => {
                  history.push(FINANCIAL_REPORT_URL);
                }}
              >
                <div className="image-bakground">
                  <img src={financialReportHome} alt="financialReportHome" />
                </div>
                <h5>FinancialReport</h5>
                <p>Create, edit, and review your financial reports</p>
              </div>
              <div
                className="card-quick-acess"
                onClick={() => {
                  history.push(NOTES_TEMPLATE_URL);
                }}
              >
                <div className="image-bakground">
                  <img src={noteTemplateHome} alt="noteTemplateHome" />
                </div>
                <h5>Note template</h5>
                <p>Manage your notes, create report in an easy way</p>
              </div>
              <div
                className="card-quick-acess"
                onClick={() => {
                  history.push(REPORT_DRIVE_URL);
                }}
              >
                <div className="image-bakground">
                  <img src={reportDriveHome} alt="ReportDriveHome" />
                </div>
                <h5>Report Drive</h5>
                <p>Upload your word attachment</p>
              </div>
            </div>
          </div>
          {/* What's new */}
          <div style={{ paddingTop: "30px" }}>
            <h4>What's New</h4>
            <WhatsNew />
          </div>
          {/* Tutorial Videos */}
          <div style={{ paddingTop: "30px" }}>
            <h4>Tutorial Video</h4>
            <TutorialVideos />
          </div>
        </div>
      </div>
    </MainContainer>
  );
};

export default Home;
