import "./Sidebar.less";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-cannex.svg";
import logoCollapsed from "../../assets/logoCollapsed.svg";
import expandIcon from "../../assets/svg-icons/expandSidebar.svg";
import ComponentSearch from "../../atoms/ComponentSearch";
import ComponentInput from "../../atoms/ComponentInput";

import accountingIcon from "../../assets/svg-icons/accounting.svg";
import { ReactComponent as FinancialReportHome } from "../../assets/svg-icons/financialReportHome.svg";
import { ReactComponent as NoteTemplateHome } from "../../assets/svg-icons/noteTemplateHome.svg";
import { ReactComponent as ReportDriveHome } from "../../assets/svg-icons/reportDriveHome.svg";
// import { ReactComponent as ReportDashboardIcon } from "../../assets/svg-icons/reportDashboard.svg";

import { FileOutlined } from "@ant-design/icons";

import { SearchOutlined } from "@ant-design/icons";
import {
  FINANCIAL_REPORT_URL,
  NOTES_TEMPLATE_URL,
  REPORT_DASHBOARD_URL,
  REPORT_DRIVE_URL,
  useRouter,
  HOME_URL,
} from "../../routes";
import { useMemo, useState } from "react";
import ComponentPopup from "../../atoms/ComponentPopup";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { changeCollapsedState } from "../../store/user";
import SaveReportModal from "../SaveReportModal";
import SaveWarningModal from "../SaveWarningModal/SaveWarningModal";

const { SubMenu } = Menu;

const Sidebar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useRouter();

  const { pathname } = router;
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const isCollapsed = useSelector((state) => state.user.isCollapsed);
  const [isSaveReportModalOpened, setIsSaveReportModalOpened] = useState(false);
  const [navigateUrl, setNavigateUrl] = useState("");

  const containerStyle = useMemo(() => {
    return isCollapsed
      ? {
          width: "70px",
          minWidth: "70px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }
      : { width: "300px", minWidth: "300px" };
  }, [isCollapsed]);

  const expandIconStyle = useMemo(() => {
    return isCollapsed ? { transform: "rotate(180deg)" } : {};
  }, [isCollapsed]);
  const saveTemplate = () => {
    if (navigateUrl === "FINANCIAL_REPORT_URL") {
      history.push(FINANCIAL_REPORT_URL);
    } else if (navigateUrl === "NOTES_TEMPLATE_URL") {
      history.push(NOTES_TEMPLATE_URL);
    } else if (navigateUrl === "REPORT_DRIVE_URL") {
      history.push(REPORT_DRIVE_URL);
    }
  };
  return (
    <div className="sidebar-container" style={containerStyle}>
      {isCollapsed && (
        <img
          src={logoCollapsed}
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(HOME_URL);
          }}
        />
      )}
      {!isCollapsed && (
        <img
          src={logo}
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={() => {
            history.push(HOME_URL);
          }}
        />
      )}
      <div
        style={{
          marginTop: "18px",
          padding: "6px 12px 6px 40px",
          background: "#FAFBFF",
          borderRadius: "0px 10px 10px 0px",
          width: "64px",
          marginLeft: "-38px",
        }}
      >
        <img
          src={expandIcon}
          alt="expand"
          style={{ cursor: "pointer", ...expandIconStyle }}
          onClick={() => {
            dispatch(changeCollapsedState(!isCollapsed));
            // setIsCollapsed(!isCollapsed);
          }}
        />
      </div>
      {isCollapsed && (
        <div className="sidebar-search-container">
          {/* <div>
            <SearchOutlined
              color="#DADADA"
              style={{
                color: "#8F95B2",
                width: "20px",
                height: "20px",
                fontSize: "20px",
              }}
            />
          </div> */}
          <div
            onClick={() => {
              // if (!(pathname === "/")) {
              //   setIsSaveReportModalOpened(true);
              // } else {
              //   navigate(FINANCIAL_REPORT_URL);
              // }
              // setNavigateUrl("FINANCIAL_REPORT_URL");
              history.push(FINANCIAL_REPORT_URL);
            }}
            style={{
              width: "70px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <FinancialReportHome style={{ width: "16px", height: "16px" }} />
          </div>
          <div
            onClick={() => {
              // if (!(pathname === "/")) {
              //   setIsSaveReportModalOpened(true);
              // } else {
              //   navigate(NOTES_TEMPLATE_URL);
              // }
              // setNavigateUrl("NOTES_TEMPLATE_URL");
              history.push(NOTES_TEMPLATE_URL);
            }}
            style={{
              width: "70px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <NoteTemplateHome style={{ width: "16px", height: "16px" }} />
          </div>
          <div
            onClick={() => {
              // if (!(pathname === "/")) {
              //   setIsSaveReportModalOpened(true);
              // } else {
              //   navigate(REPORT_DRIVE_URL);
              // }
              // setNavigateUrl("REPORT_DRIVE_URL");
              history.push(REPORT_DRIVE_URL);
            }}
            style={{
              width: "70px",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            <ReportDriveHome style={{ width: "16px", height: "16px" }} />
          </div>
        </div>
      )}
      {!isCollapsed && (
        <div className="sidebar-search-container">
          {/* <ComponentInput
            placeholder="Search"
            prefix={
              <SearchOutlined
                color="#DADADA"
                style={{ marginRight: "10px", color: "#8F95B2" }}
              />
            }
            size="large"
          /> */}
          <div style={{ marginTop: "35px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <FinancialReportHome style={{ width: "16px", height: "16px" }} />
              <div
                style={{
                  padding: "10px 20px",
                  color:
                    pathname === FINANCIAL_REPORT_URL ||
                    pathname === FINANCIAL_REPORT_URL + "/create"
                      ? "#4bb354"
                      : "",
                }}
                onClick={() => {
                  // if (!(pathname === "/")) {
                  //   setIsSaveReportModalOpened(true);
                  // } else {
                  //   navigate(FINANCIAL_REPORT_URL);
                  // }
                  // setNavigateUrl("FINANCIAL_REPORT_URL");
                  history.push(FINANCIAL_REPORT_URL);
                }}
                className={
                  pathname === FINANCIAL_REPORT_URL ||
                  pathname === FINANCIAL_REPORT_URL + "/create"
                    ? "sidebar-menu-item-selected"
                    : ""
                }
              >
                Financial Report Design
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <NoteTemplateHome style={{ width: "16px", height: "16px" }} />
              <div
                style={{
                  padding: "10px 20px",
                  color: pathname === NOTES_TEMPLATE_URL ? "#4bb354" : "",
                }}
                onClick={() => {
                  // if (!(pathname === "/")) {
                  //   setIsSaveReportModalOpened(true);
                  // } else {
                  //   navigate(NOTES_TEMPLATE_URL);
                  // }
                  // setNavigateUrl("NOTES_TEMPLATE_URL");
                  history.push(NOTES_TEMPLATE_URL);
                }}
                className={
                  pathname === NOTES_TEMPLATE_URL
                    ? "sidebar-menu-item-selected"
                    : ""
                }
              >
                Note Template
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <ReportDriveHome style={{ width: "16px", height: "16px" }} />
              <div
                style={{
                  padding: "10px 20px",
                  color: pathname === REPORT_DRIVE_URL ? "#4bb354" : "",
                }}
                onClick={() => {
                  // if (!(pathname === "/")) {
                  //   setIsSaveReportModalOpened(true);
                  // } else {
                  //   navigate(REPORT_DRIVE_URL);
                  // }
                  // setNavigateUrl("REPORT_DRIVE_URL");
                  history.push(REPORT_DRIVE_URL);
                }}
                className={
                  pathname === REPORT_DRIVE_URL
                    ? "sidebar-menu-item-selected"
                    : ""
                }
              >
                Report Drive
              </div>
            </div>
          </div>
        </div>
      )}
      {isSaveReportModalOpened && (
        <SaveWarningModal
          open={isSaveReportModalOpened}
          handleClose={() => {
            setIsSaveReportModalOpened(false);
          }}
          saveTemplate={saveTemplate}
        />
      )}
    </div>
  );
};

export default Sidebar;
