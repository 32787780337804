import { Radio } from "antd";
import "./ComponentTabs.less";

const ComponentTabs = ({ tabList, selectedValue, handleTabChange }) => {
  return (
    <Radio.Group
      onChange={handleTabChange}
      value={selectedValue}
      style={{ marginBottom: 8 }}
    >
      {tabList &&
        tabList?.length > 0 &&
        tabList?.map((tab) => {
          return <Radio.Button value={tab.value}>{tab.label}</Radio.Button>;
        })}
    </Radio.Group>
  );
};

export default ComponentTabs;
