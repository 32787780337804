import React from "react";
import { Card, Button, message, Checkbox } from "antd";
import "./ComponentButton.less";
const ComponentButton = ({
  title,
  icon,
  style,
  onClick,
  htmlType,
  size,
  disabled,
  loading,
}) => {
  return (
    <Button
      id="btn-0222-1456"
      type="primary"
      className="component-button"
      icon={icon}
      style={style}
      onClick={onClick}
      htmlType={htmlType}
      size={size}
      disabled={disabled}
      loading={loading}

      // onClick={() => {
      //   saveNewTemplate();
      // }}
    >
      {/* <FormattedMessage id="Save" defaultMessage="保存" /> */}
      {title}
    </Button>
  );
};

export default ComponentButton;
