// import less
import "./CreateEquationModal.less";

import ComponentModal from "../../atoms/ComponentModal";
import { useEffect, useState } from "react";
// import arrayMove from "array-move";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { PlusOutlined } from "@ant-design/icons";
import ComponentInput from "../../atoms/ComponentInput";
import { useRouter } from "../../routes";
import {
  createAccountEquationHook,
  deleteAccountEquationHook,
  updateAccountEquationHook,
} from "../../api-hooks/account-equation";
import Operator from "./Operator";
import { Button, Checkbox, Col, notification, Row, Typography } from "antd";
import EquationItem from "./EquationItem";
import OperatorItem from "./OperatorItem";
import ComponentButton from "../../atoms/ComponentButton";
import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";

const operators = [
  { key: "selectOperator", value: "Select Operator" },
  { key: "+", value: "Add (+)" },
  { key: "-", value: "Subtract (-)" },
  { key: "(", value: "Left Bracket(" },
  { key: ")", value: "Right Bracket )" },
];
const { Paragraph } = Typography;
const style = {
  padding: "8px 0",
  textAlign: "center",
  fontWeight: "500",
};

const SortableItem = SortableElement(
  ({
    item,
    indexValue,
    yearList,
    previewObj,
    itemAmountObj,
    onDeleteEquationItem,
    onUpdateEquationItemValue,
  }) => {
    let style = {};
    return (
      <div>
        {item?.type === "equation" && (
          <EquationItem
            yearList={yearList}
            previewObj={previewObj}
            itemAmountObj={itemAmountObj}
            onDeleteEquationItem={onDeleteEquationItem}
            indexValue={indexValue}
            onUpdateEquationItemValue={onUpdateEquationItemValue}
            item={item}
          />
        )}
        {item?.type === "operator" && (
          <OperatorItem
            item={item}
            onDeleteEquationItem={onDeleteEquationItem}
            indexValue={indexValue}
            onUpdateEquationItemValue={onUpdateEquationItemValue}
          />
        )}
      </div>
    );
  }
);

const SortableList = SortableContainer(
  ({
    items,
    onUpdateScenarioName,
    stepObj,
    // selectedScenario,
    // setSelectedScenario,
    onRemove,
    yearList,
    setStepObj,
    updateStepToSecnario,
    previewObj,
    itemAmountObj,
    onDeleteEquationItem,
    onUpdateEquationItemValue,
  }) => {
    return (
      <div style={{ height: "200px", overflowY: "auto" }}>
        {items.map((item, index) => (
          <SortableItem
            key={index}
            index={index}
            item={item}
            indexValue={index}
            yearList={yearList}
            previewObj={previewObj}
            itemAmountObj={itemAmountObj}
            onDeleteEquationItem={onDeleteEquationItem}
            onUpdateEquationItemValue={onUpdateEquationItemValue}
          />
        ))}
      </div>
    );
  }
);

const CreateEquationModal = ({
  open,
  handleClose,
  type,
  categoryName,
  previewData,
  setPreviewData,
  yearList,
  previewObj,
  itemAmountObj,
  selectedEquationItem,
  currentYear,
  previousYear,
}) => {
  const router = useRouter();
  const { query } = router;
  const [showFooter, setShowFooter] = useState(null);
  const [isCurrentYear, setCurrentYear] = useState(
    ["both", "current"].includes(selectedEquationItem?.period)
      ? true
      : selectedEquationItem
      ? false
      : true
  );
  const [isPrevYear, setPrevYear] = useState(
    ["both", "previous"].includes(selectedEquationItem?.period)
      ? true
      : selectedEquationItem
      ? false
      : true
  );

  let list = [];

  const [showAddEquation, setShowAddEquation] = useState(false);

  const [isSelectEquationDialog, setIsSelectEquationDialog] = useState(false);
  const [newlyFormedEquation, setNewlyFormedEquation] = useState([]);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  // console.log("Preview Data", previewData);
  const [equationItemList, setEquationItemList] = useState([]);

  useEffect(() => {
    if (selectedEquationItem?.id) {
      setName(selectedEquationItem?.name);
      const equation = selectedEquationItem?.equation;
      const equationList = equation.split("{{");
      let finalList = [];
      let equationItemList = [];
      equationList?.forEach((data) => {
        const splittedData = data.split("}}");
        if (splittedData[0]) {
          finalList.push(splittedData[0]);
        }
        if (splittedData[1]) {
          finalList.push(splittedData[1]);
        }
      });

      finalList?.forEach((finalData) => {
        if (finalData) {
          console.log("Final Data", finalData);
          const splittedData = finalData?.split("|");
          if (splittedData?.length === 1) {
            if (splittedData[0]?.length > 1) {
              const operatorSplitData = splittedData[0]?.split("");
              operatorSplitData?.forEach((operator) => {
                equationItemList.push({
                  type: "operator",
                  value: operator,
                });
              });
            } else {
              equationItemList.push({
                type: "operator",
                value: splittedData[0],
              });
            }
          } else if (splittedData?.length === 2) {
            equationItemList.push({
              type: "equation",
              year: "",
              equationType: splittedData[0],
              value: splittedData[1],
            });
          } else if (splittedData?.length === 3) {
            equationItemList.push({
              type: "equation",
              year: splittedData[0],
              equationType: splittedData[1],
              value: splittedData[2],
            });
          }
        }
      });
      console.log("finalList", equationItemList);
      setEquationItemList([...equationItemList]);
    }
  }, [selectedEquationItem?.id]);

  const onAddEquation = () => {
    var finalEquationFormed = "";
    newlyFormedEquation.map((item) => {
      finalEquationFormed += item;
    });
    const value = {
      name: name,
      description: description,
      equation: finalEquationFormed,
      reportId: query?.reportId,
    };
    createAccountEquationHook(value, (response) => {
      console.log("Equation created successfully!", response);
      previewData.push(response);
      setPreviewData([...previewData]);
      handleClose();
    });
  };

  const addOperator = (operator) => {
    const item = {
      type: "operator",
      value: operator,
    };
    equationItemList.push(item);
    setEquationItemList([...equationItemList]);
  };

  const addEquation = () => {
    const item = {
      type: "equation",
      year: "",
      equationType: "",
      value: "",
    };
    equationItemList.push(item);
    setEquationItemList([...equationItemList]);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const equation = equationItemList[oldIndex];
    equationItemList.splice(oldIndex, 1);
    equationItemList.splice(newIndex, 0, equation);
    console.log("Old Index", oldIndex, newIndex, equationItemList);
    setEquationItemList([...equationItemList]);
  };

  const onDeleteEquationItem = (index) => {
    equationItemList.splice(index, 1);
    setEquationItemList([...equationItemList]);
  };

  const onUpdateEquationItemValue = (index, key, value) => {
    const item = equationItemList[index];
    item[key] = value;
    equationItemList.splice(index, 1, item);
    setEquationItemList([...equationItemList]);
  };

  const onCreateEquation = () => {
    let equation = "";
    equationItemList?.forEach((equationItem, index) => {
      if (equationItem?.type === "operator") {
        equation = `${equation}${equationItem?.value}`;
      } else if (equationItem?.type === "equation") {
        if (equationItem?.year) {
          equation = `${equation}{{${equationItem?.year}|${equationItem?.equationType}|${equationItem?.value}}}`;
        } else {
          equation = `${equation}{{${equationItem?.equationType}|${equationItem?.value}}}`;
        }
      }
    });
    console.log("Equation Post Values", name, equation, query?.reportId);
    const reportId = query?.reportId;
    if (name && equation && reportId) {
      if (selectedEquationItem?.id) {
        updateAccountEquationHook(
          {
            name: name,
            equation: equation,
            reportId: reportId,
            description: name,
            id: selectedEquationItem?.id,
            period:
              isCurrentYear && isPrevYear
                ? "both"
                : isCurrentYear
                ? "current"
                : isPrevYear
                ? "previous"
                : "",
          },
          (response) => {
            console.log("Equation Updated created");
            notification.success({
              message: "Equation Updated Successfully!",
            });
            handleClose();
          }
        );
      } else {
        createAccountEquationHook(
          {
            name: name,
            equation: equation,
            reportId: reportId,
            description: name,
            period:
              isCurrentYear && isPrevYear
                ? "both"
                : isCurrentYear
                ? "current"
                : isPrevYear
                ? "previous"
                : "",
          },
          (response) => {
            console.log("Equation Successfully created");
            notification.success({
              message: "Equation Created Successfully!",
            });
            handleClose();
          }
        );
      }
    }
  };

  const deleteEquation = () => {
    console.log("EquationId", selectedEquationItem?.id);
    deleteAccountEquationHook(
      {
        id: selectedEquationItem?.id,
      },
      (response) => {
        console.log("Equation Deleted Successfully!!", response);
        notification.success({
          message: "Equation Deleted Successfully!",
        });
        handleClose();
      }
    );
  };

  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      submitText="Create Equation"
      title={selectedEquationItem?.id ? "Edit Equation" : "Create New Equation"}
      width={832}
      footer={showFooter}
      handleOk={onAddEquation}
    >
      <div className="equation-modal-height">
        <div className={"table-balance"}>
          {!showAddEquation && (
            <div className="goto-modal">
              {/* <img src={mathematicequation} alt="mathematicequation" /> */}
              <div className="components-text-fields">
                <div style={{ marginBottom: "5px" }}>Equation Name</div>
                <ComponentInput
                  placeholder="Enter Name"
                  value={name}
                  onChange={(value) => {
                    setName(value);
                  }}
                  style={{ maxWidth: "300px" }}
                />
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Operator
                    operator="+"
                    onClick={() => {
                      addOperator("+");
                    }}
                  />
                  <Operator
                    operator="-"
                    onClick={() => {
                      addOperator("-");
                    }}
                  />
                  <Operator
                    operator="("
                    onClick={() => {
                      addOperator("(");
                    }}
                  />
                  <Operator
                    operator=")"
                    onClick={() => {
                      addOperator(")");
                    }}
                  />
                  <div
                    className="add-equation-button"
                    style={{ marginRight: "10px" }}
                    onClick={addEquation}
                  >
                    <PlusOutlined style={{ marginRight: "8px" }} />
                    <span>Add Equation</span>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Paragraph level={5} style={{ margin: "0 10px 0px 50px" }}>
                      Apply For
                    </Paragraph>
                    <Checkbox
                      defaultChecked={isCurrentYear}
                      className="create-equation-checkbox"
                      onChange={(e) => setCurrentYear(e.target.checked)}
                    >
                      {currentYear}
                    </Checkbox>
                    <Checkbox
                      defaultChecked={isPrevYear}
                      className="create-equation-checkbox"
                      onChange={(e) => setPrevYear(e.target.checked)}
                    >
                      {previousYear}
                    </Checkbox>
                  </div>
                </div>

                <div
                  style={{
                    backgroundColor: "#FAFBFF",
                    borderBottom: "1.5px solid #E6E8F0",
                    marginTop: "20px",
                    marginBottom: "10px",
                    padding: "0px 20px",
                  }}
                >
                  <Row gutter={16}>
                    <Col className="gutter-row" span={6}>
                      <div style={style}>Year</div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <div style={style}>Type</div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <div style={style}>Name/Code</div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                      <div style={style}>Amount</div>
                    </Col>
                  </Row>
                </div>
                <SortableList
                  items={equationItemList}
                  onSortEnd={onSortEnd}
                  yearList={yearList}
                  // onUpdateScenarioName={onUpdateScenarioName}
                  useDragHandle
                  previewObj={previewObj}
                  itemAmountObj={itemAmountObj}
                  onDeleteEquationItem={onDeleteEquationItem}
                  onUpdateEquationItemValue={onUpdateEquationItemValue}
                  // stepObj={stepObj}
                  // setSelectedScenario={setSelectedScenario}
                  // selectedScenario={selectedScenario}
                  // onRemove={onRemove}
                  // setStepObj={setStepObj}
                  // updateStepToSecnario={updateStepToSecnario}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div style={{ display: "flex", marginTop: "20px" }}>
        <div style={{ flex: "1 1 auto" }}>
          {selectedEquationItem?.id && (
            <>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  color: "#EE9191",
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: "500",
                }}
                onClick={deleteEquation}
              >
                <TrashIcon
                  style={{ marginRight: "10px", width: "12px", height: "12px" }}
                />
                Delete Equation
              </div>
            </>
          )}
        </div>
        <div>
          <Button style={{ marginRight: "19px" }} onClick={handleClose}>
            Cancel
          </Button>
          <ComponentButton
            title={selectedEquationItem?.id ? "Save" : "Create"}
            onClick={onCreateEquation}
          />
        </div>
      </div>
      {/* {isSelectEquationDialog && (
        <SelectEquationModal
          open={isSelectEquationDialog}
          handleClose={closeEquationSelectDialog}
          type={type}
          previewData={previewData}
          setNewlyFormedEquation={setNewlyFormedEquation}
          setIsSelectEquationDialog={setIsSelectEquationDialog}
          setIsCreateNewEquationDialog={setIsCreateNewEquationDialog}
        />
      )}
      {isCreateNewEquationDialog && (
        <CreateNewEquationModal
          open={isCreateNewEquationDialog}
          handleClose={closeCreateNewEquationDialog}
          type={type}
          setNewlyFormedEquation={setNewlyFormedEquation}
          handleOk={checkClickedBtn}
          setNewSelectedType={setNewSelectedType}
          setNewSelectedPeriod={setNewSelectedPeriod}
          setNewSelectedAccount={setNewSelectedAccount}
          newEquationResponseData={newEquationResponseData}
        />
      )} */}
    </ComponentModal>
  );
};

export default CreateEquationModal;
