/* eslint-disable no-unused-vars */
import { groupBy } from "lodash";
import React, { FC } from "react";

// interface Props {
//   items: any;
//   selectItem: (idx: string) => void;
// }

const CommandsList = (props) => {
  const { items, selectItem } = props;

  const group = groupBy(items, "category");
  console.log("Items Items", items, group);
  return (
    <ul style={{ width: "300px" }}>
      {Object?.keys(group)?.map((groupKey, groupIndex) => {
        const items = group[groupKey];
        return (
          <div
            style={{ borderBottom: "1px solid #d8dae5", marginBottom: "15px" }}
          >
            <div className="slash-commands-group-container">{groupKey}</div>
            {items.map(({ title, category }, idx) => {
              return (
                <li
                  key={idx}
                  onClick={() => {
                    console.log("Selected Index", idx, title);
                    selectItem(idx, title, category);
                  }}
                >
                  <div className="command-list-item-container">{title}</div>
                </li>
              );
            })}
          </div>
        );
      })}
    </ul>
  );
};

export default CommandsList;
