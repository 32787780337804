import React, { useEffect, useState } from "react";
import ComponentModal from "../../atoms/ComponentModal";

import "./CategoryWarningModal.less";
import ComponentButton from "../../atoms/ComponentButton";
import { Button } from "antd";
const CategoryWarningModal = ({
  open,
  handleClose,
  showAllCategoryData,
  setShowCategoryDataModal,
  onCreateNewTemplate,
}) => {
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Save anyway"
      submitText="Okay"
      title="Maping Warning"
      handleOk={() => {
        setShowCategoryDataModal(false);
      }}
      footer={null}
    >
      <div style={{ fontWeight: "500" }}>
        Maping is Incomplete for the following Accounts :
      </div>
      <div style={{ marginTop: "10px" }}>
        {showAllCategoryData.map((item) => {
          return (
            <>
              <div>{item?.label}</div>
            </>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          marginTop: "20px",
        }}
      >
        <ComponentButton
          title="Okay"
          style={{ marginLeft: "15px" }}
          onClick={() => {
            setShowCategoryDataModal(false);
          }}
        ></ComponentButton>
        <Button key="back" title="Save anyway" onClick={onCreateNewTemplate}>
          Save anyway
        </Button>
      </div>
    </ComponentModal>
  );
};

export default CategoryWarningModal;
