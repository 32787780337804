import axios from "axios";
import api from "./api-intercepter";

export const fetchComapnyWithReportsHook = (callback) => {
  api
    .get("/companies")
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const fetchCompaniesHook = (callback) => {
  return api
    .get("/companies")
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const createCompanyHook = (comapnyValue, callback) => {
  const data = JSON.stringify({
    name: comapnyValue,
  });
  api
    .post("/companies", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const deleteCompanyHook = (values, callback) => {
  console.log("delete valuers",values?.id)
  api
    .delete(`/companies/${values?.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateCompanyHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
  });
  api
    .patch(`/companies/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
