import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
// import ComponentModal from "../../../common/ComponentModal";
// import ComponentSwitch from "../../../common/ComponentSwitch";

import ComponentModal from "../../atoms/ComponentModal";
import ComponentSwitch from "../../atoms/ComponentSwitch";

// import { getTemplateData } from "./templateData";
import { v4 as uuidv4 } from "uuid";

import "./ReportTemplatesModal.less";
import ReportSectionsHook from "./ReportSectionsHook";
import { useSelector } from "react-redux";
import {
  bulkCreateReportGeneratorsHook,
  createReportGeneratorsHook,
  getReportByIdHook,
} from "../../api-hooks/report";

import { useRouter } from "../../routes";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ReportTemplatePreviewVersion from "./ReportTemplatePreviewVersion";

// import styles from "./index.less";
const ReportTemplatesModal = ({
  open,
  handleClose,
  setCurUnitType,
  onAddFun,
  setIsNotePopupOpened,
  setAddModalVisible,
  type,
  setSelectedTemplateList,
  selectedTemplateList,
  addFn,
  setIsSectionDialogOpened,
}) => {
  const router = useRouter();
  const { query } = router;
  const templateList = useSelector((state) => state.reportTemplates?.[type]);

  const reportSections = useSelector(
    (state) => state.report?.selectedReport?.sections
  );

  const [selectedVisibleIndex, setSelectedVisibleIndex] = useState(0);
  //   const templateList = getTemplateData();
  const [templateNotesData, setTemplateNotesData] = useState([]);
  const [selectedIndustryId, setSelectedIndustryId] = useState("");

  const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);
  console.log("selectedTemplateIds 1", selectedTemplateIds);

  useEffect(() => {
    if (reportSections?.length) {
      const lastVisibleIndex =
        reportSections[reportSections.length - 1]?.visibleIndex;
      setSelectedVisibleIndex(lastVisibleIndex);
    }
  }, [reportSections]);

  useEffect(() => {
    if (query?.reportId) {
      getReportByIdHook(query?.reportId, (response) => {
        if (response && response?.industry?.id) {
          setSelectedIndustryId(response?.industry?.id);
        } else {
          setSelectedIndustryId("");
        }
      });
    }
  }, [query?.reportId]);

  const onInsertTemplate = () => {
    console.log("Selected template List", selectedTemplateList);
    addFn();
    handleClose();
    setIsSectionDialogOpened(false);
  };

  const onClose = () => {
    setSelectedTemplateList([]);
    handleClose();
  };

  console.log("Template List", templateList);
  console.log("selectedTemplateList", selectedTemplateList);
  const getVersionDropdown = (selectedVersionList) => {
    const dropdownList = [];
    selectedVersionList?.forEach((item) => {
      dropdownList.push({
        label: item?.name,
        value: item.id,
        ...item,
      });
    });
    return dropdownList;
  };

  console.log("selectedTemplateIds", selectedTemplateIds);
  const getIsTemplatesEnabled = (categoryId) => {
    return selectedTemplateIds.indexOf(categoryId);
  };
  return (
    <ComponentModal
      open={open}
      handleClose={onClose}
      handleOk={onInsertTemplate}
      backText="Back"
      submitText="Insert Template"
      title={`Insert Template to ${type}`}
      width={1000}
    >
      <ReportSectionsHook type={type} selectedIndustryId={selectedIndustryId}>
        <div style={{ display: "flex" }}>
          <div
            style={{
              overflowY: "auto",
              borderRight: "1px solid #D8DAE5",
              flex: "1 1 auto",
              maxWidth: "350px",
              minWidth: "350px",
              marginTop: "16px",
            }}
          >
            {templateList?.map((item, index) => {
              return (
                <div style={{ padding: "0px 16px" }}>
                  <Checkbox
                    onChange={(e) => {
                      if (e.target.checked) {
                        if (selectedTemplateIds?.indexOf(item?.id) === -1) {
                          let sectionData = {
                            report: {
                              id: query.reportId,
                            },
                            name: item?.name,
                            type: type,
                            data: {
                              name: item?.name,
                              status: "IN_PROGRESS",
                              html: "",
                            },
                            versions: item?.versions,
                            visibleIndex: selectedVisibleIndex + 1,
                            templates: [],
                            templateId: item?.id,
                          };
                          console.log("sectionData", sectionData);
                          // if (
                          //   item?.templates?.length === 1 ||
                          //   !item?.templates[0]?.data?.name
                          // ) {
                          sectionData.data.html = item?.versions[0]?.data?.html;
                          // }
                          setSelectedVisibleIndex(selectedVisibleIndex + 1);
                          selectedTemplateList.push(sectionData);
                          setSelectedTemplateList([...selectedTemplateList]);
                          selectedTemplateIds.push(item?.id);
                          setSelectedTemplateIds([...selectedTemplateIds]);
                        }
                      } else {
                        const templateIndex = selectedTemplateIds?.indexOf(
                          item?.id
                        );
                        selectedTemplateIds.splice(templateIndex, 1);
                        setSelectedTemplateIds([...selectedTemplateIds]);
                        let updatedSelectedtemplateList = [];
                        selectedTemplateList?.forEach(
                          (selectedTemplate, index) => {
                            if (selectedTemplate?.templateId !== item?.id) {
                              updatedSelectedtemplateList.push(
                                selectedTemplate
                              );
                            }
                          }
                        );
                        setSelectedTemplateList([
                          ...updatedSelectedtemplateList,
                        ]);
                      }
                    }}
                  >
                    {item?.name}
                  </Checkbox>
                  {/* {item?.sectionTitle && (
                  <div className={"note-template-subtitle"}>
                    {item?.sectionTitle}
                  </div>
                )} */}
                  {selectedTemplateIds.indexOf(item.id) !== 1 && (
                    <>
                      <div className="notes-click-add-container ">
                        {" "}
                        Click to add
                      </div>
                      <div style={{ marginLeft: "28px" }}>
                        {item?.templates?.length > 0 &&
                          item?.templates?.map((section) => {
                            let sectionData =
                              selectedTemplateList[item.visibleIndex - 1];
                            console.log("Section Data", sectionData);
                            if (section?.data?.name) {
                              const templateIndex =
                                selectedTemplateIds?.indexOf(section?.id);
                              let style = {};
                              if (templateIndex !== -1) {
                                style = {
                                  borderColor: "#69CC73",
                                  color: "#69CC73",
                                  fontWeight: "500",
                                };
                              }
                              return (
                                <div
                                  className="notes-sub-note-container"
                                  style={style}
                                  onClick={() => {
                                    if (
                                      selectedTemplateIds?.indexOf(
                                        section?.id
                                      ) === -1
                                    ) {
                                      let sectionData = {
                                        report: {
                                          id: query.reportId,
                                        },
                                        name: section?.data?.name,
                                        type: section?.type,
                                        data: {
                                          name: section?.data?.name,
                                          status: "IN_PROGRESS",
                                          html: "",
                                        },
                                        versions: section?.templateVersions,
                                        visibleIndex: selectedVisibleIndex + 1,
                                        templateId: section?.id,
                                      };
                                      sectionData.data.html =
                                        section?.templateVersions[0]?.data?.html;
                                      // if (
                                      //   item?.templates?.length === 1 ||
                                      //   !item?.templates[0]?.data?.name
                                      // ) {

                                      // }
                                      setSelectedVisibleIndex(
                                        selectedVisibleIndex + 1
                                      );
                                      selectedTemplateList.push(sectionData);
                                      setSelectedTemplateList([
                                        ...selectedTemplateList,
                                      ]);
                                      selectedTemplateIds.push(section?.id);
                                      setSelectedTemplateIds([
                                        ...selectedTemplateIds,
                                      ]);
                                    } else {
                                      const templateIndex =
                                        selectedTemplateIds?.indexOf(
                                          section?.id
                                        );
                                      selectedTemplateIds.splice(
                                        templateIndex,
                                        1
                                      );
                                      setSelectedTemplateIds([
                                        ...selectedTemplateIds,
                                      ]);
                                      let updatedSelectedtemplateList = [];
                                      selectedTemplateList?.forEach(
                                        (selectedTemplate, index) => {
                                          if (
                                            selectedTemplate?.templateId !==
                                            section?.id
                                          ) {
                                            updatedSelectedtemplateList.push(
                                              selectedTemplate
                                            );
                                          }
                                        }
                                      );
                                      setSelectedTemplateList([
                                        ...updatedSelectedtemplateList,
                                      ]);
                                    }
                                  }}
                                >
                                  {section?.data?.name}
                                </div>

                                // <ComponentSwitch
                                //   name={section?.data?.name}
                                //   style={{ marginLeft: "28px", marginTop: "8px" }}
                                //   onChange={(checked) => {
                                //     if (checked) {
                                //       console.log("Checked", checked);
                                //       sectionData.templates.push({
                                //         id: section.id,
                                //         name: section?.data?.name,
                                //       });
                                //       if (sectionData.data.html) {
                                //         sectionData.data.html = `${sectionData.data.html}<br/>${section?.data?.html}`;
                                //       } else {
                                //         sectionData.data.html = section?.data?.html;
                                //       }

                                //       selectedTemplateList.splice(
                                //         item.visibleIndex - 1,
                                //         1,
                                //         sectionData
                                //       );
                                //       setSelectedTemplateList([
                                //         ...selectedTemplateList,
                                //       ]);

                                //       // const sectionData = {
                                //       //   report: {
                                //       //     id: query.reportId,
                                //       //   },
                                //       //   type: type,
                                //       //   data: {
                                //       //     ...section?.data,
                                //       //     status: "IN_PROGRESS",
                                //       //   },
                                //       //   visibleIndex: selectedVisibleIndex + 1,
                                //       // };
                                //       // setSelectedVisibleIndex(selectedVisibleIndex + 1);
                                //       // selectedTemplateList.push(sectionData);
                                //       // setSelectedTemplateList([...selectedTemplateList]);
                                //     }
                                //   }}
                                // />
                              );
                            }
                          })}
                      </div>
                    </>
                  )}
                </div>
              );
            })}
          </div>
          <div
            style={{
              overflowY: "auto",
              padding: "16px",
              flex: "1 1 auto",
              marginRight: "20px",
            }}
          >
            <div className="notes-preview-notes-container">Preview Notes</div>
            {selectedTemplateList?.length !== 0 &&
              selectedTemplateList?.map((selectedTemplate, index) => {
                console.log("Selected Template", selectedTemplate);
                return (
                  <div>
                    {" "}
                    <div className="notes-preview-note-heading">
                      {selectedTemplate?.name}
                    </div>
                    <ReportTemplatePreviewVersion
                      selectedTemplate={selectedTemplate}
                      selectedTemplateList={selectedTemplateList}
                      setSelectedTemplateList={setSelectedTemplateList}
                      indexValue={index}
                    />
                    {/* <div className="notes-preview-version-container">
                      <div className="notes-preview-disclaimer-text">
                        <sup>*</sup>This will not be displayed in your report
                      </div>
                      <div className="notes-preview-body-template">
                        Main Body template
                      </div>
                      <ComponentDropdown
                        optionList={getVersionDropdown(
                          selectedTemplate?.versions
                        )}
                        value={selectedTemplate?.versions?.[0]?.id}
                        style={{ width: "40%" }}
                      />
                    </div>
                    <div style={{ marginTop: "12px" }}>
                      {parse(selectedTemplate?.versions?.[0]?.data?.html)}
                    </div> */}
                  </div>
                );
              })}
          </div>
        </div>
      </ReportSectionsHook>
    </ComponentModal>
  );
};

export default ReportTemplatesModal;
