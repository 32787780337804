import { useEffect, useState } from "react";
import { useRouter } from "../../routes";
import Editor from "../../sub-components/Editor";
import dayjs from "dayjs";

import {
  getReportGeneratorHtmlHook,
  updateReportGeneratorHtmlHook,
  getPDFPreviewHook,
  convertWordHook,
  bulkPatchReportGeneratorsDataHook,
  deleteReportSectionHook,
  bulkPatchAndCreateReportGeneratorsHook,
  getReportGenerators,
  getReportAccountList,
} from "../../api-hooks/report";

import { saveAs } from "file-saver";
import { message } from "antd";
import debounce from "lodash/debounce";
import { useSelector } from "react-redux";
import MultiInstanceEditor from "../Editor/MultiInstanceEditor";
import { sortReportSections } from "../../helper";
import { cloneDeep } from "lodash";
import { useDispatch } from "react-redux";
import { setSelectedReport } from "../../store/report";
import {
  getAccountEquationHook,
  getEquationPreviewHook,
} from "../../api-hooks/account-equation";
import {
  getReportAccountSummaryHook,
  getTrialBalanceItemsHook,
} from "../../api-hooks/account";

const PreviewHtml = ({
  refreshKey,
  preview,
  content,
  setContent,
  previewContent,
  setPreviewContent,
}) => {
  const dispatch = useDispatch();
  const router = useRouter();
  const [saveLoading, setSaveLoading] = useState(false);
  const { query } = router;
  // const [content, setContent] = useState("");
  // const [previewContent, setPreviewContent] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [contentUpdatedObj, setContentUpdatedObj] = useState({});

  const [equationDataObj, setEquationDataObj] = useState({});

  const [accountObj, setAccountObj] = useState({});

  const sections = useSelector(
    (state) => state.report?.selectedReport?.sections
  );

  const getReportGeneratorHtml = (type) => {
    getReportGeneratorHtmlHook({ reportId: query?.reportId }, (response) => {
      if (response) {
        type === "init" && setContent(response.content);
        setPreviewContent(response.previewContent);
        setName(response.name);
        console.log(response);
      }
    });
  };

  useEffect(() => {
    // getReportGeneratorHtml("init");
  }, [refreshKey]);

  useEffect(() => {
    getAccountEquationHook(
      {
        reportId: query?.reportId,
      },
      (response) => {
        console.log("Report Equation Summary", response);
        let str = "";
        // response.map(() => {
        const itemList = response;

        itemList?.forEach((item) => {
          str =
            str +
            `current|equation|${item.name}:{{current|equation|${item.name}}}::previous|equation|${item.name}:{{previous|equation|${item.name}}}::`;
        });

        getTrialBalanceItemsHook(
          {
            reportId: query?.reportId,
          },
          (response) => {
            if (response?.length) {
              const itemList = response;
              itemList?.forEach((item) => {
                str =
                  str +
                  `current|trial_item|${item.name}:{{current|trial_item|${item.name}}}::previous|trial_item|${item.name}:{{previous|trial_item|${item.name}}}::`;
              });
            }
            getReportAccountSummaryHook(
              {
                reportId: query?.reportId,
              },
              (response) => {
                console.log("Account Summary", response);
                if (response) {
                  Object.keys(response)?.map((itemKey) => {
                    if (itemKey !== "null") {
                      console.log("Item Key", itemKey);
                      const childAccounts = response[itemKey];
                      const parentAccountType =
                        childAccounts[0]?.type === "income_item"
                          ? "income_header"
                          : "balance_header";
                      childAccounts?.forEach((childAccount) => {
                        str =
                          str +
                          `current|${childAccount?.type}|${childAccount?.item}:{{current|${childAccount?.type}|${childAccount?.item}}}::previous|${childAccount?.type}|${childAccount?.item}:{{previous|${childAccount?.type}|${childAccount?.item}}}::`;
                      });
                      str =
                        str +
                        `current|${parentAccountType}|${itemKey}:{{current|${parentAccountType}|${itemKey}}}::previous|${parentAccountType}|${itemKey}:{{previous|${parentAccountType}|${itemKey}}}::`;
                    }
                  });
                }
                str =
                  str +
                  "currency:{{currency}}::current|financialPositionEndDate|year:{{current|financialPositionEndDate|year}}::previous|financialPositionEndDate|year:{{previous|financialPositionEndDate|year}}::previous|financialPositionEndDate:{{previous|financialPositionEndDate}}::current|financialPositionEndDate:{{current|financialPositionEndDate}}::company:{{company}}";
                getEquationPreviewHook(
                  { reportId: query?.reportId, content: str },
                  (response) => {
                    if (response) {
                      const splittedList = response?.split("::");
                      console.log("Preview Hook Currency", splittedList);

                      let obj = {};
                      splittedList.forEach((dataItem) => {
                        const splittedData = dataItem?.split(":");
                        obj[`{{${splittedData?.[0]}}}`] = splittedData[1];
                      });
                      console.log("Equation Obj", obj);
                      setEquationDataObj(obj);
                    }
                  }
                );
              }
            );
          }
        );
      }
    );
  }, []);

  const onDeleteSection = (sectionId, index) => {
    deleteReportSectionHook(
      {
        id: sectionId,
      },
      (response) => {
        const updatedArray = [...sections];
        updatedArray.splice(index, 1);
        const updatedList = [];
        updatedArray.forEach((item, index) => {
          updatedList.push({
            ...item,
            visibleIndex: index + 1,
          });
        });

        // setList([...updatedList]);
        bulkPatchAndCreateReportGeneratorsHook(updatedList);
        setTimeout(() => {
          const reportId = query?.reportId;
          getReportGenerators({ reportId }, (response) => {
            if (response) {
              console.log("Resport Generators", response);
              if (response?.length) {
                const selectedReportData = {
                  sections: [],
                };
                response?.forEach((item) => {
                  if (item?.type === "coverPage") {
                    // setCoverPageData(item);
                    selectedReportData.coverPage = item;
                  } else if (item?.type === "pageHeader") {
                    // setHeaderData(item);
                    selectedReportData.pageHeader = item;
                  } else if (item?.type === "pageFooter") {
                    // setFooterData(item);
                    selectedReportData.pageFooter = item;
                  } else if (item?.type === "financialPositionEndDate") {
                    selectedReportData.financialPositionEndDate = item;
                  } else if (item?.type === "profitLossDate") {
                    selectedReportData.profitLossDate = item;
                  } else if (item?.type === "changesEquityDate") {
                    selectedReportData.changesEquityDate = item;
                  } else if (item?.type === "currency") {
                    selectedReportData.currency = item;
                  } else if (item?.type === "company") {
                    selectedReportData.company = item;
                  } else {
                    selectedReportData.sections.push(item);
                  }
                });

                const sortedSections = sortReportSections(
                  selectedReportData.sections
                );

                selectedReportData.sections = sortedSections;

                console.log("Sorted Sections", sortedSections);
                // setRefreshKey(Math.random());
                dispatch(setSelectedReport(cloneDeep(selectedReportData)));
              }
            }
          });
        }, 1000);
        // const sortedSections = sortReportSections(updatedArray);
        // setList([...sortedSections]);
        // dispatch(
        //   setSelectedReport({ ...selectedReportData, sections: sortedSections })
        // );
        console.log("Report Sections Deleted Successfully", response);
      }
    );
  };

  console.log("Eqaution Data Obj", equationDataObj);

  useEffect(() => {
    let accountObj = {
      "Trial Balance Account Names": {},
      "Balance Sheet Child Item": {},
      "Balance Sheet Parent Item": {},
      "Income Child Item": {},
      "Income Parent Item": {},
    };
    getReportAccountList({ reportId: query?.reportId }, (response) => {
      Object.keys(response)
        .sort()
        .forEach((key) => {
          const accounData = response[key]?.accounts;
          const firstItem = accounData[0];
          accountObj["Trial Balance Account Names"][firstItem?.name] =
            firstItem?.name;
          if (firstItem?.category?.type === "balance_item") {
            accountObj["Balance Sheet Child Item"][firstItem?.category?.name] =
              firstItem?.category?.name;
            accountObj["Balance Sheet Parent Item"][
              firstItem?.category?.parent?.name
            ] = firstItem?.category?.parent?.name;
          } else {
            accountObj["Income Child Item"][firstItem?.category?.name] =
              firstItem?.category?.name;
            accountObj["Income Parent Item"][
              firstItem?.category?.parent?.name
            ] = firstItem?.category?.parent?.name;
          }
        });
      setAccountObj(accountObj);
    });
  }, []);
  return (
    <>
      {Object.keys(equationDataObj)?.length !== 0 &&
        Object.keys(accountObj)?.length !== 0 && (
          <MultiInstanceEditor
            preview={preview}
            setData={setContent}
            accountObj={accountObj}
            isSaveButtonEnabled
            onPdf={(disablingPDF, onWord) => {
              if (loading) return;
              if (!disablingPDF) {
                setLoading(true);
                message.loading({
                  content: "Downloading PDF..",
                  duration: 0,
                  key: "loadingKey",
                });
              }

              getPDFPreviewHook({ reportId: query?.reportId }, (response) => {
                if (!disablingPDF) {
                  setLoading(false);
                  saveAs(
                    response,
                    `${name}-${dayjs().format("YYYYMMDDHHmmss")}.pdf`
                  );
                  message.destroy("loadingKey");
                  message.success("Loading finished", 2.5);
                } else {
                  onWord();
                }
              });
            }}
            onWord={() => {
              if (loading) return;
              setLoading(true);
              message.loading({
                content: "Downloading Word..",
                duration: 0,
                key: "loadingKey",
              });
              convertWordHook({ reportId: query?.reportId }, (response) => {
                setLoading(false);
                saveAs(
                  response,
                  `${name}-${dayjs().format("YYYYMMDDHHmmss")}.docx`
                );
                message.destroy("loadingKey");
                message.success("Loading finished", 2.5);
              });
            }}
            type="content"
            // onSwitch={() => {
            //   getReportGeneratorHtml();
            // }}
            onSave={debounce((type, data, callback) => {
              console.log("Content Data Obj", contentUpdatedObj);
              const list = [];
              if (Object.keys(contentUpdatedObj)?.length) {
                setSaveLoading(true);
                Object.keys(contentUpdatedObj)?.forEach((sectionId, index) => {
                  const html = contentUpdatedObj[sectionId]?.html;
                  console.log("Sections Data", sections, index);
                  list.push({
                    id: sectionId,
                    html: html,
                    name: contentUpdatedObj[sectionId]?.name,
                  });
                });

                console.log("Final List", list);
                bulkPatchReportGeneratorsDataHook(list, (response) => {
                  console.log("I am getting called");

                  const reportId = query?.reportId;
                  getReportGenerators({ reportId }, (response) => {
                    if (response) {
                      console.log("Resport Generators", response);
                      if (response?.length) {
                        const selectedReportData = {
                          sections: [],
                        };
                        response?.forEach((item) => {
                          if (item?.type === "coverPage") {
                            // setCoverPageData(item);
                            selectedReportData.coverPage = item;
                          } else if (item?.type === "pageHeader") {
                            // setHeaderData(item);
                            selectedReportData.pageHeader = item;
                          } else if (item?.type === "pageFooter") {
                            // setFooterData(item);
                            selectedReportData.pageFooter = item;
                          } else if (
                            item?.type === "financialPositionEndDate"
                          ) {
                            selectedReportData.financialPositionEndDate = item;
                          } else if (item?.type === "profitLossDate") {
                            selectedReportData.profitLossDate = item;
                          } else if (item?.type === "changesEquityDate") {
                            selectedReportData.changesEquityDate = item;
                          } else if (item?.type === "currency") {
                            selectedReportData.currency = item;
                          } else if (item?.type === "company") {
                            selectedReportData.company = item;
                          } else {
                            selectedReportData.sections.push(item);
                          }
                        });

                        const sortedSections = sortReportSections(
                          selectedReportData.sections
                        );

                        selectedReportData.sections = sortedSections;

                        console.log("Sorted Sections", sortedSections);
                        // setRefreshKey(Math.random());
                        dispatch(
                          setSelectedReport(cloneDeep(selectedReportData))
                        );
                        callback(response);
                        setContentUpdatedObj({});
                        setSaveLoading(false);
                      }
                    }
                  });
                });
              } else {
                callback();
              }

              // updateReportGeneratorHtmlHook(
              //   {
              //     reportId: query?.reportId,
              //     content: data,
              //   },
              //   (response) => {
              //     getReportGeneratorHtml("init");
              //   }
              // );
            }, 200)}
            data={null}
            previewData={null}
            sections={sections}
            setContentUpdatedObj={setContentUpdatedObj}
            contentUpdatedObj={contentUpdatedObj}
            onDeleteSection={onDeleteSection}
            equationDataObj={equationDataObj}
            saveLoading={saveLoading}
          />
        )}

      {/* {sections?.length !== 0 &&
        sections?.map((item) => {
          console.log("Data Html", item?.data?.html);
          return <div style={{ border: "1px dashed" }}></div>;
        })} */}
    </>

    // <Editor
    //   preview={preview}
    //   setData={setContent}
    //   isSaveButtonEnabled
    //   onPdf={(disablingPDF, onWord) => {
    //     if (loading) return;
    //     if (!disablingPDF) {
    //       setLoading(true);
    //       message.loading({
    //         content: "Downloading PDF..",
    //         duration: 0,
    //         key: "loadingKey",
    //       });
    //     }

    //     getPDFPreviewHook({ reportId: query?.reportId }, (response) => {
    //       if (!disablingPDF) {
    //         setLoading(false);
    //         saveAs(response, `${name}-${dayjs().format("YYYYMMDDHHmmss")}.pdf`);
    //         message.destroy("loadingKey");
    //         message.success("Loading finished", 2.5);
    //       } else {
    //         onWord();
    //       }
    //     });
    //   }}
    //   onWord={() => {
    //     if (loading) return;
    //     setLoading(true);
    //     message.loading({
    //       content: "Downloading Word..",
    //       duration: 0,
    //       key: "loadingKey",
    //     });
    //     convertWordHook({ reportId: query?.reportId }, (response) => {
    //       setLoading(false);
    //       saveAs(response, `${name}-${dayjs().format("YYYYMMDDHHmmss")}.docx`);
    //       message.destroy("loadingKey");
    //       message.success("Loading finished", 2.5);
    //     });
    //   }}
    //   type="content"
    //   onSwitch={() => {
    //     getReportGeneratorHtml();
    //   }}
    //   onSave={debounce((type, data) => {
    //     updateReportGeneratorHtmlHook(
    //       {
    //         reportId: query?.reportId,
    //         content: data,
    //       },
    //       (response) => {
    //         getReportGeneratorHtml("init");
    //       }
    //     );
    //   }, 200)}
    //   data={content}
    //   previewData={previewContent}
    // />
  );
};

export default PreviewHtml;
