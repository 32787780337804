import api from "./api-intercepter";
export const getReportTemplatesByTypeHook = (type, industryId, callback) => {
  let url = `/report-template-categories?join=templates&&filter=type||eq||${type}`;
  if (industryId) {
    url = `/report-template-categories?join=templates&&filter=type||eq||${type}&&filter=industry.id||eq||${industryId}`;
  }
  api
    // .get(
    //   `/report-template-categories?join=templates&&filter=type||eq||${type}&&filter=name||cont||${companyName} -`
    // )
    .get(url)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
