// import logo from "../../assets/logo.svg";
import logo from "../../assets/logo-cannex.svg";
import { Form, Input, message } from "antd";

import "../Login/Login.less";
import ComponentInput from "../../atoms/ComponentInput";
import { LockOutlined, MailOutlined, UserOutlined } from "@ant-design/icons";
import ComponentButton from "../../atoms/ComponentButton";
import { Link } from "react-router-dom";
import { LOGIN_URL } from "../../routes";
import { signupHook } from "../../api-hooks/user";
const Signup = () => {
  const onFinish = (values) => {
    if (values.firstName && values.email && values.password) {
      signupHook(values, (response) => {
        if (response?.message) {
          message.error(response?.message);
        } else {
          console.log("Signup response", response);
          if (response?.accessToken) {
            console.log("Login response", response?.accessToken);
            localStorage.setItem("authToken", response?.accessToken);
            window.location.href = "/";
          }
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className="login-container">
      <div className="login-card-container">
        <img src={logo} alt="logo" />
        <div className="login-sign-in-text">SIGN UP</div>
        <div className="login-sign-in-welcome">Welcome to CanCan</div>

        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Form.Item
              name="firstName"
              rules={[
                {
                  required: true,
                  message: "Please input your first name!",
                },
              ]}
              style={{ marginRight: "10px" }}
              marginRight="10px"
            >
              <ComponentInput
                placeholder="First Name"
                style={{ height: "42px", borderRadius: "8px" }}
                prefix={<UserOutlined style={{ marginRight: "12px" }} />}
              />
            </Form.Item>
            <Form.Item name="lastName" style={{ marginLeft: "10px" }}>
              <ComponentInput
                placeholder="Last Name"
                style={{ height: "42px", borderRadius: "8px" }}
                prefix={<UserOutlined style={{ marginRight: "12px" }} />}
              />
            </Form.Item>
          </div>

          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <ComponentInput
              placeholder="Email"
              style={{ height: "42px", borderRadius: "8px" }}
              prefix={<MailOutlined style={{ marginRight: "12px" }} />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              style={{ height: "42px", borderRadius: "8px" }}
              prefix={<LockOutlined style={{ marginRight: "12px" }} />}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "10px" }}>
            <ComponentButton
              title="SIGN IN"
              htmlType="submit"
              size="large"
              style={{ width: "100%", fontSize: "14px" }}
            />
          </Form.Item>

          <div style={{ textAlign: "center", color: "#999" }}>
            Already a user?{" "}
            <Link to={LOGIN_URL}>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4bb354",
                  cursor: "pointer",
                }}
              >
                SIGN IN
              </span>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Signup;
