import api from "./api-intercepter";

export const createAccountMappingHook = (values, callback) => {
  const data = JSON.stringify({
    category: {
      id: values?.categoryId,
    },
  });

  api
    .patch(`/accounts/${values?.accountId}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const removeMappingHook = (values, callback) => {
  const data = JSON.stringify({
    category: null,
  });

  api
    .patch(`/accounts/${values?.accountId}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const getReportAccountSummaryHook = (values, callback) => {
  api
    .get(`/accounts/summary/${values?.reportId}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const getTrialBalanceItemsHook = (values, callback) => {
  api
    .get(`/data-sources/trial-balance-data/${values?.reportId}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
