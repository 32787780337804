import { useState } from "react";
import ComponentPopup from "../../atoms/ComponentPopup";
import {
  EllipsisOutlined,
  DeleteOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";

import { ReactComponent as PageBreakIcon } from "../../assets/svg-icons/pageBreakIcon.svg";
import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";

const ReportSectionMenu = ({
  onDeleteSection,
  item,
  indexValue,
  onInsertPageBreak,
  onSaveContent,
  setIsRenameDialogOpened,
  onAddNewSection,
  onInsertPageBreakAbove,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);

  return (
    <ComponentPopup
      trigger={
        <div
          className={"saved-draft-item-menu"}
          style={{ marginLeft: "20px" }}
          onClick={() => {
            setIsPopupOpened(true);
          }}
        >
          <EllipsisOutlined />
        </div>
      }
      handleClose={() => {
        console.log("On Handle close");
        setIsPopupOpened(false);
      }}
      title="SELECT ACTION"
      open={isPopupOpened}
    >
      <div>
        {/* <PopupItem name="Save as new version" Icon={CopyOutlined} />
    <PopupItem name="Approve as final version" Icon={CheckCircleFilled} /> */}
        {/* <PopupItem
          name="Rename"
          Icon={EditIcon}
          // isDelete
          onClick={() => {
            onInsertPageBreak();
            setIsPopupOpened(false);
          }}
        /> */}
        <PopupItem
          name="Rename"
          Icon={EditIcon}
          // isDelete
          onClick={() => {
            setIsRenameDialogOpened(true);
            // onSaveContent(() => {
            //   onInsertPageBreak();
            //   setIsPopupOpened(false);
            // });
          }}
        />
        <PopupItem
          name="Insert Page Break Above"
          Icon={PageBreakIcon}
          // isDelete
          onClick={() => {
            onSaveContent(() => {
              onInsertPageBreakAbove();
              setIsPopupOpened(false);
            });
          }}
        />
        <PopupItem
          name="Insert Page Break Below"
          Icon={PageBreakIcon}
          // isDelete
          onClick={() => {
            onSaveContent(() => {
              onInsertPageBreak();
              setIsPopupOpened(false);
            });
          }}
        />
        <PopupItem
          name="Add Section Above"
          Icon={CaretUpOutlined}
          onClick={() => {
            onAddNewSection(item?.id, indexValue, indexValue + 1);
          }}
        />
        <PopupItem
          name="Add Section Below"
          Icon={CaretDownOutlined}
          onClick={() => {
            onAddNewSection(item?.id, indexValue, indexValue + 2);
          }}
        />
        <PopupItem
          name="Delete"
          Icon={DeleteOutlined}
          isDelete
          onClick={() => {
            onSaveContent(() => {
              onDeleteSection(item?.id, indexValue);
            });
          }}
        />
      </div>
    </ComponentPopup>
  );
};

export default ReportSectionMenu;
