import { useParams, useLocation, useHistory, useMatch } from "react-router-dom";
import queryString from "query-string";
import { useMemo } from "react";
export const HOME_URL = "/";
export const LOGIN_URL = "/login";
export const SIGN_UP_URL = "/signup";
export const FINANCIAL_REPORT_URL = "/financial-report";
export const NOTES_TEMPLATE_URL = "/notes-template";
export const REPORT_DASHBOARD_URL = "/report-dashboard";
export const CREATE_FINANCIAL_REPORT_URL = "/financial-report/create";
export const EDIT_FINANCIAL_REPORT_URL = "/financial-report/edit";
export const REPORT_DRIVE_URL = "/report-drive";

export const useRouter = () => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  //   const match = useMatch();
  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,

      query: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },

      //   match,
      location,
      history,
    };
  }, [params, location, history]);
};
