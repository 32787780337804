// import less
import { useEffect, useState } from "react";
import ComponentInput from "../../atoms/ComponentInput";
import ComponentModal from "../../atoms/ComponentModal";
import "./DuplicateReportModal.less";

const DuplicateReportModal = ({
  open,
  handleClose,
  duplicateReportId,
  duplicateName,
  onDuplicate,
  closePreviousModal,
}) => {
  const [name, setName] = useState(duplicateName);

  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Back"
      submitText="Confirm"
      title="Save Report"
      handleOk={() => {
        onDuplicate(duplicateReportId, name);
        handleClose();
        closePreviousModal && closePreviousModal();
      }}
    >
      <div className={"save-reports-heading-container"}>
        <div className={"save-reports-heading-title"}>Report Name</div>
        <ComponentInput
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
      </div>
    </ComponentModal>
  );
};

export default DuplicateReportModal;
