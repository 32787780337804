import React, { useState } from "react";
import { useHistory } from "react-router";
import {
  FileOutlined,
  EllipsisOutlined,
  CopyOutlined,
  CheckCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";

import ComponentPopup from "../../atoms/ComponentPopup";
import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import DeleteReportModal from "../../sub-components/DeleteReportModal";

import { ReactComponent as EditIcon } from "../../assets/svg-icons/editIcon.svg";
import { ReactComponent as CopyIcon } from "../../assets/svg-icons/copyIcon.svg";
import RenameReportModal from "../../sub-components/RenameReportModal";
import SaveReportModal from "../../sub-components/SaveReportModal";
import DuplicateReportModal from "../../sub-components/DuplicateReportModal";
import {
  duplicateReportHook,
  fetchReportTemplates,
  updateIsTemplateStatus,
} from "../../api-hooks/report";
import { useDispatch } from "react-redux";
import { setReportTemplates } from "../../store/report";
import { notification } from "antd";

const DraftItem = ({
  companyItem,
  draftItem,
  onSelect,
  fetchCompanyWithReports,
}) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [isRenameDialogOpened, setIsRenameDialogOpened] = useState(false);
  const [isNextYearReport, setIsNextYearReport] = useState(false);
  const [isCopyReport, setIsCopyReport] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const onDuplicate = (reportId, name) => {
    console.log("Duplicate Report Id", reportId);
    duplicateReportHook({ reportId, name }, (response) => {
      console.log("Duplicate Report Response", response);
      history.push(`/financial-report/create?reportId=${response?.id}&step=1`);
    });
  };

  return (
    <div
      className={"saved-draft-item-container"}
      onClick={() => {
        onSelect(draftItem?.id, draftItem?.name);
      }}
    >
      <FileOutlined color="#C1C4D6" />
      <div style={{ marginLeft: "9.5px", flex: "1 1 auto" }}>
        {draftItem?.name}
      </div>
      <ComponentPopup
        trigger={
          <div
            className={"saved-draft-item-menu"}
            onClick={() => {
              setIsPopupOpened(true);
            }}
          >
            <EllipsisOutlined />
          </div>
        }
        handleClose={() => {
          console.log("On Handle close");
          setIsPopupOpened(false);
        }}
        title="SELECT ACTION"
        open={isPopupOpened}
      >
        <div>
          {/* <PopupItem name="Save as new version" Icon={CopyOutlined} />
          <PopupItem name="Approve as final version" Icon={CheckCircleFilled} /> */}
          <PopupItem
            name="Edit"
            Icon={EditIcon}
            // isDelete
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              history.push(
                `/financial-report/edit?reportId=${draftItem?.id}&step=2`
              );
              // navigate(
              //   `/financial-report/create?reportId=${draftItem?.id}&step=2`
              // );
              // setIsDeleteDialogOpened(true);
              // setIsPopupOpened(false);
            }}
          />
          <PopupItem
            name="Rename"
            Icon={EditIcon}
            // isDelete
            onClick={(e) => {
              setIsRenameDialogOpened(true);
              setIsPopupOpened(false);
              // navigate(
              //   `/financial-report/create?reportId=${draftItem?.id}&step=2`
              // );
              // setIsDeleteDialogOpened(true);
              // setIsPopupOpened(false);
            }}
          />
          <PopupItem
            name="Roll forward to next year"
            Icon={CopyIcon}
            onClick={(e) => {
              setIsNextYearReport(true);
              setIsPopupOpened(false);
            }}
          />
          <PopupItem
            name="Copy to other companies"
            Icon={CopyIcon}
            onClick={(e) => {
              setIsCopyReport(true);
              setIsPopupOpened(false);
            }}
          />
          <PopupItem
            name="Use as template"
            Icon={CopyIcon}
            onClick={(e) => {
              updateIsTemplateStatus(
                {
                  id: draftItem?.id,
                  isTemplate: true,
                },
                (response) => {
                  if (response?.id) {
                    notification.success({
                      message: "Marked as template successfully!",
                      placement: "top",
                    });
                    fetchReportTemplates((response) => {
                      if (response) {
                        dispatch(setReportTemplates(response));
                      }
                    });
                  }
                }
              );
            }}
          />
          <PopupItem
            name="Delete"
            Icon={DeleteOutlined}
            isDelete
            onClick={() => {
              setIsDeleteDialogOpened(true);
              setIsPopupOpened(false);
            }}
          />
        </div>
      </ComponentPopup>
      {isDeleteDialogOpened && (
        <DeleteReportModal
          open={isDeleteDialogOpened}
          name={draftItem?.name}
          reportId={draftItem?.id}
          fetchCompanyWithReports={fetchCompanyWithReports}
          handleClose={() => {
            setIsDeleteDialogOpened(false);
          }}
        />
      )}
      {isRenameDialogOpened && (
        <RenameReportModal
          open={isRenameDialogOpened}
          reportName={draftItem?.name}
          reportId={draftItem?.id}
          handleClose={() => {
            setIsRenameDialogOpened(false);
          }}
        />
      )}
      {isNextYearReport && (
        <DuplicateReportModal
          open={isNextYearReport}
          handleClose={() => setIsNextYearReport(false)}
          duplicateReportId={draftItem?.id}
          duplicateName={`Copy of ${draftItem?.name}`}
          onDuplicate={onDuplicate}
        />
      )}
      {isCopyReport && (
        <SaveReportModal
          open={isCopyReport}
          handleClose={() => {
            setIsCopyReport(false);
          }}
          selectedItem={{ ...draftItem, company: companyItem }}
        />
      )}
    </div>
  );
};
export default DraftItem;
