import { ArrowLeftOutlined, LeftOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";
import { getReportAccountSummaryHook } from "../../api-hooks/account";

import { getReportByIdHook } from "../../api-hooks/report";
import { useRouter } from "../../routes";
import MakeAdjustmentModal from "../../sub-components/MakeAdjustmentModal";
import PreviewReportSummaryHoc from "../../sub-components/PreviewReportSummaryHoc";
import { useSelector } from "react-redux";
import { numberWithCommas } from "../../helper";
import ComponentButton from "../../atoms/ComponentButton";
import RearrangeModal from "../../sub-components/RearrangeModal/RearrangeModal";
import { orderBy } from "lodash";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ReactComponent as DragIcon } from "../../assets/svg-icons/DragIcon.svg";
import { updateCategoryHook } from "../../api-hooks/categories";

const sampleIncomeStatementTemplate = {
  revenue: {
    id: "revenue",
    name: "Revenue",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
  },
  "cost of services": {
    id: "cost of services",
    name: "Cost of services",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: true,
  },
  // "gross profit": {
  //   id: "gross profit",
  //   name: "Gross Profit",
  //   currentPeriod: 0,
  //   previousPeriod: 0,
  //   isUnderline: false,
  //   isBold: true,
  // },
  "other income": {
    id: "other income",
    name: "Other income",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
  },
  "administrative expenses": {
    id: "administrative expenses",
    name: "Administrative expenses",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
  },
  // "operating and administrative expenses": {
  //   id: "operating and administrative expenses",
  //   name: "Operating and administrative expenses",
  //   currentPeriod: 0,
  //   previousPeriod: 0,
  //   isUnderline: false,
  // },
  "other operating expenses": {
    id: "other operating expenses",
    name: "Other operating expenses",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
  },
  "finance costs": {
    id: "finance costs",
    name: "Finance costs",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: true,
  },
  // "profit (loss) before tax": {
  //   id: "profit (loss) before tax",
  //   name: "Profit (loss) before tax",
  //   currentPeriod: 0,
  //   previousPeriod: 0,
  //   isUnderline: false,
  //   isBold: true,
  // },
  "income tax expense": {
    id: "income tax expense",
    name: "Income tax expense",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: true,
  },
  // "profit (loss) for the year": {
  //   id: "profit (loss) for the year",
  //   name: "Profit (loss) for the year",
  //   currentPeriod: 0,
  //   previousPeriod: 0,
  //   isUnderline: true,
  //   isBold: true,
  // },
};

const sampleBalanceSheetTemplate = {
  "non-current assets": {
    id: "non-current assets",
    name: "Non-current assets",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
  "current assets": {
    id: "current assets",
    name: "Current assets",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
  "current liabilities": {
    id: "current liabilities",
    name: "Current liabilities",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
  "net current assets": {
    id: "net current assets",
    name: "Net current assets",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
  "total assets less current liabilities": {
    id: "total assets less current liabilities",
    name: "Total assets less current liabilities",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
  equity: {
    id: "equity",
    name: "Equity",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
  "non-current liabilities": {
    id: "non-current liabilities",
    name: "Non-current liabilities",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
  "total equity and non-current liabilities": {
    id: "total equity and non-current liabilities",
    name: "Total equity and non-current liabilities",
    currentPeriod: 0,
    previousPeriod: 0,
    isUnderline: false,
    children: [],
  },
};

const PreviewSheet = ({ title, type, setIsSheetPreviewEnabled }) => {
  const router = useRouter();
  const { query } = router;

  const [previewData, setPreviewData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [isMakeAdjustmentDialogOpened, setIsMakeAdjustmentDialogOpened] =
    useState(false);
  const [currentYear, setCurrentYear] = useState("2022");
  const [previousYear, setPreviousYear] = useState("2021");

  const reportPeriodObj = useSelector((state) => state.report.reportPeriod);
  const [rearrangeModal, setRearrangeModal] = useState(false);
  let rearrangeList = [{}];

  // useEffect(() => {
  //   getReportAccountSummaryHook(
  //     {
  //       reportId: query?.reportId,
  //     },
  //     (response) => {
  //       console.log("Report Account Summary", response);
  //       // const data = {};

  //       Object.keys(response).map((key) => {
  //         const itemList = response[key];
  //         let currentPeriod = 0;
  //         let previousPeriod = 0;
  //         itemList?.forEach((item) => {
  //           currentPeriod = currentPeriod + Number(item?.currentPeriod);
  //           previousPeriod = previousPeriod + Number(item?.previousPeriod);
  //         });
  //         if (itemList?.[0]?.type === "income_item") {
  //           if (!sampleIncomeStatementTemplate[key?.toLowerCase()]) {
  //             sampleIncomeStatementTemplate[key?.toLowerCase()] = {
  //               id: key?.toLowerCase(),
  //               name: key,
  //               currentPeriod: currentPeriod,
  //               previousPeriod: previousPeriod,
  //               isUnderline: false,
  //             };
  //           } else {
  //             sampleIncomeStatementTemplate[key?.toLowerCase()].currentPeriod =
  //               currentPeriod;
  //             sampleIncomeStatementTemplate[key?.toLowerCase()].previousPeriod =
  //               previousPeriod;
  //           }
  //         } else if (itemList?.[0]?.type === "balance_item") {
  //           console.log("key Value", key);
  //           if (key === "Capital and reserves") {
  //             sampleBalanceSheetTemplate["equity"].currentPeriod =
  //               currentPeriod;
  //             sampleBalanceSheetTemplate["equity"].name =
  //               "Capital and reserves";
  //             sampleBalanceSheetTemplate["equity"].previousPeriod =
  //               previousPeriod;
  //             sampleBalanceSheetTemplate["equity"].children = itemList;
  //           } else {
  //             sampleBalanceSheetTemplate[key?.toLowerCase()].currentPeriod =
  //               currentPeriod;
  //             sampleBalanceSheetTemplate[key?.toLowerCase()].previousPeriod =
  //               previousPeriod;
  //             sampleBalanceSheetTemplate[key?.toLowerCase()].children =
  //               itemList;
  //           }
  //         }

  //         // if (itemList?.[0]?.type === type) {
  //         //   previewData[key] = itemList;
  //         // }
  //       });
  //       if (type === "income_item") {
  //         let currentPeriod = 0;
  //         let previousPeriod = 0;
  //         Object.keys(sampleIncomeStatementTemplate)?.forEach(
  //           (sampleIncomekey) => {
  //             if (
  //               sampleIncomekey === "gross profit" ||
  //               sampleIncomekey === "profit (loss) before tax" ||
  //               sampleIncomekey === "profit (loss) for the year"
  //             ) {
  //               sampleIncomeStatementTemplate[sampleIncomekey].currentPeriod =
  //                 currentPeriod;
  //               sampleIncomeStatementTemplate[sampleIncomekey].previousPeriod =
  //                 previousPeriod;
  //             } else {
  //               currentPeriod +=
  //                 sampleIncomeStatementTemplate[sampleIncomekey]?.currentPeriod;
  //               previousPeriod +=
  //                 sampleIncomeStatementTemplate[sampleIncomekey]
  //                   ?.previousPeriod;
  //             }
  //           }
  //         );
  //         setPreviewData({ ...sampleIncomeStatementTemplate });
  //       } else if (type === "balance_item") {
  //         Object.keys(sampleBalanceSheetTemplate)?.forEach(
  //           (sampleIncomekey) => {
  //             if (sampleIncomekey === "net current assets") {
  //               sampleBalanceSheetTemplate[sampleIncomekey].currentPeriod =
  //                 sampleBalanceSheetTemplate["current assets"].currentPeriod -
  //                 sampleBalanceSheetTemplate["current liabilities"]
  //                   .currentPeriod;
  //               sampleBalanceSheetTemplate[sampleIncomekey].previousPeriod =
  //                 sampleBalanceSheetTemplate["current assets"].previousPeriod -
  //                 sampleBalanceSheetTemplate["current liabilities"]
  //                   .previousPeriod;
  //             } else if (
  //               sampleIncomekey === "total assets less current liabilities"
  //             ) {
  //               sampleBalanceSheetTemplate[sampleIncomekey].currentPeriod =
  //                 sampleBalanceSheetTemplate["non-current assets"]
  //                   .currentPeriod +
  //                 sampleBalanceSheetTemplate["current assets"].currentPeriod -
  //                 sampleBalanceSheetTemplate["current liabilities"]
  //                   .currentPeriod;
  //               sampleBalanceSheetTemplate[sampleIncomekey].previousPeriod =
  //                 sampleBalanceSheetTemplate["non-current assets"]
  //                   .previousPeriod +
  //                 sampleBalanceSheetTemplate["current assets"].previousPeriod -
  //                 sampleBalanceSheetTemplate["current liabilities"]
  //                   .previousPeriod;
  //             } else if (
  //               sampleIncomekey === "total equity and non-current liabilities"
  //             ) {
  //               sampleBalanceSheetTemplate[sampleIncomekey].currentPeriod =
  //                 sampleBalanceSheetTemplate["equity"].currentPeriod +
  //                 sampleBalanceSheetTemplate["non-current liabilities"]
  //                   .currentPeriod;
  //               sampleBalanceSheetTemplate[sampleIncomekey].previousPeriod =
  //                 sampleBalanceSheetTemplate["equity"].previousPeriod +
  //                 sampleBalanceSheetTemplate["non-current liabilities"]
  //                   .previousPeriod;
  //             }
  //           }
  //         );
  //         console.log("Balance sheet12", sampleBalanceSheetTemplate);
  //         setPreviewData({ ...sampleBalanceSheetTemplate });
  //       }
  //     }
  //   );
  // }, [type, isMakeAdjustmentDialogOpened]);

  useEffect(() => {
    getReportAccountSummaryHook(
      {
        reportId: query?.reportId,
      },
      (response) => {
        console.log("Report Account Summary", response);
        // const data = {};
        let previewList = [];
        Object.keys(response).map((key) => {
          const itemList = response[key];
          if (itemList?.[0]?.type === type) {
            const item = {
              id: itemList[0]?.parentId,
              name: itemList[0]?.category,
              visibleIndex: itemList?.[0]?.parentIndex,
              children: [],
              previousPeriodSubTotal: 0,
              currentPeriodSubTotal: 0,
              type: itemList[0]?.type,
            };
            // const previousPeriodSubTotal = 0;
            // const currentPeriodSubTotal = 0;
            let childrenList = [];
            itemList?.forEach((children) => {
              if (children?.previousPeriod) {
                item.previousPeriodSubTotal += Number(children?.previousPeriod);
              }
              if (children?.currentPeriod) {
                item.currentPeriodSubTotal += Number(children?.currentPeriod);
              }
              if (
                Number(children?.currentPeriod) !== 0 ||
                Number(children?.previousPeriod) !== 0
              ) {
                childrenList.push({
                  id: children.itemId,
                  name: children?.item,
                  type: children?.type,
                  visibleIndex: children?.childIndex,
                  parentId: children?.parentId,
                  previousPeriod: children?.previousPeriod,
                  currentPeriod: children?.currentPeriod,
                });
              }
            });
            childrenList = orderBy(childrenList, ["visibleIndex"], ["asc"]);
            item.children = [...childrenList];
            if (item?.children?.length) {
              previewList.push(item);
            }

            // previewData[key] = itemList;
            // setPreviewData(itemList);
          }
        });
        previewList = orderBy(previewList, ["visibleIndex"], ["asc"]);
        console.log("Preview List**", previewList);
        setPreviewData([...previewList]);
      }
    );
  }, [type, isMakeAdjustmentDialogOpened, rearrangeModal]);
  console.log("preview data 12", previewData);

  useEffect(() => {
    getReportByIdHook(query?.reportId, (response) => {
      console.log("Report By id", response);
      if (response?.fiscalYear?.name) {
        const yearList = response?.fiscalYear?.name?.split("/");
        setCurrentYear(yearList[1]);

        setPreviousYear(yearList[0]);
      }
    });
  }, [query?.reportId]);

  const openMakeAdjustmentDialog = (category) => {
    setSelectedCategory(category);
    setIsMakeAdjustmentDialogOpened(true);
  };
  console.log("typeinfo", type);
  const closeMakeAdjustmentDialog = () => {
    setSelectedCategory("");
    setIsMakeAdjustmentDialogOpened(false);
  };
  const closeRearrangeModal = () => {
    rearrangeList = [{}];
    setRearrangeModal(false);
  };
  const submitRearrangeModal = () => {
    rearrangeList = [{}];
    console.log("Rearrange Modal Clicked");
    closeRearrangeModal();
  };

  console.log("previewData", previewData);

  const updateCategory = (list) => {
    list?.forEach((item) => {
      updateCategoryHook(
        {
          id: item?.id,
          visibleIndex: item?.visibleIndex,
          parent: item?.parent,
        },
        (response) => {
          console.log("Category Updated Successfully!", response);
        }
      );
    });
  };

  const onDragEnd = (e) => {
    console.log("On Drag End", e, e?.source?.droppableId);
    let sourceIndex = e?.source?.index;
    let destinationIndex = e?.destination?.index;
    if (e?.source?.droppableId === "droppable") {
      if (destinationIndex !== sourceIndex) {
        let copyList = [...previewData];
        if (sourceIndex > destinationIndex) {
          copyList.splice(destinationIndex, 0, previewData[sourceIndex]);
          copyList.splice(sourceIndex + 1, 1);
        } else if (sourceIndex < destinationIndex) {
          copyList.splice(destinationIndex + 1, 0, previewData[sourceIndex]);
          copyList.splice(sourceIndex, 1);
        }
        const list = [];
        copyList?.forEach((item, index) => {
          list.push({
            ...item,
            visibleIndex: index + 1,
          });
          if (item?.children?.length) {
            item?.children?.forEach((children, childIndex) => {
              list.push({
                ...children,
                visibleIndex: childIndex + 1,
              });
            });
          }
        });
        console.log("Updated Preview List", copyList, list);
        updateCategory(list);
        setPreviewData([...copyList]);
      }
    } else {
      if (
        destinationIndex !== sourceIndex ||
        e?.source?.droppableId !== e?.destination?.droppableId
      ) {
        let sourceParentItem;
        let destinationParentItem;
        previewData.map((previewCategory) => {
          const childItem = previewCategory?.children?.find(
            (data) => data?.id === e.draggableId
          );
          console.log("rearrangeData12", childItem, previewData, e.draggableId);
          const findDestinationParentItem = previewData?.find(
            (p) => p.id === e?.destination?.droppableId
          );
          if (findDestinationParentItem) {
            destinationParentItem = findDestinationParentItem;
          }
          const findSourceParentItem = previewData?.find(
            (p) => p.id === e?.source?.droppableId
          );
          if (findSourceParentItem) {
            sourceParentItem = findSourceParentItem;
          }
          // console.log("parentItem12", parentItem);
          // if (previewCategory?.id === item?.id) {
          //   previewCategory.children = copyList;
          // }
        });

        const copyList = [...destinationParentItem.children];
        const sourceCopyList = [...sourceParentItem.children];
        if (e?.destination?.droppableId === e?.source?.droppableId) {
          if (sourceIndex > destinationIndex) {
            copyList.splice(
              destinationIndex,
              0,
              destinationParentItem.children[sourceIndex]
            );
            copyList.splice(sourceIndex + 1, 1);
          } else if (sourceIndex < destinationIndex) {
            copyList.splice(
              destinationIndex + 1,
              0,
              destinationParentItem.children[sourceIndex]
            );
            copyList.splice(sourceIndex, 1);
          }
        } else {
          copyList.splice(
            destinationIndex,
            0,
            sourceParentItem.children[sourceIndex]
          );
          sourceCopyList.splice(sourceIndex, 1);
        }

        console.log(
          "Source Parent Item",
          sourceParentItem,
          destinationParentItem,
          [...copyList],
          [...sourceCopyList]
        );

        previewData.map((previewCategory) => {
          if (previewCategory?.id === destinationParentItem?.id) {
            // let finalChildList = [];
            previewCategory.previousPeriodSubTotal = 0;
            previewCategory.currentPeriodSubTotal = 0;
            copyList?.forEach((children) => {
              if (children?.previousPeriod) {
                previewCategory.previousPeriodSubTotal += Number(
                  children?.previousPeriod
                );
              }
              if (children?.currentPeriod) {
                previewCategory.currentPeriodSubTotal += Number(
                  children?.currentPeriod
                );
              }
            });
            previewCategory.children = copyList;
          }
          if (e?.destination?.droppableId !== e?.source?.droppableId) {
            if (previewCategory?.id === sourceParentItem?.id) {
              previewCategory.previousPeriodSubTotal = 0;
              previewCategory.currentPeriodSubTotal = 0;
              sourceCopyList?.forEach((children) => {
                if (children?.previousPeriod) {
                  previewCategory.previousPeriodSubTotal += Number(
                    children?.previousPeriod
                  );
                }
                if (children?.currentPeriod) {
                  previewCategory.currentPeriodSubTotal += Number(
                    children?.currentPeriod
                  );
                }
              });
              previewCategory.children = sourceCopyList;
            }
          }
        });
        const list = [];
        previewData?.forEach((item, index) => {
          list.push({
            ...item,
            visibleIndex: index + 1,
          });
          if (item?.children?.length) {
            item?.children?.forEach((children, childIndex) => {
              list.push({
                ...children,
                visibleIndex: childIndex + 1,
                parent: {
                  id: item?.id,
                },
              });
            });
          }
        });
        console.log("Updated Preview List 12", [...previewData], [...list]);
        updateCategory([...list]);

        setPreviewData([...previewData]);
      }
    }
  };

  return (
    <section className={"right-comp2"}>
      <header className={"section-header"}>
        {!(type === "equation_item") && (
          <div
            className="preview-sheet-back-container"
            onClick={() => {
              setIsSheetPreviewEnabled(false);
            }}
          >
            <LeftOutlined />
            <span style={{ marginLeft: "11.5px" }}>Back to Mapping</span>
          </div>
        )}
        <div style={{ display: "flex", rowGap: "10px" }}>
          <div className={"header-right"}>
            <div className="preview-sheet-title-container">{title}</div>
          </div>
          {/* rearrange btn */}
          <ComponentButton
            title="Rearrange"
            onClick={() => {
              rearrangeList = [{}];
              setRearrangeModal(true);
            }}
          ></ComponentButton>
        </div>
      </header>
      <main className={"section-body"}>
        <div className="preview-sheet-data-table-header">
          <div className="preview-sheet-header-title"></div>
          <div className="preview-sheet-data-period preview-sheet-header-period">
            {currentYear}
          </div>
          {reportPeriodObj["previous"] && (
            <div className="preview-sheet-data-period preview-sheet-header-period">
              {previousYear}
            </div>
          )}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId="droppable"
            direction="vertical"
            ignoreContainerClipping={true}
            type="items"
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="dropp-able-box"
              >
                {previewData.map((parentItem, index) => {
                  const itemList = parentItem.children;
                  return (
                    <>
                      <Draggable
                        key={parentItem.id}
                        draggableId={parentItem.id}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            item={JSON.stringify(parentItem)}
                          >
                            <div className="preview-sheet-data-item-container">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  paddingRight: "10px",
                                }}
                              >
                                <DragIcon />
                              </div>
                              <div className="preview-sheet-data-header">
                                {parentItem?.name}
                              </div>
                            </div>
                            <Droppable
                              droppableId={parentItem?.id}
                              direction="vertical"
                              ignoreContainerClipping={true}
                              type="subItems"
                            >
                              {(provided, snapshot) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  className="dropp-able-box"
                                >
                                  {itemList?.length !== 0 &&
                                    itemList.map((item, index) => {
                                      return (
                                        <Draggable
                                          key={item.id}
                                          draggableId={item.id}
                                          index={index}
                                        >
                                          {(provided, snapshot) => (
                                            <div
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              item={JSON.stringify(item)}
                                            >
                                              <div className="preview-sheet-data-item-container">
                                                <div
                                                  style={{
                                                    paddingRight: "10px",
                                                    display: "flex",
                                                    alignItems: "center",
                                                  }}
                                                >
                                                  <DragIcon />
                                                </div>
                                                <div className="preview-sheet-data-title">
                                                  {item?.name}
                                                </div>
                                                <div className="preview-sheet-data-period">
                                                  <Tooltip
                                                    title={
                                                      <div
                                                        onClick={() => {
                                                          openMakeAdjustmentDialog(
                                                            item?.name
                                                          );
                                                        }}
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        Make Adjustment
                                                      </div>
                                                    }
                                                  >
                                                    <span>
                                                      {" "}
                                                      {numberWithCommas(
                                                        item?.currentPeriod
                                                      )}
                                                    </span>
                                                  </Tooltip>
                                                </div>
                                                {reportPeriodObj[
                                                  "previous"
                                                ] && (
                                                  <div className="preview-sheet-data-period">
                                                    <span>
                                                      {" "}
                                                      {numberWithCommas(
                                                        item?.previousPeriod
                                                      )}
                                                    </span>
                                                  </div>
                                                )}
                                              </div>
                                            </div>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                </div>
                              )}
                            </Droppable>

                            {(parentItem?.previousPeriodSubTotal ||
                              parentItem?.currentPeriodSubTotal) && (
                              <div className="preview-sheet-data-item-container">
                                <div
                                  className="preview-sheet-data-title"
                                  style={{ marginLeft: "19px" }}
                                ></div>
                                <div
                                  className="preview-sheet-data-period"
                                  style={{ borderTop: "1px solid" }}
                                >
                                  <span>
                                    {" "}
                                    {numberWithCommas(
                                      parentItem?.currentPeriodSubTotal
                                    )}
                                  </span>
                                </div>
                                {reportPeriodObj["previous"] && (
                                  <div
                                    className="preview-sheet-data-period"
                                    style={{ borderTop: "1px solid" }}
                                  >
                                    <span>
                                      {" "}
                                      {numberWithCommas(
                                        parentItem?.previousPeriodSubTotal
                                      )}
                                    </span>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        )}
                      </Draggable>
                    </>
                  );
                })}
              </div>
            )}
          </Droppable>
        </DragDropContext>

        {/* {type === "income_item" &&
          Object.keys(previewData).map((key, index) => {
            const item = previewData[key];
            return (
              <>
                <div className="preview-sheet-data-item-container">
                  <div
                    className="preview-sheet-data-title"
                    style={{ fontWeight: item?.isBold ? "bold" : "unset" }}
                  >
                    {item?.name}
                  </div>
                  <div
                    className="preview-sheet-data-period"
                    style={{
                      borderBottom: "none",
                    }}
                  >
                    {item?.currentPeriod !== 0 && (
                      <span> {numberWithCommas(item?.currentPeriod)}</span>
                    )}
                    {!item?.currentPeriod && <span> -</span>}
                  </div>
                  {reportPeriodObj["previous"] && (
                    <div
                      className="preview-sheet-data-period"
                      style={{
                        borderBottom: "none",
                      }}
                    >
                      {item?.previousPeriod !== 0 && (
                        <span> {numberWithCommas(item?.previousPeriod)}</span>
                      )}
                      {!item?.previousPeriod && <span>-</span>}
                    </div>
                  )}
                </div>
              </>
            );
          })} */}

        {/* {type === "balance_item" &&
          Object.keys(previewData).map((key, index) => {
            const itemList = previewData[key]?.children;
            const item = previewData[key];
            return (
              <>
                {item?.currentPeriod === 0 || (
                  <>
                    <div className="preview-sheet-data-item-container">
                      <div className="preview-sheet-data-header">
                        {item?.name}
                      </div>
                      {itemList?.length === 0 && (
                        <>
                          <div
                            className="preview-sheet-data-period"
                            style={{
                              borderBottom: "1px solid",
                            }}
                          >
                            {item?.currentPeriod !== 0 && (
                              <span>
                                {" "}
                                {numberWithCommas(item?.currentPeriod)}
                              </span>
                            )}
                            {!item?.currentPeriod && <span> -</span>}
                          </div>
                          {reportPeriodObj["previous"] && (
                            <div
                              className="preview-sheet-data-period"
                              style={{
                                borderBottom: "1px solid",
                              }}
                            >
                              {item?.previousPeriod !== 0 && (
                                <span>
                                  {" "}
                                  {numberWithCommas(item?.previousPeriod)}
                                </span>
                              )}
                              {!item?.previousPeriod && <span>-</span>}
                            </div>
                          )}
                        </>
                      )}
                    </div>
                    {itemList.map((item, index) => {
                      return (
                        <>
                          <div className="preview-sheet-data-item-container">
                            <div className="preview-sheet-data-title">
                              {item?.item}
                            </div>
                            <div
                              className="preview-sheet-data-period"
                              style={{
                                borderBottom:
                                  index === itemList?.length - 1
                                    ? "1px solid"
                                    : "none",
                              }}
                            >
                         
                              {item?.currentPeriod !== 0 && (
                                <span>
                                  {" "}
                                  {numberWithCommas(item?.currentPeriod)}
                                </span>
                              )}
                              {!item?.currentPeriod && <span> -</span>}
                     
                            </div>
                            {reportPeriodObj["previous"] && (
                              <div
                                className="preview-sheet-data-period"
                                style={{
                                  borderBottom:
                                    index === itemList?.length - 1
                                      ? "1px solid"
                                      : "none",
                                }}
                              >
                                {item?.previousPeriod !== 0 && (
                                  <span>
                                    {" "}
                                    {numberWithCommas(item?.previousPeriod)}
                                  </span>
                                )}
                                {!item?.previousPeriod && <span>-</span>}
                              </div>
                            )}
                          </div>
                        </>
                      );
                    })}
                    {itemList?.length !== 0 && (
                      <div className="preview-sheet-data-item-container">
                        <div className="preview-sheet-data-title"></div>
                        <div
                          className="preview-sheet-data-period"
                          style={{
                            borderBottom: "1px solid",
                          }}
                        >
                          {item?.currentPeriod !== 0 && (
                            <span>
                              {" "}
                              {numberWithCommas(item?.currentPeriod)}
                            </span>
                          )}
                          {!item?.currentPeriod && <span>-</span>}
                        
                        </div>
                        {reportPeriodObj["previous"] && (
                          <div
                            className="preview-sheet-data-period"
                            style={{
                              borderBottom: "1px solid",
                            }}
                          >
                            {item?.previousPeriod !== 0 && (
                              <span>
                                {" "}
                                {numberWithCommas(item?.previousPeriod)}
                              </span>
                            )}
                            {!item?.previousPeriod && <span>-</span>}
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </>
            );
          })} */}
      </main>
      {isMakeAdjustmentDialogOpened && (
        <MakeAdjustmentModal
          open={isMakeAdjustmentDialogOpened}
          handleClose={closeMakeAdjustmentDialog}
          type={type}
          categoryName={selectedCategory}
        />
      )}
      {rearrangeModal && (
        <RearrangeModal
          open={rearrangeModal}
          handleClose={closeRearrangeModal}
          handleOk={submitRearrangeModal}
          rearrangeData={previewData}
          type={type}
          rearrangeList={rearrangeList}
          setPreviewData={setPreviewData}
          onDragEndFn={onDragEnd}
        />
      )}
    </section>
  );
};

export default PreviewSheet;
