import React, { useRef } from "react";
import { Carousel } from "antd";
import "./WhatNew.less";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const WhatsNew = () => {
  const ref = useRef();
  return (
    <div className="what-card-slider">
      <div className="slider-btns">
        <LeftOutlined
          style={{ cursor: "pointer" }}
          onClick={() => {
            ref.current.prev();
          }}
        />
        <RightOutlined
          style={{ cursor: "pointer" }}
          onClick={() => {
            ref.current.next();
          }}
        />
      </div>
      <div className="gradient-effect">
        <span></span>
      </div>
      <Carousel
        dots={false}
        draggable
        ref={ref}
        slidesToShow={3}
        slidesToScroll={1}
        autoplay
        easing
        pauseOnHover
        autoplaySpeed={3000}
      >
        <div className="what-cards-design">
          <div className="what-date">
            <span></span>
            <h6>June 16, 2023</h6>
          </div>
          <p>
            <b style={{ marginBottom: "5px" }}>
              Embed Equations Directly into Your Reports!
            </b>
            <br />
            Generate equations effortlessly within your report, eliminating the
            hassle of switching between different software or tools.
          </p>
        </div>
        <div className="what-cards-design">
          <div className="what-date">
            <span></span>
            <h6>June 16, 2023</h6>
          </div>
          <p>
            <b style={{ marginBottom: "5px" }}>
              {" "}
              Advanced Report Generation and Cloning Capability!
            </b>
            <br />
            Our cutting-edge report generation feature allows you to instantly
            create reports for the upcoming year with just a few clicks.{" "}
          </p>
        </div>
        <div className="what-cards-design">
          <div className="what-date">
            <span></span>
            <h6>June 16, 2023</h6>
          </div>
          <p>
            <b style={{ marginBottom: "5px" }}>
              Robust Notes Template Library and Advanced Versioning System!
            </b>
            <br />
            Our platform now offers an extensive library of pre-designed notes
            templates tailored to various industries, report types, and specific
            use cases.
          </p>
        </div>
        <div className="what-cards-design">
          <div className="what-date">
            <span></span>
            <h6>June 16, 2023</h6>
          </div>
          <p>
            <b style={{ marginBottom: "5px" }}>
              {" "}
              Report Templates for Efficient Report Creation!
            </b>
            <br />
            Transform your existing reports into reusable templates by simply
            marking them as templates within our platform. Once marked, these
            reports serve as a foundation for future report creation, preserving
            their structure, formatting, and sections
          </p>
        </div>
      </Carousel>
    </div>
  );
};

export default WhatsNew;
