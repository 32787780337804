import ComponentModal from "../../atoms/ComponentModal";
import ComponentInput from "../../atoms/ComponentInput";

// import "../SaveNewTemplateModal/SaveNewTemplateModal.less";
import {
  deleteReportHook,
  getAccountDataHook,
  getReportGenerators,
  patchReportGeneratorsDataHook,
  updateReportAccountingTemplateHook,
} from "../../api-hooks/report";
import { notification } from "antd";
import { fetchComapnyWithReportsHook } from "../../api-hooks/company";
import { setCompaniesWithReports } from "../../store/company";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useRouter } from "../../routes";
import {
  createAccountTemplatesHook,
  getAccountTemplatesHook,
} from "../../api-hooks/account-templates";
import { setAccountTemplates } from "../../store/account-templates";
import { sortReportSections } from "../../helper";
import { cloneDeep } from "lodash";
import { setSelectedReport } from "../../store/report";

const RenameSectionModal = ({ open, handleClose, section }) => {
  const [name, setName] = useState(section?.data?.name);
  const dispatch = useDispatch();
  const router = useRouter();
  const { query } = router;
  const onSaveTemplate = () => {
    console.log("Section Name", section);
    if (name) {
      patchReportGeneratorsDataHook(
        {
          html: section?.data?.html,
          name: name,
          id: section?.id,
        },
        (response) => {
          const reportId = query?.reportId;
          getReportGenerators({ reportId }, (response) => {
            if (response) {
              console.log("Resport Generators", response);
              if (response?.length) {
                const selectedReportData = {
                  sections: [],
                };
                response?.forEach((item) => {
                  if (item?.type === "coverPage") {
                    // setCoverPageData(item);
                    selectedReportData.coverPage = item;
                  } else if (item?.type === "pageHeader") {
                    // setHeaderData(item);
                    selectedReportData.pageHeader = item;
                  } else if (item?.type === "pageFooter") {
                    // setFooterData(item);
                    selectedReportData.pageFooter = item;
                  } else if (item?.type === "financialPositionEndDate") {
                    selectedReportData.financialPositionEndDate = item;
                  } else if (item?.type === "profitLossDate") {
                    selectedReportData.profitLossDate = item;
                  } else if (item?.type === "changesEquityDate") {
                    selectedReportData.changesEquityDate = item;
                  } else if (item?.type === "currency") {
                    selectedReportData.currency = item;
                  } else if (item?.type === "company") {
                    selectedReportData.company = item;
                  } else {
                    selectedReportData.sections.push(item);
                  }
                });

                const sortedSections = sortReportSections(
                  selectedReportData.sections
                );

                selectedReportData.sections = sortedSections;

                console.log("Sorted Sections", sortedSections);

                dispatch(setSelectedReport(cloneDeep(selectedReportData)));
                handleClose();
              }
            }
          });
        }
      );
    }
  };
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      submitText="Save"
      title="Rename Section"
      handleOk={onSaveTemplate}
    >
      <div className={"save-reports-heading-container"}>
        <div
          className={"save-reports-heading-title"}
          style={{ marginBottom: "10px" }}
        >
          Section Name
        </div>
        <ComponentInput
          value={name}
          onChange={(value) => {
            setName(value);
          }}
        />
      </div>
    </ComponentModal>
  );
};

export default RenameSectionModal;
