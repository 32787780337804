import { createSlice } from "@reduxjs/toolkit";
// Slice
const reportSlice = createSlice({
  name: "report",
  initialState: {
    reports: [],
    firstDataSourceId: "",
    secondDataSourceId: "",
    selectedReport: {
      coverPage: {},
      pageHeader: {},
      pageFooter: {},
    },
    reportPeriod: {},
    reportTemplates: [],
  },
  reducers: {
    setReports: (state, action) => {
      state.reports = action.payload;
    },
    setFirstDataSourceId: (state, action) => {
      state.firstDataSourceId = action.payload;
    },
    setSecondDataSourceId: (state, action) => {
      state.secondDataSourceId = action.payload;
    },
    setSelectedReport: (state, action) => {
      state.selectedReport = action.payload;
    },
    setReportPeriod: (state, action) => {
      state.reportPeriod = action.payload;
    },
    setReportTemplates: (state, action) => {
      state.reportTemplates = action.payload;
    },
  },
});

export default reportSlice.reducer;
// Actions
export const {
  setReports,
  setFirstDataSourceId,
  setSecondDataSourceId,
  setSelectedReport,
  setReportPeriod,
  setReportTemplates,
} = reportSlice.actions;
