export const getReportListHook = () => {
  const data = [
    {
      id: 1,
      name: "xxxfund",
      draftList: [
        {
          id: 1,
          name: "Draft_v5",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v4",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v3",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v2",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v1",
          isDraft: true,
          isFinal: false,
        },
      ],
    },
    {
      id: 2,
      name: "X Limited",
      draftList: [
        {
          id: 1,
          name: "Draft_v5",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v4",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v3",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v2",
          isDraft: true,
          isFinal: false,
        },
        {
          id: 1,
          name: "Draft_v1",
          isDraft: true,
          isFinal: false,
        },
      ],
    },
  ];

  return data;
};

export const suggestionsList = [
  {
    id: 1,
    name: "Cash and cash equivalents",
    percentage: "85%",
    confidence: 85,
  },
  {
    id: 2,
    name: "Accounts receviable",
    percentage: "75%",
    confidence: 85,
  },
  {
    id: 3,
    name: "Other receivables and prepayments",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 4,
    name: "Inventories",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 5,
    name: "Deferred tax",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 6,
    name: "Amount due from holding company",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 7,
    name: "Amount due from minority interest",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 8,
    name: "Amount due from related companies",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 9,
    name: "Amount due from shareholders",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 10,
    name: "Amount due from officer",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 11,
    name: "Fixed assets",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 12,
    name: "Intangible assets",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 13,
    name: "Tax recoverable",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 14,
    name: "Investment",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 15,
    name: "Borrowings",
    percentage: "0%",
    confidence: 85,
  },

  {
    id: 16,
    name: "Accounts payable ",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 17,
    name: "Other payables and accurals",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 18,
    name: "Tax payable",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 19,
    name: "Amount due to related companies",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 20,
    name: "Amount due to minority interest",
    percentage: "0%",
    confidence: 85,
  },
  {
    id: 21,
    name: "Amount due to officer",
    percentage: "0%",
    confidence: 85,
  },
];

export const categorySuggestionList = [
  {
    id: 1,
    name: "Current Assets",
    percentage: "90%",
    confidence: 0,
  },
  {
    id: 2,
    name: "Non-current assets",
    percentage: "87%",
    confidence: 0,
  },
  {
    id: 3,
    name: "Non-current liabilities",
    percentage: "85%",
    confidence: 0,
  },
  {
    id: 4,
    name: "Current liabilities",
    percentage: "83%",
    confidence: 0,
  },
  {
    id: 5,
    name: "Equity",
    percentage: "81%",
    confidence: 0,
  },
];

export const parentCategoryObj = {
  profitandloss: "Profit/Loss",
  liabilities: "Liabilities",
  assets: "Assets",
  equity: "Equity",
};
