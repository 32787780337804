import api from "./api-intercepter";

export const fetchReports = (callback) => {
  api
    .get("/reports")
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const fetchReportTemplates = (callback) => {
  api
    .get("/reports?filter=isTemplate||eq||true")
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateIsTemplateStatus = (values, callback) => {
  const data = JSON.stringify({
    isTemplate: values?.isTemplate,
  });
  api
    .patch(`/reports/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const getReportByIdHook = (reportId, callback) => {
  api
    .get(`/reports/${reportId}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
const reportPayload = (values, isCopyReport) => {
  let data = {
    name: values?.name,
    company: {
      id: values?.companyId,
    },
    fiscalYear: {
      id: values?.financialYearId,
    },
    industry: {
      id: values?.industry,
    },
  };
  if (isCopyReport) {
    data = {
      report: {
        ...data,
        id: values?.id,
      },
    };
  }
  return data;
};
export const createReportHook = (values, isCopyReport, callback) => {
  // debugger;
  const data = JSON.stringify(reportPayload(values, isCopyReport));
  const url = isCopyReport ? "/reports/copy-report" : "/reports";
  api
    .post(url, data)
    .then(function (response) {
      const reportId = response?.data?.data?.id;
      const companyName = response?.data?.data?.company?.name;
      let currency = "HK$";
      if (
        companyName === "Atheneum Partners Asia Limited" ||
        companyName === "ABC 有限公司"
      ) {
        currency = "RMB";
      }
      if (companyName === "R Limited") {
        currency = "HK$";
      }
      if (companyName === "Xxxfund PTE. Ltd") {
        currency = "US$";
      }
      let coverPageValue = `<h1>${values?.companyName}</h1><div style="padding-top:30px"><p><strong>Directors’ Report and Financial Statements</strong><br>for the year ended <span data-type="equation" class="equationNode" data-id="current financial date" data-label="31 March 2022" data-value="{{current|financialPositionEndDate}}" id="{{current|financialPositionEndDate}}">31 March 2022</span></p></div>`;
      let pageHeaderValue = `<div style="text-align: right;font-size:10px"><p><em>New Group</em><br><em>Year ended </em><span data-type="equation" class="equationNode" data-id="current financial date" data-label="31 March 2022" data-value="{{current|financialPositionEndDate}}"  id="{{current|financialPositionEndDate}}">31 March 2022</span> </p></div>`;
      if (companyName === "ABC 有限公司") {
        coverPageValue = `<h2>{{company}}</h2><div style="padding-top:30px"><br />{{current|financialPositionEndDate}}日止年度财务报表</div>`;
        pageHeaderValue = `<div style="text-align: right;font-size:10px"><i>${values?.companyName}</i><br /><i>结束年份{{current|financialPositionEndDate}}</i></div>`;
      }
      if (!isCopyReport) {
        bulkCreateReportGeneratorsHook(
          {
            bulk: [
              {
                report: {
                  id: reportId,
                },
                type: "coverPage",
                data: {
                  html: coverPageValue,
                },
              },
              {
                report: {
                  id: reportId,
                },
                type: "pageHeader",
                data: {
                  html: pageHeaderValue,
                },
              },
              {
                report: {
                  id: reportId,
                },
                type: "financialPositionEndDate",
                data: {
                  current: "31/03/2022",
                  previous: "31/03/2021",
                },
              },
              {
                report: {
                  id: reportId,
                },
                type: "currency",
                data: {
                  text: currency,
                },
              },
              {
                report: {
                  id: reportId,
                },
                type: "pageFooter",
                data: {
                  html: "<p></p>",
                },
              },
              {
                report: {
                  id: reportId,
                },
                type: "company",
                data: {
                  text: companyName,
                },
              },
            ],
          },
          (response) => {
            console.log("Bulk Creation Done", response);
          }
        );
      }

      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const editReportHook = (values, callback) => {
  const reportId = values?.id;
  delete values?.id;
  const data = JSON.stringify(reportPayload(values));
  api
    .patch(`/reports/${reportId}`, data)
    .then((response) => {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const previewDataSourceHook = (values, callback) => {
  api.post("/excels/preview", values).then((response) => {
    console.log("uploadDataSource", response);
    callback(response?.data?.data);
  });
};

export const uploadDataSource = (values, callback) => {
  api.post("/data-sources/upload", values).then((response) => {
    callback(response?.data?.data);
  });
};

export const getAccountDataHook = (values, callback) => {
  api
    .get(`/accounts?filter=report.id||eq||${values?.reportId}`)
    .then((response) => {
      console.log("Response", response);
      callback(response?.data?.data);
    });
};

export const getReportAccountList = (values, callback) => {
  api.get(`/accounts/report/${values?.reportId}`).then((response) => {
    console.log("Report Account List", response);
    callback(response?.data?.data);
  });
};

export const getAccountByType = (values, callback) => {
  api
    .get(
      `/accounts?filter=report.id||eq||${values?.reportId}&filter=category.type||eq||${values?.type}`
    )
    .then((response) => {
      console.log("Response", response);
      callback(response?.data?.data);
    });
};

export const getReportGenerators = (values, callback) => {
  api
    .get(`/report-generators?filter=report.id||eq||${values?.reportId}`)
    .then((response) => {
      console.log("Response", response);
      callback(response?.data?.data);
    });
};

export const createReportGeneratorsHook = (values, callback) => {
  let data = JSON.stringify({
    report: {
      id: values?.reportId,
    },
    // name: "Page Header",
    type: values?.type,
    data: values?.data,
  });
  if (values?.visibleIndex) {
    data = JSON.stringify({
      report: {
        id: values?.reportId,
      },
      // name: "Page Header",
      type: values?.type,
      data: values?.data,
      visibleIndex: values?.visibleIndex,
    });
  }
  api
    .post("/report-generators", data)
    .then(function (response) {
      console.log("Response", response);
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateReportGeneratorsHook = (values, callback) => {
  const data = JSON.stringify({
    report: {
      id: values?.reportId,
    },
    // name: "Page Header",
    type: values?.type,
    data: {
      html: values?.data,
    },
  });
  api
    .put(`/report-generators/${values?.id}`, data)
    .then(function (response) {
      console.log("Response", response);
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getPDFPreviewHook = (values, callback) => {
  api
    .get(`/report-generators/preview/${values?.reportId}?mode=refresh`, {
      responseType: "blob",
    })
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const convertWordHook = (values, callback) => {
  api
    .get(`/report-generators/preview/${values?.reportId}?mode=docx`, {
      responseType: "blob",
    })
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const createOrUpdateFinancialPositionDateHook = (values, callback) => {
  if (values.id) {
    const data = JSON.stringify({
      type: "financialPositionEndDate",
      data: {
        current: values?.current,
        previous: values?.previous,
      },
    });
    api
      .patch(`/report-generators/${values.id}`, data)
      .then(function (response) {
        console.log("Response", response);
        callback(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    createReportGeneratorsHook(
      {
        reportId: values.reportId,
        type: "financialPositionEndDate",
        data: {
          current: values?.current,
          previous: values?.previous,
        },
      },
      (response) => {
        if (response) {
          console.log("Resport Generators", response);
          callback(response);
        }
      }
    );
  }
};

export const createOrUpdateProfitAndLossDateHook = (values, callback) => {
  if (values.id) {
    const data = JSON.stringify({
      type: "profitLossDate",
      data: {
        currentPeriod: {
          startDate: values?.currentPeriodStartDate,
          endDate: values?.currentPeriodEndDate,
        },
        previousPeriod: {
          startDate: values?.previousPeriodStartDate,
          endDate: values?.previousPeriodEndDate,
        },
      },
    });
    api
      .patch(`/report-generators/${values.id}`, data)
      .then(function (response) {
        console.log("Response", response);
        callback(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    createReportGeneratorsHook(
      {
        reportId: values.reportId,
        type: "profitLossDate",
        data: {
          currentPeriod: {
            startDate: values?.currentPeriodStartDate,
            endDate: values?.currentPeriodEndDate,
          },
          previousPeriod: {
            startDate: values?.previousPeriodStartDate,
            endDate: values?.previousPeriodEndDate,
          },
        },
      },
      (response) => {
        if (response) {
          console.log("Resport Generators", response);
        }
      }
    );
  }
};

export const createOrUpdateChangesEquityDateHook = (values, callback) => {
  if (values.id) {
    const data = JSON.stringify({
      type: "changesEquityDate",
      data: {
        startDate: values?.startDate,
        endDate: values?.endDate,
      },
    });
    api
      .patch(`/report-generators/${values.id}`, data)
      .then(function (response) {
        console.log("Response", response);
        callback(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    createReportGeneratorsHook(
      {
        reportId: values.reportId,
        type: "changesEquityDate",
        data: {
          startDate: values?.startDate,
          endDate: values?.endDate,
        },
      },
      (response) => {
        if (response) {
          console.log("Resport Generators", response);
        }
      }
    );
  }
};

export const createOrUpdateCurrencyHook = (values, callback) => {
  if (values.id) {
    const data = JSON.stringify({
      type: "currency",
      data: {
        text: values?.currency,
      },
    });
    api
      .patch(`/report-generators/${values.id}`, data)
      .then(function (response) {
        console.log("Response", response);
        callback(response?.data?.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  } else {
    createReportGeneratorsHook(
      {
        reportId: values.reportId,
        type: "currency",
        data: {
          text: values?.currency,
        },
      },
      (response) => {
        if (response) {
          console.log("Resport Generators", response);
        }
      }
    );
  }
};

export const bulkCreateReportGeneratorsHook = (values, callback) => {
  const data = JSON.stringify({
    bulk: values?.bulk,
  });
  api
    .post("/report-generators/bulk", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteReportSectionHook = (values, callback) => {
  api
    .delete(`report-generators/${values.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateReportSectionsHook = (values, callback) => {
  const data = JSON.stringify({
    type: values?.type,
    data: {
      html: values?.html,
      name: values?.name,
      status: values?.status || "IN_PROGRESS",
    },
  });
  api
    .patch(`/report-generators/${values?.id}`, data)
    .then(function (response) {
      console.log("Response", response);
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const bulkPatchAndCreateReportGeneratorsHook = (list, callback) => {
  if (list?.length) {
    list.forEach((item) => {
      if (item.id) {
        const data = JSON.stringify({
          visibleIndex: item?.visibleIndex,
        });
        api
          .patch(`/report-generators/${item?.id}`, data)
          .then(function (response) {
            console.log("Response", response);
            // callback(response?.data?.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      } else {
        createReportGeneratorsHook(
          {
            reportId: item?.report?.id,
            type: item?.type,
            data: item?.data,
            visibleIndex: item?.visibleIndex,
          },
          (response) => {
            if (response) {
              console.log("Resport Generators", response);
            }
          }
        );
      }
    });
  }
};

export const bulkPatchReportGeneratorsDataHook = (list, callback) => {
  if (list?.length) {
    list.forEach((item, index) => {
      if (item.id) {
        const data = JSON.stringify({
          data: {
            html: item?.html,
            name: item?.name,
          },
        });
        api
          .patch(`/report-generators/${item?.id}`, data)
          .then(function (response) {
            console.log("Response", response);
            if (index === list?.length - 1) {
              callback();
            }
            // callback(response?.data?.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    });
  }
};

export const patchReportGeneratorsDataHook = (item, callback) => {
  const data = JSON.stringify({
    data: {
      html: item?.html,
      name: item?.name,
    },
  });
  api
    .patch(`/report-generators/${item?.id}`, data)
    .then(function (response) {
      console.log("Response", response);

      callback();

      // callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getReportGeneratorPreviewById = (values, callback) => {
  api
    .get(`/report-generators/${values.id}?mode=preview`)
    .then(function (response) {
      callback(response?.data?.data);
    });
};

export const getReportGeneratorEditById = (values, callback) => {
  api
    .get(`/report-generators/${values.id}?mode=edit`)
    .then(function (response) {
      callback(response?.data?.data);
    });
};

export const duplicateReportHook = (values, callback) => {
  const data = JSON.stringify({
    report: {
      id: values?.reportId,
      name: values?.name,
    },
  });
  api.post("/reports/duplicate", data).then((response) => {
    callback(response?.data?.data);
  });
};

export const deleteReportHook = (values, callback) => {
  api.delete(`/reports/${values?.reportId}`).then((response) => {
    callback(response?.data?.data);
  });
};

export const renameReportHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
  });
  api.patch(`/reports/${values?.reportId}`, data).then((response) => {
    callback(response?.data?.data);
  });
};

export const getReportGeneratorHtmlHook = (values, callback) => {
  api
    .get(`/report-generators/html/${values?.reportId}`)
    .then(function (response) {
      console.log("PDF Response", response);
      callback(response?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateReportGeneratorHtmlHook = (values, callback) => {
  api
    .put(`/report-generators/html/${values?.reportId}`, {
      content: values?.content,
    })
    .then(function (response) {
      console.log("PDF Response", response);
      callback(response?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateReportAccountingTemplateHook = (values, callback) => {
  const data = JSON.stringify({
    selectedAccountTemplate: {
      id: values?.templateId,
    },
  });
  api
    .patch(`/reports/${values.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const deleteReportDrive = (id, callback) => {
  return api
    .delete(`/report-drive/${id}`)
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const updateReportDrive = (values, callback) => {
  const data = JSON.stringify(values);
  return api
    .patch(`/report-drive/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getReportFile = (id, callback) => {
  return api
    .get(`/report-drive/upload/${id}`, {
      responseType: "blob",
    })
    .then(function (response) {
      callback(response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const uploadReportDriveDoc = (value, callback) => {
  return api
    .post(`/report-drive/upload`, JSON.stringify(value))
    .then(function (response) {
      callback(response?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getDataSourceHook = (values, callback) => {
  api
    .get(`/data-sources?filter=report.id||eq||${values.reportId}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
    });
};
