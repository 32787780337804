import { createSlice } from "@reduxjs/toolkit";
// Slice
const industrySlice = createSlice({
  name: "industry",
  initialState: {
    industries: [],
  },
  reducers: {
    setIndustries: (state, action) => {
      state.industries = action.payload;
    },
  },
});

export default industrySlice.reducer;
// Actions
export const { setIndustries } = industrySlice.actions;
