import ComponentModal from "../../atoms/ComponentModal";
import ComponentInput from "../../atoms/ComponentInput";

import { useDispatch } from "react-redux";
import { useState } from "react";
import { useRouter } from "../../routes";

const SaveWarningModal = ({
  open,
  handleClose,
  reportId,
  setSelectedTemplateObj,
  setIsMappingChanged,
  onOk,
}) => {
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      submitText="Save"
      title="Warning"
      handleOk={onOk}
    >
      <div>Do you want to Save or Disgard the changes.</div>
    </ComponentModal>
  );
};

export default SaveWarningModal;
