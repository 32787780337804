import React, { useState, useEffect } from "react";
import { numberWithCommas } from "../../helper";
import "./RearrangeModal.less";
import { ReactComponent as DragIcon } from "../../assets/svg-icons/DragIcon.svg";
import { HolderOutlined } from "@ant-design/icons";
const RearrangeExpandableItem = ({ item }) => {
  return (
    <div>
      <div
        style={{
          paddingLeft: "50px",
          display: "flex",
          alignItems: "center",
          gap: "10px",
          paddingBottom: "15px",
        }}
      >
        <DragIcon />
        <div>
          {item?.name ? <div>{item?.name}</div> : <div>{item?.name}</div>}
        </div>
      </div>
      {/* <div>
        {item?.currentPeriod ? (
          <div>{numberWithCommas(item?.currentPeriod)}</div>
        ) : (
          <div>-</div>
        )}
      </div>
      <div>
        {item?.previousPeriod ? (
          <div>{numberWithCommas(item?.previousPeriod)}</div>
        ) : (
          <div>-</div>
        )}
      </div> */}
    </div>
  );
};

export default RearrangeExpandableItem;
