// import logo from "../../assets/logo.svg";
import logo from "../../assets/logo-cannex.svg"
import { Form, Input, message } from "antd";

import "./Login.less";
import ComponentInput from "../../atoms/ComponentInput";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import ComponentButton from "../../atoms/ComponentButton";
import { Link } from "react-router-dom";
import { SIGN_UP_URL } from "../../routes";
import { loginHook } from "../../api-hooks/user";
const Login = () => {
  const onFinish = (values) => {
    if (values.email && values.password) {
      loginHook(values, (response) => {
        if (response?.message) {
          message.error(response?.message);
        } else {
          console.log("Login response", response);
          if (response?.accessToken) {
            console.log("Login response", response?.accessToken);
            localStorage.setItem("authToken", response?.accessToken);

            window.location.href = "/";
          }
        }
      });
    }
  };

  const onFinishFailed = (errorInfo) => {};
  return (
    <div className="login-container">
      <div className="login-card-container">
        <img src={logo} alt="logo" />
        <div className="login-sign-in-text">SIGN IN</div>
        <div className="login-sign-in-welcome">Welcome to CanCan</div>

        <Form
          name="basic"
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <ComponentInput
              placeholder="Email"
              style={{ height: "42px", borderRadius: "8px" }}
              prefix={<MailOutlined style={{ marginRight: "12px" }} />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              placeholder="Password"
              style={{ height: "42px", borderRadius: "8px" }}
              prefix={<LockOutlined style={{ marginRight: "12px" }} />}
            />
          </Form.Item>

          <Form.Item style={{ marginBottom: "10px" }}>
            <ComponentButton
              title="SIGN IN"
              htmlType="submit"
              size="large"
              style={{ width: "100%", fontSize: "14px" }}
            />
          </Form.Item>

          <div style={{ textAlign: "center", color: "#999" }}>
            Not a user?{" "}
            <Link to={SIGN_UP_URL}>
              <span
                style={{
                  fontWeight: "bold",
                  color: "#4bb354",
                  cursor: "pointer",
                }}
              >
                SIGN UP
              </span>
            </Link>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default Login;
