import api from "./api-intercepter";

export const fetchCategoriesHook = (reportId, type, parentType, callback) => {
  const url = `/categories?filter=report.id||eq||${reportId}&join=parent&filter=type||eq||${type}&join=children&filter=parentType||eq||${parentType}`;
  api
    .get(url)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateCategoryHook = (values, callback) => {
  const url = `/categories/${values?.id}`;
  const payload = {
    visibleIndex: values?.visibleIndex,
  };
  if (values?.parent) {
    payload.parent = values?.parent;
  }
  const data = JSON.stringify(payload);
  api.patch(url, data).then((response) => {
    callback(response?.data?.data);
  });
};

export const fetchItemsHook = (reportId, type, categoryId, callback) => {
  const url = `/categories?filter=report.id||eq||${reportId}&join=parent&filter=type||eq||${type}&join=children&filter=parent.id||eq||${categoryId}`;
  api
    .get(url)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const saveCategoriesHook = (values, callback) => {
  const data = JSON.stringify({
    type: values?.type,
    visibleIndex: values?.index,
    name: values?.name,
    report: {
      id: values?.reportId,
    },
    parentType: values?.parentType,
  });

  api
    .post("/categories", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
export const updateCategoriesHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    id: values?.id,
  });

  api
    .patch(`/categories/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const deleteCategoriesHook = (values, callback) => {
  api
    .delete(`/categories/${values?.id}`)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const saveCategoryItemHook = (values, callback) => {
  const data = JSON.stringify({
    type: values?.type,
    visibleIndex: values?.index,
    name: values?.name,
    report: {
      id: values?.reportId,
    },
    parent: {
      id: values?.parentId,
    },
    parentType: values?.parentType,
  });

  api
    .post("/categories", data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};

export const updateCategoryItemHook = (values, callback) => {
  const data = JSON.stringify({
    name: values?.name,
    id: values?.id,
  });

  api
    .patch(`/categories/${values?.id}`, data)
    .then(function (response) {
      callback(response?.data?.data);
    })
    .catch(function (error) {
      console.log(error);
      callback(error);
    });
};
