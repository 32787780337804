import { Mark, mergeAttributes } from "@tiptap/core";

export const DoubleUnderline = Mark.create({
  name: "double-underline",

  addOptions() {
    return {
      HTMLAttributes: {
        style: "border-bottom:4px double;text-decoration:none",
        // style: {
        //   paddingBottom: "10px",
        //   borderBottom: "4px double",
        //   textDecoration: "none",
        // },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: "du",
      },
      {
        style: "border-bottom:4px double;text-decoration:none",
        consuming: false,
        getAttrs: (style) =>
          style.includes("double-underline")
            ? {
                // paddingBottom: "10px",
                borderBottom: "4px double",
                textDecoration: "none",
              }
            : false,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    console.log("HtmlAttributes", HTMLAttributes);
    return [
      "du",
      // {
      //   style: `padding-bottom:10px;border-bottom:4px double;text-decoration:none`,
      // },
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setDoubleUnderline:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleDoubleUnderline:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      unsetDoubleUnderline:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addKeyboardShortcuts() {
    return {
      "Mod-d": () => this.editor.commands.toggleDoubleUnderline(),
      "Mod-D": () => this.editor.commands.toggleDoubleUnderline(),
    };
  },
});
