import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { createCompanyHook, deleteCompanyHook } from "../../api-hooks/company";
import {
  createReportHook,
  editReportHook,
  getReportByIdHook,
} from "../../api-hooks/report";
import {
  createFinancialYearHook,
  deleteFinancialYearHook,
  fetchYearsHook,
} from "../../api-hooks/year";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentDropdownPopup from "../../atoms/ComponentDropdownPopup";
import ComponentInput from "../../atoms/ComponentInput";
import ComponentModal from "../../atoms/ComponentModal";
import {
  CREATE_FINANCIAL_REPORT_URL,
  FINANCIAL_REPORT_URL,
  useRouter,
} from "../../routes";
import { setCompanies } from "../../store/company";
import year, { setYears } from "../../store/year";
import { updateFinancialYearHook } from "../../api-hooks/year";
import { updateCompanyHook } from "../../api-hooks/company";
import { fetchCompaniesHook } from "../../api-hooks/company";

import "./SaveReportModal.less";
import { responsiveArray } from "antd/lib/_util/responsiveObserve";
import {
  createIndustryHook,
  deleteIndustryHook,
  fetchIndustriesHook,
  updateIndustryHook,
} from "../../api-hooks/industry";
import { setIndustries } from "../../store/industry";
import DeleteTemplateItemModal from "../DeleteTemplateItemModal";
const SaveReportModal = ({
  open,
  handleClose,
  duplicateId,
  setReportList,
  reportList,
  showReportEdit,
  updateData,
  selectedItem,
  isEdit,
  setIsByPassBlock,
  setCount,
  count,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const router = useRouter();
  const years = useSelector((state) => state.year.years);
  const companies = useSelector((state) => state.company.companies);
  const industries = useSelector((state) => state.industry.industries);
  const [name, setName] = useState("");
  const [draftVersion, setDraftVersion] = useState("");
  const [selectedYear, setSelectedYear] = useState(null);

  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [loader, setLoader] = useState(false);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [typeId, setType] = useState();
  const [reportType, setReportType] = useState("");

  const { query } = router;

  console.log("Query12", query);

  const companyDeleteWarningModal = (company) => {
    setType(company);
    setReportType("company");
    setDeleteWarningModal(true);
  };
  const closeOpenWarningModal = () => {
    setDeleteWarningModal(false);
  };
  const deleteItemCalled = () => {
    if (reportType === "company") {
      onDeleteCompany(typeId);
    } else if (reportType === "industry") {
      onDeleteIndustry(typeId);
    } else if (reportType === "year") {
      onDeleteYear(typeId);
    }
  };

  const onAddFinancialYear = (financialYear) => {
    createFinancialYearHook(financialYear, (response) => {
      const newYearList = [...years];
      newYearList.unshift(response);
      setSelectedYear(response);
      updateReportName(null, response);
      dispatch(setYears(newYearList));
    });
  };
  const yearDeleteWarningModal = (year) => {
    setType(year);
    setReportType("year");
    setDeleteWarningModal(true);
  };
  const onDeleteYear = (year) => {
    deleteFinancialYearHook(
      {
        id: year,
      },
      (response) => {
        fetchYearsHook((allYearResponse) => {
          dispatch(setYears([...allYearResponse]));
        });
      }
    );
    setDeleteWarningModal(false);
  };

  const onAddCompany = (company) => {
    createCompanyHook(company, (response) => {
      const newCompanyList = [...companies];
      newCompanyList.unshift(response);
      setSelectedCompany(response);
      updateReportName(response);
      dispatch(setCompanies(newCompanyList));
    });
  };
  const onDeleteCompany = (company) => {
    deleteCompanyHook(
      {
        id: company,
      },
      (response) => {
        fetchCompaniesHook((allCompanyResponse) => {
          dispatch(setCompanies([...allCompanyResponse]));
        });
      }
    );
    setDeleteWarningModal(false);
  };

  const onAddIndustry = (industry) => {
    createIndustryHook(industry, (response) => {
      const newIndustryList = [...industries];
      newIndustryList.unshift(response);
      setSelectedIndustry(response);
      dispatch(setIndustries(newIndustryList));
    });
  };
  const industryDeleteWarningModal = (industry) => {
    setType(industry);
    setReportType("industry");
    setDeleteWarningModal(true);
  };
  const onDeleteIndustry = (industry) => {
    deleteIndustryHook(
      {
        id: industry,
      },
      (response) => {
        fetchIndustriesHook((allYearResponse) => {
          dispatch(setIndustries([...allYearResponse]));
        });
      }
    );
    setDeleteWarningModal(false);
  };

  useEffect(() => {
    let reportName;

    if (companies?.length && !selectedCompany) {
      const companyData = selectedItem?.company ?? companies[0];
      setSelectedCompany(companyData);
      reportName = companyData.name;
    }
    if (years?.length && !selectedYear) {
      const financeYear = selectedItem?.fiscalYear?.id
        ? years.filter((item) => item?.id === selectedItem?.fiscalYear?.id)?.[0]
        : years[0];
      setSelectedYear(financeYear);
      if (reportName) {
        reportName = `${reportName} ${financeYear?.name}`;
      }
    }
    if (reportName) {
      setName(selectedItem?.name ?? reportName);
    }
  }, [years?.length, companies?.length]);

  useEffect(() => {
    if (industries?.length && !selectedIndustry) {
      const industryData = selectedItem?.industry ?? industries[0];
      setSelectedIndustry(industryData);
    }
  }, [industries?.length]);

  useEffect(() => {
    if (isEdit) {
      getReportByIdHook(query?.reportId, (response) => {
        if (response?.id) {
          setName(response?.name);
        }
      });
    }
  }, [isEdit]);

  const updateFiscalyear = (id, value, callback) => {
    // call patch function
    updateFinancialYearHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("Equation Updated created", response);
        fetchYearsHook((allYearResponse) => {
          dispatch(setYears([...allYearResponse]));

          console.log("Response Year", response);
          if (selectedYear?.id === response?.id) {
            setSelectedYear(response);
          }
          callback();
        });
      }
    );
  };

  const updateIndustry = (id, value, callback) => {
    // call patch function
    updateIndustryHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("Equation Updated created", response);
        fetchIndustriesHook((allYearResponse) => {
          dispatch(setIndustries([...allYearResponse]));

          console.log("Response Industry", response);
          if (selectedIndustry?.id === response?.id) {
            setSelectedIndustry(response);
          }
          callback();
        });
      }
    );
  };
  const updateCompany = (id, value, callback) => {
    // call patch function
    updateCompanyHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("company Updated successfully", response);
        fetchCompaniesHook((allCompanyResponse) => {
          dispatch(setCompanies([...allCompanyResponse]));

          console.log("Response company", response);
          if (selectedCompany?.id === response?.id) {
            setSelectedCompany(response);
          }
          callback();
        });
      }
    );
  };
  const updateReportName = (company, year) => {
    setTimeout(() => {
      let companyName = company?.name ? company?.name : selectedCompany?.name;
      let yearName = year?.name ? year?.name : selectedYear?.name;
      let reportName = `${companyName} ${yearName}`;

      console.log("report Name", reportName);
      setName(reportName);
    }, 300);
  };
  // const onAddFinancialYear = (financialYear) => {
  //   createFinancialYearHook(financialYear, (response) => {
  //     const newYearList = [...years];
  //     newYearList.push(response);
  //     dispatch(setYears(newYearList));
  //   });
  // };
  const onFinish = () => {
    if (setLoader) {
      setLoader(true);
    }
    if (isEdit) {
      setCount(count + 1);
      editReportHook(
        {
          name,
          companyId: selectedCompany?.id,
          financialYearId: selectedYear?.id,
          companyName: selectedCompany?.name,
          id: query?.reportId,
          industry: selectedIndustry?.id,
        },
        (response) => {
          if (setLoader) {
            setLoader(false);
          }
          // setIsByPassBlock(true);
          history.replace(
            `${CREATE_FINANCIAL_REPORT_URL}?reportId=${response.id}&step=1`
          );
          handleClose();
        }
      );
    } else {
      createReportHook(
        {
          name,
          companyId: selectedCompany?.id,
          financialYearId: selectedYear?.id,
          companyName: selectedCompany?.name,
          id: selectedItem?.id,
          industry: selectedIndustry?.id,
        },
        selectedItem?.id ? true : false,
        (response) => {
          if (setLoader) {
            setLoader(false);
          }
          history.push(
            `${CREATE_FINANCIAL_REPORT_URL}?reportId=${response.id}&step=1`
          );
        }
      );
    }
  };
  return (
    <>
      <ComponentModal
        open={open}
        handleClose={handleClose}
        backText="Back"
        submitText="Confirm"
        title="Save Report"
        handleOk={onFinish}
        loader={loader}
      >
        <div className={"save-reports-heading-container"}>
          <div className={"save-reports-heading-title"}>Industry</div>
          <ComponentDropdownPopup
            title="Select Industry"
            onSelect={(item) => {
              setSelectedIndustry(item);
            }}
            selectedItem={selectedIndustry}
            list={industries}
            addText="Add as new industry"
            onAdd={onAddIndustry}
            showReportEdit={showReportEdit}
            updateData={updateIndustry}
            onDelete={industryDeleteWarningModal}
          />
        </div>
        <div className={"save-reports-heading-container"}>
          <div className={"save-reports-heading-title"}>Company</div>
          <ComponentDropdownPopup
            title="Select Company"
            onSelect={(item) => {
              setSelectedCompany(item);
              updateReportName(item);
            }}
            selectedItem={selectedCompany}
            list={companies}
            addText="Add as new company"
            onAdd={onAddCompany}
            onDelete={companyDeleteWarningModal}
            showReportEdit={showReportEdit}
            updateData={updateCompany}
          />
        </div>

        <div>
          <div className={"save-reports-heading-container"}>
            <div className={"save-reports-heading-title"}>Financial Year</div>
            <ComponentDropdownPopup
              title="Select financial year"
              onSelect={(item) => {
                setSelectedYear(item);
                updateReportName(null, item);
              }}
              selectedItem={selectedYear}
              updateData={updateFiscalyear}
              list={years}
              onDelete={yearDeleteWarningModal}
              addText="Add as new financial year"
              onAdd={onAddFinancialYear}
              showReportEdit={showReportEdit}
            />
          </div>
        </div>

        <div className={"save-reports-heading-container"}>
          <div className={"save-reports-heading-title"}>Report Name</div>
          <ComponentInput
            value={name}
            onChange={(value) => {
              setName(value);
            }}
          />
        </div>
      </ComponentModal>
      {deleteWarningModal && (
        <DeleteTemplateItemModal
          open={deleteWarningModal}
          handleClose={closeOpenWarningModal}
          confirmDelete={deleteItemCalled}
          deleteWarningModalName={""}
        />
      )}
    </>
  );
};

export default SaveReportModal;
