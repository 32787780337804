import classNames from "classnames";
import { useEffect, useState } from "react";

import {
  PlusCircleOutlined,
  DownCircleOutlined,
  UpCircleOutlined,
  PlusOutlined,
  OrderedListOutlined,
  ArrowDownOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ReactComponent as ArrangerOrderIcon } from "../../assets/svg-icons/arrangeOrder.svg";
import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";
// import less
import "./ReportSections.less";
import AddSectionModal from "../AddSectionModal";
import ReportTemplatesModal from "../ReportTemplatesModal";
import { useSelector } from "react-redux";
import {
  bulkCreateReportGeneratorsHook,
  bulkPatchAndCreateReportGeneratorsHook,
  deleteReportSectionHook,
  getReportGeneratorPreviewById,
  getReportGenerators,
} from "../../api-hooks/report";
import { useDispatch } from "react-redux";
import { setSelectedReport } from "../../store/report";
import { sortReportSections } from "../../helper";
import { useRouter } from "../../routes";
import { cloneDeep } from "lodash";
import ReportSectionMenu from "./ReportSectionMenu";
import RenameSectionModal from "../RenameSectionModal";

const ReportSections = ({
  collapsed,
  activeUnit,
  setActiveUnit,
  setRefreshKey,
  setActivePreviewUnit,
  activePreviewUnit,
  onSaveContent,
}) => {
  const [isSorted, setIsSorted] = useState(false);
  const router = useRouter();
  const { query } = router;
  const dispatch = useDispatch();
  let [list, setList] = useState([]);
  // const [activeKey, setActiveKey] = useState("");
  const [isSectionDialogOpened, setIsSectionDialogOpened] = useState(false);
  const [selectedTemplateList, setSelectedTemplateList] = useState([]);
  const [isRenameDialogOpened, setIsRenameDialogOpened] = useState(false);
  const [isTextTemplateDialogOpened, setIsTextTemplateDialogOpened] =
    useState(false);
  const [isNotesTemplateDialogOpened, setIsNotesTemplateDialogOpened] =
    useState(false);

  const sections = useSelector(
    (state) => state.report?.selectedReport?.sections
  );

  const selectedReportData = useSelector(
    (state) => state.report?.selectedReport
  );

  const addSectionFn = (id, currentPosition, selectedIndex) => {
    const emptyTextContent = {
      report: {
        id: query?.reportId,
      },
      type: "text",
      data: {
        name: "Text",
        html: "<p>Write Something...</p>",
      },
      visibleIndex: selectedIndex,
    };
    bulkCreateReportGeneratorsHook(
      {
        bulk: [emptyTextContent],
      },
      (response) => {
        const copyList = [...list];
        copyList.splice(currentPosition, 0, response[0]);
        setList([...copyList]);
        setTimeout(() => {
          updateSectionOrdering();
        }, 100);
      }
    );
  };

  useEffect(() => {
    if (sections?.length) {
      console.log("Sections Updated", sections);
      setList(sections);
      if (!activeUnit?.id) {
        setActivePreviewUnit(sections);
        setActiveUnit(sections[0]);
        // getReportGeneratorPreviewById({ id: sections[0]?.id }, (response) => {
        //   console.log("Preview response", response);
        //   setActivePreviewUnit(response);
        //   setActiveUnit(sections[0]);
        // });
      }
    } else {
      setList([]);
    }
  }, [JSON.stringify(sections)]);

  const onDragEnd = (e) => {
    let sourceIndex = e?.source?.index;
    let destinationIndex = e?.destination?.index;
    console.log("Source Index", destinationIndex, sourceIndex);
    if (destinationIndex !== sourceIndex) {
      const copyList = [...list];
      if (sourceIndex > destinationIndex) {
        copyList.splice(destinationIndex, 0, list[sourceIndex]);
        copyList.splice(sourceIndex + 1, 1);
      } else if (sourceIndex < destinationIndex) {
        copyList.splice(destinationIndex + 1, 0, list[sourceIndex]);
        copyList.splice(sourceIndex, 1);
      }
      console.log("Updated Drag List", copyList);
      setList([...copyList]);
    }
  };

  const closeTextDialog = () => {
    setIsTextTemplateDialogOpened(false);
  };

  const closeNotesDialog = () => {
    setIsNotesTemplateDialogOpened(false);
  };

  const updateSectionOrdering = () => {
    const updatedArray = [...list];

    const updatedList = [];
    updatedArray.forEach((item, index) => {
      updatedList.push({
        ...item,
        visibleIndex: index + 1,
      });
    });
    console.log("Final Updated List", updatedList);
    setList([...updatedList]);
    bulkPatchAndCreateReportGeneratorsHook(updatedList);
    setTimeout(() => {
      const reportId = query?.reportId;
      getReportGenerators({ reportId }, (response) => {
        if (response) {
          console.log("Resport Generators", response);
          if (response?.length) {
            const selectedReportData = {
              sections: [],
            };
            response?.forEach((item) => {
              if (item?.type === "coverPage") {
                // setCoverPageData(item);
                selectedReportData.coverPage = item;
              } else if (item?.type === "pageHeader") {
                // setHeaderData(item);
                selectedReportData.pageHeader = item;
              } else if (item?.type === "pageFooter") {
                // setFooterData(item);
                selectedReportData.pageFooter = item;
              } else if (item?.type === "financialPositionEndDate") {
                selectedReportData.financialPositionEndDate = item;
              } else if (item?.type === "profitLossDate") {
                selectedReportData.profitLossDate = item;
              } else if (item?.type === "changesEquityDate") {
                selectedReportData.changesEquityDate = item;
              } else if (item?.type === "currency") {
                selectedReportData.currency = item;
              } else if (item?.type === "company") {
                selectedReportData.company = item;
              } else {
                selectedReportData.sections.push(item);
              }
            });

            const sortedSections = sortReportSections(
              selectedReportData.sections
            );

            selectedReportData.sections = sortedSections;

            console.log("Sorted Sections", sortedSections);
            setRefreshKey(Math.random());
            dispatch(setSelectedReport(cloneDeep(selectedReportData)));
          }
        }
      });
    }, 1000);
  };

  const onDeleteSection = (sectionId, index) => {
    deleteReportSectionHook(
      {
        id: sectionId,
      },
      (response) => {
        const updatedArray = [...list];
        updatedArray.splice(index, 1);
        const updatedList = [];
        updatedArray.forEach((item, index) => {
          updatedList.push({
            ...item,
            visibleIndex: index + 1,
          });
        });

        setList([...updatedList]);
        bulkPatchAndCreateReportGeneratorsHook(updatedList);
        setTimeout(() => {
          const reportId = query?.reportId;
          getReportGenerators({ reportId }, (response) => {
            if (response) {
              console.log("Resport Generators", response);
              if (response?.length) {
                const selectedReportData = {
                  sections: [],
                };
                response?.forEach((item) => {
                  if (item?.type === "coverPage") {
                    // setCoverPageData(item);
                    selectedReportData.coverPage = item;
                  } else if (item?.type === "pageHeader") {
                    // setHeaderData(item);
                    selectedReportData.pageHeader = item;
                  } else if (item?.type === "pageFooter") {
                    // setFooterData(item);
                    selectedReportData.pageFooter = item;
                  } else if (item?.type === "financialPositionEndDate") {
                    selectedReportData.financialPositionEndDate = item;
                  } else if (item?.type === "profitLossDate") {
                    selectedReportData.profitLossDate = item;
                  } else if (item?.type === "changesEquityDate") {
                    selectedReportData.changesEquityDate = item;
                  } else if (item?.type === "currency") {
                    selectedReportData.currency = item;
                  } else if (item?.type === "company") {
                    selectedReportData.company = item;
                  } else {
                    selectedReportData.sections.push(item);
                  }
                });

                const sortedSections = sortReportSections(
                  selectedReportData.sections
                );

                selectedReportData.sections = sortedSections;

                console.log("Sorted Sections", sortedSections);
                setRefreshKey(Math.random());
                dispatch(setSelectedReport(cloneDeep(selectedReportData)));
              }
            }
          });
        }, 1000);
        // const sortedSections = sortReportSections(updatedArray);
        // setList([...sortedSections]);
        // dispatch(
        //   setSelectedReport({ ...selectedReportData, sections: sortedSections })
        // );
        console.log("Report Sections Deleted Successfully", response);
      }
    );
  };

  const addFn = () => {
    console.log("selectedTemplateList addFn", selectedTemplateList);
    bulkCreateReportGeneratorsHook(
      {
        bulk: selectedTemplateList,
      },
      (response) => {
        const updatedArray = [...list];
        console.log("Report Genetaors", response);
        setSelectedTemplateList([]);
        if (response?.length) {
          response?.forEach((item) => {
            updatedArray.push(item);
            console.log("items are", item);
          });
          const sortedSections = sortReportSections(updatedArray);
          setList([...sortedSections]);
          dispatch(
            setSelectedReport({
              ...selectedReportData,
              sections: sortedSections,
            })
          );
          setRefreshKey(Math.random());
        }
        setIsSectionDialogOpened(false);
      }
    );
  };

  const onInsertPageBreak = () => {
    const copyList = [...list];

    copyList.splice(activeUnit?.visibleIndex, 0, {
      report: {
        id: query?.reportId,
      },
      type: "pageBreak",
      data: {
        html: '<cp id="page-break" style="page-break-before: always; display:block" class="page-break"></cp>',
        name: `Page Break`,
      },
      visibleIndex: activeUnit?.visibleIndex + 1,
    });

    const updatedList = [];

    copyList.forEach((item, index) => {
      updatedList.push({
        ...item,
        visibleIndex: index + 1,
      });
    });
    console.log("updatedList", updatedList);
    setList([...updatedList]);
    bulkPatchAndCreateReportGeneratorsHook(updatedList);
    setTimeout(() => {
      const reportId = query?.reportId;
      getReportGenerators({ reportId }, (response) => {
        if (response) {
          if (response?.length) {
            const selectedReportData = {
              sections: [],
            };
            response?.forEach((item) => {
              if (item?.type === "coverPage") {
                // setCoverPageData(item);
                selectedReportData.coverPage = item;
              } else if (item?.type === "pageHeader") {
                // setHeaderData(item);
                selectedReportData.pageHeader = item;
              } else if (item?.type === "pageFooter") {
                // setFooterData(item);
                selectedReportData.pageFooter = item;
              } else if (item?.type === "financialPositionEndDate") {
                selectedReportData.financialPositionEndDate = item;
              } else if (item?.type === "profitLossDate") {
                selectedReportData.profitLossDate = item;
              } else if (item?.type === "changesEquityDate") {
                selectedReportData.changesEquityDate = item;
              } else if (item?.type === "currency") {
                selectedReportData.currency = item;
              } else if (item?.type === "company") {
                selectedReportData.company = item;
              } else {
                selectedReportData.sections.push(item);
              }
            });

            const sortedSections = sortReportSections(
              selectedReportData.sections
            );

            selectedReportData.sections = sortedSections;

            setRefreshKey(Math.random());
            dispatch(setSelectedReport(cloneDeep(selectedReportData)));
          }
        }
      });
    }, 1000);
  };
  const onInsertPageBreakAbove = () => {
    const copyList = [...list];

    copyList.splice(activeUnit?.visibleIndex - 1, 0, {
      report: {
        id: query?.reportId,
      },
      type: "pageBreak",
      data: {
        html: '<cp id="page-break" style="page-break-before: always; display:block" class="page-break"></cp>',
        name: `Page Break`,
      },
      visibleIndex: activeUnit?.visibleIndex,
    });
    const updatedList = [];

    copyList.forEach((item, index) => {
      updatedList.push({
        ...item,
        visibleIndex: index + 1,
      });
    });
    console.log("updatedList above", updatedList);
    setList([...updatedList]);
    bulkPatchAndCreateReportGeneratorsHook(updatedList);
    setTimeout(() => {
      const reportId = query?.reportId;
      getReportGenerators({ reportId }, (response) => {
        if (response) {
          if (response?.length) {
            const selectedReportData = {
              sections: [],
            };
            response?.forEach((item) => {
              if (item?.type === "coverPage") {
                // setCoverPageData(item);
                selectedReportData.coverPage = item;
              } else if (item?.type === "pageHeader") {
                // setHeaderData(item);
                selectedReportData.pageHeader = item;
              } else if (item?.type === "pageFooter") {
                // setFooterData(item);
                selectedReportData.pageFooter = item;
              } else if (item?.type === "financialPositionEndDate") {
                selectedReportData.financialPositionEndDate = item;
              } else if (item?.type === "profitLossDate") {
                selectedReportData.profitLossDate = item;
              } else if (item?.type === "changesEquityDate") {
                selectedReportData.changesEquityDate = item;
              } else if (item?.type === "currency") {
                selectedReportData.currency = item;
              } else if (item?.type === "company") {
                selectedReportData.company = item;
              } else {
                selectedReportData.sections.push(item);
              }
            });

            const sortedSections = sortReportSections(
              selectedReportData.sections
            );

            selectedReportData.sections = sortedSections;

            setRefreshKey(Math.random());
            dispatch(setSelectedReport(cloneDeep(selectedReportData)));
          }
        }
      });
    }, 1000);
  };

  const onCloseRenameDialog = () => {
    setIsRenameDialogOpened(false);
  };
  return (
    <div id="group-radio" className={"group-radio"}>
      <div className={"operate"}>
        {/* 1.添加元件 */}
        <span
          //   title={formatMessage({
          //     id: "Add-components",
          //     defaultMessage: "添加元件",
          //   })}
          style={{ cursor: "pointer" }}
          onClick={() => {
            onSaveContent(() => {
              setIsSectionDialogOpened(true);
            });
          }}
        >
          <PlusOutlined />
          <span style={{ marginLeft: "11px" }}>Add New Section</span>
        </span>

        <span
          // title={formatMessage({
          //   id: "Add-components",
          //   defaultMessage: "添加元件",
          // })}
          style={{ cursor: "pointer", marginLeft: "15px" }}
          onClick={() => {
            if (isSorted) {
              updateSectionOrdering();
            } else {
              onSaveContent(() => {});
            }
            setIsSorted(!isSorted);
          }}
        >
          <ArrangerOrderIcon />
          {isSorted && <span style={{ marginLeft: "11px" }}>Done sorting</span>}
          {!isSorted && (
            <span style={{ marginLeft: "11px" }}>Arrange order</span>
          )}
        </span>
      </div>
      <div
        className={classNames("tabs tabs-height", !collapsed && "collpased")}
      >
        {isSorted == true && list.length > 0 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "5px",
              marginTop: "22px",
            }}
          >
            <span className="move-tips">
              Please drag the tabs to sort
              {/* <FormattedMessage
                id="drag-the-labels"
                defaultMessage="请拖动标签进行排序"
              /> */}
            </span>
          </div>
        )}
        <div
          className={[
            "drag-box-1818 tab-section-height",
            isSorted ? "sort-1818" : "sort-not-1818",
          ].join(" ")}
          style={{ marginTop: isSorted ? "10px" : "30px" }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="droppable"
              direction="vertical"
              ignoreContainerClipping={true}
              isDropDisabled={!isSorted}
            >
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="dropp-able-box"
                >
                  {list.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={!isSorted}
                    >
                      {(provided, snapshot) => (
                        <div
                          //title={item.name}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className={[
                            "box-1842",
                            activeUnit?.id == item.id ? "box-cur" : "",
                          ].join(" ")}
                          item={JSON.stringify(item)}
                          onClick={(e) => {
                            console.log("Selected Item", item);
                            getReportGeneratorPreviewById(
                              { id: item?.id },
                              (response) => {
                                window.location.hash = item.id;
                                console.log("Preview response", response);
                                setActivePreviewUnit(response);
                                setActiveUnit(item);
                              }
                            );

                            // let _item = JSON.parse(
                            //   e.target.getAttribute("item")
                            // );
                            // let _key = _item?.id || "";
                            // console.log("I am getting called", _key, item);
                            // if (_key) {
                            //   //   setActiveItem(_key);
                            // }
                            e.preventDefault();
                            e.stopPropagation();
                            e.persist();
                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {item?.data?.name}
                            </span>

                            {!isSorted && (
                              <ReportSectionMenu
                                item={item}
                                indexValue={index}
                                onDeleteSection={onDeleteSection}
                                onInsertPageBreak={onInsertPageBreak}
                                onInsertPageBreakAbove={onInsertPageBreakAbove}
                                onSaveContent={onSaveContent}
                                setIsRenameDialogOpened={
                                  setIsRenameDialogOpened
                                }
                                onAddNewSection={addSectionFn}
                              />
                              // <TrashIcon
                              //   style={{
                              //     marginLeft: "10px",
                              //     cursor: "pointer",
                              //   }}
                              //   className="section-trash-icon"
                              //   onClick={() => {
                              //     onDeleteSection(item.id, index);
                              //   }}
                              // />
                            )}
                          </div>

                          {/* ({ctrlObj.getType(item.type)}){ctrlObj.getName(item)} */}
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                  <div style={{ clear: "both" }}></div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      {isSectionDialogOpened && (
        <AddSectionModal
          addModalVisible={isSectionDialogOpened}
          setAddModalVisible={setIsSectionDialogOpened}
          setIsTextTemplateDialogOpened={setIsTextTemplateDialogOpened}
          setIsNotesTemplateDialogOpened={setIsNotesTemplateDialogOpened}
          addFn={addFn}
          setSelectedTemplateList={setSelectedTemplateList}
          selectedTemplateList={selectedTemplateList}
        />
      )}
      {isTextTemplateDialogOpened && (
        <ReportTemplatesModal
          open={isTextTemplateDialogOpened}
          handleClose={closeTextDialog}
          type="text"
          setSelectedTemplateList={setSelectedTemplateList}
          selectedTemplateList={selectedTemplateList}
          addFn={addFn}
          setIsSectionDialogOpened={setIsSectionDialogOpened}
        />
      )}
      {isNotesTemplateDialogOpened && (
        <ReportTemplatesModal
          open={isNotesTemplateDialogOpened}
          handleClose={closeNotesDialog}
          type="notes"
          setSelectedTemplateList={setSelectedTemplateList}
          selectedTemplateList={selectedTemplateList}
          addFn={addFn}
          setIsSectionDialogOpened={setIsSectionDialogOpened}
        />
      )}
      {isRenameDialogOpened && (
        <RenameSectionModal
          section={activeUnit}
          open={isRenameDialogOpened}
          handleClose={onCloseRenameDialog}
        />
      )}
    </div>
  );
};

export default ReportSections;
