// import less

import { useState } from "react";
import ComponentModal from "../../atoms/ComponentModal";
import RearrangeExpandableGroup from "./ExpandableGroup";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { updateCategoryHook } from "../../api-hooks/categories";
import { orderBy } from "lodash";

const RearrangeModal = ({
  open,
  handleClose,
  handleOk,
  rearrangeData,
  type,
  rearrangeList,
  setPreviewData,
  onDragEndFn,
}) => {
  console.log("rearrangeData", rearrangeData);
  rearrangeList = [{}];
  let defaultIndex = 0;

  const onDragEnd = (e) => {
    onDragEndFn(e);
    // console.log("On Drag End", e, e?.source?.droppableId);
    // let sourceIndex = e?.source?.index;
    // let destinationIndex = e?.destination?.index;
    // if (e?.source?.droppableId === "droppable") {
    //   if (destinationIndex !== sourceIndex) {
    //     let copyList = [...rearrangeData];
    //     if (sourceIndex > destinationIndex) {
    //       copyList.splice(destinationIndex, 0, rearrangeData[sourceIndex]);
    //       copyList.splice(sourceIndex + 1, 1);
    //     } else if (sourceIndex < destinationIndex) {
    //       copyList.splice(destinationIndex + 1, 0, rearrangeData[sourceIndex]);
    //       copyList.splice(sourceIndex, 1);
    //     }
    //     const list = [];
    //     copyList?.forEach((item, index) => {
    //       list.push({
    //         ...item,
    //         visibleIndex: index + 1,
    //       });
    //       if (item?.children?.length) {
    //         item?.children?.forEach((children, childIndex) => {
    //           list.push({
    //             ...children,
    //             visibleIndex: childIndex + 1,
    //           });
    //         });
    //       }
    //     });
    //     console.log("Updated Preview List", copyList, list);
    //     updateCategory(list);
    //     setPreviewData([...copyList]);
    //   }
    // } else {
    //   if (
    //     destinationIndex !== sourceIndex ||
    //     e?.source?.droppableId !== e?.destination?.droppableId
    //   ) {
    //     let sourceParentItem;
    //     let destinationParentItem;
    //     rearrangeData.map((previewCategory) => {
    //       const childItem = previewCategory?.children?.find(
    //         (data) => data?.id === e.draggableId
    //       );
    //       console.log(
    //         "rearrangeData12",
    //         childItem,
    //         rearrangeData,
    //         e.draggableId
    //       );
    //       const findDestinationParentItem = rearrangeData?.find(
    //         (p) => p.id === e?.destination?.droppableId
    //       );
    //       if (findDestinationParentItem) {
    //         destinationParentItem = findDestinationParentItem;
    //       }
    //       const findSourceParentItem = rearrangeData?.find(
    //         (p) => p.id === e?.source?.droppableId
    //       );
    //       if (findSourceParentItem) {
    //         sourceParentItem = findSourceParentItem;
    //       }
    //       // console.log("parentItem12", parentItem);
    //       // if (previewCategory?.id === item?.id) {
    //       //   previewCategory.children = copyList;
    //       // }
    //     });

    //     const copyList = [...destinationParentItem.children];
    //     const sourceCopyList = [...sourceParentItem.children];
    //     if (e?.destination?.droppableId === e?.source?.droppableId) {
    //       if (sourceIndex > destinationIndex) {
    //         copyList.splice(
    //           destinationIndex,
    //           0,
    //           destinationParentItem.children[sourceIndex]
    //         );
    //         copyList.splice(sourceIndex + 1, 1);
    //       } else if (sourceIndex < destinationIndex) {
    //         copyList.splice(
    //           destinationIndex + 1,
    //           0,
    //           destinationParentItem.children[sourceIndex]
    //         );
    //         copyList.splice(sourceIndex, 1);
    //       }
    //     } else {
    //       copyList.splice(
    //         destinationIndex,
    //         0,
    //         sourceParentItem.children[sourceIndex]
    //       );
    //       sourceCopyList.splice(sourceIndex, 1);
    //     }

    //     console.log(
    //       "Source Parent Item",
    //       sourceParentItem,
    //       destinationParentItem,
    //       [...copyList],
    //       [...sourceCopyList]
    //     );

    //     rearrangeData.map((previewCategory) => {
    //       if (previewCategory?.id === destinationParentItem?.id) {
    //         previewCategory.children = copyList;
    //       }
    //       if (e?.destination?.droppableId !== e?.source?.droppableId) {
    //         if (previewCategory?.id === sourceParentItem?.id) {
    //           previewCategory.children = sourceCopyList;
    //         }
    //       }
    //     });
    //     const list = [];
    //     rearrangeData?.forEach((item, index) => {
    //       list.push({
    //         ...item,
    //         visibleIndex: index + 1,
    //       });
    //       if (item?.children?.length) {
    //         item?.children?.forEach((children, childIndex) => {
    //           list.push({
    //             ...children,
    //             visibleIndex: childIndex + 1,
    //             parent: {
    //               id: item?.id,
    //             },
    //           });
    //         });
    //       }
    //     });
    //     console.log("Updated Preview List 12", [...rearrangeData], [...list]);
    //     updateCategory([...list]);

    //     setPreviewData([...rearrangeData]);
    //   }
    // }
  };

  const updateCategory = (list) => {
    list?.forEach((item) => {
      updateCategoryHook(
        {
          id: item?.id,
          visibleIndex: item?.visibleIndex,
        },
        (response) => {
          console.log("Category Updated Successfully!", response);
        }
      );
    });
  };
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      submitText="Rearrange"
      title="Rearrange"
      width={628}
      handleOk={handleOk}
    >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="droppable"
          direction="vertical"
          ignoreContainerClipping={true}
          type="items"
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="dropp-able-box"
            >
              {rearrangeData.map((item, index) => {
                const itemList = item?.children;
                if (itemList.length > 0) {
                  return (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          item={JSON.stringify(item)}
                        >
                          <RearrangeExpandableGroup
                            item={item}
                            previewList={rearrangeData}
                            setPreviewData={setPreviewData}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                }
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      {/* {type === "income_item" && (
        <>
          {Object.keys(rearrangeData).map((key) => {
            const itemList = rearrangeData[key];
            if (itemList.isBold) {
              rearrangeList.push(itemList);
              defaultIndex += 1;
            } else {
              if (rearrangeList[defaultIndex].children) {
                rearrangeList[defaultIndex].children.push(itemList);
              } else {
                rearrangeList[defaultIndex].children = [];
                rearrangeList[defaultIndex].children.push(itemList);
              }
            }
          })}
          {rearrangeList.map((item) => {
            return <RearrangeExpandableGroup item={item} />;
          })}
        </>
      )} */}
    </ComponentModal>
  );
};

export default RearrangeModal;
