import "./Editor.less";

import { Extension, mergeAttributes, Node, Editor } from "@tiptap/core";
import { EditorContent, useEditor, ReactRenderer } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";

import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableCell from "@tiptap/extension-table-cell";

import DoubleUnderline from "./extensions/double-underline";
import Underline from "./extensions/underline";
import Equation from "./extensions/equation";

import React, { useEffect, useState } from "react";

import doubleUnderlineIcon from "../../assets/svg-icons/double-underline.svg";

import { MentionList } from "./MentionList";

import {
  AlignCenterOutlined,
  AlignLeftOutlined,
  AlignRightOutlined,
  BoldOutlined,
  HighlightOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  UnderlineOutlined,
  UndoOutlined,
  FilePdfOutlined,
  FullscreenOutlined,
  FullscreenExitOutlined,
  FileWordOutlined,
} from "@ant-design/icons";

import { ReactComponent as CreateTableIcon } from "../../assets/svg-icons/createTable.svg";
import { ReactComponent as AfterColumnIcon } from "../../assets/svg-icons/afterColumn.svg";
import { ReactComponent as BeforeColumnIcon } from "../../assets/svg-icons/beforeColumn.svg";
import { ReactComponent as DeleteColumnIcon } from "../../assets/svg-icons/deleteColumn.svg";
import { ReactComponent as AfterRowIcon } from "../../assets/svg-icons/afterRow.svg";
import { ReactComponent as BeforeRowIcon } from "../../assets/svg-icons/beforeRow.svg";
import { ReactComponent as DeleteRowIcon } from "../../assets/svg-icons/deleteRow.svg";
import { ReactComponent as DeleteTableIcon } from "../../assets/svg-icons/deleteTable.svg";
import { ReactComponent as TableUnderlineIcon } from "../../assets/svg-icons/tableUnderline.svg";
import { ReactComponent as TableDoubleUnderlineIcon } from "../../assets/svg-icons/tableDoubleUnderline.svg";
import { ReactComponent as MergeCellIcon } from "../../assets/svg-icons/mergeCell.svg";
import { ReactComponent as SplitCellIcon } from "../../assets/svg-icons/splitCell.svg";

import ComponentButton from "../../atoms/ComponentButton";
import { Switch, Tooltip } from "antd";
import { CustomTableHeader } from "./extensions/CustomTableHeader";

import { useRouter } from "../../routes";
import SaveDiscardModal from "../SaveDiscard";
import { ShowEquationData } from "./ShowEquationData";
import { ReactComponent as TrashIcon } from "../../assets/svg-icons/trashIcon.svg";
import { getReportGeneratorPreviewById } from "../../api-hooks/report";
import api from "../../api-hooks/api-intercepter";
import { orderBy } from "lodash";
import DeleteTemplateItemModal from "../DeleteTemplateItemModal";
import TableHeader from "@tiptap/extension-table-header";
import { CustomTableCell } from "./extensions/CustomTableCell";
import { SlashCommands } from "./extensions/slash-commands";
import CommandsList from "./extensions/slash-commands/CommandList";

const CustomDiv = Node.create({
  name: "div",
  content: "block*",
  group: "block",
  defining: true,
  parseHTML() {
    return [
      {
        tag: "div",
      },
    ];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            id: attributes.id,
          };
        },
      },
      // style: {
      //   default: null,
      //   parseHTML: (element) => element.getAttribute("style"),
      //   renderHTML: (attributes) => {
      //     if (!attributes.style) {
      //       return {};
      //     }
      //     return {
      //       style: attributes.style,
      //     };
      //   },
      // },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "div",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const CustomParagraph = Node.create({
  name: "cp",
  content: "block*",
  group: "block",
  defining: true,
  parseHTML() {
    return [
      {
        tag: "cp",
      },
    ];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            id: attributes.id,
          };
        },
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style"),
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: attributes.style,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class"),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "cp",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});

const CustomAttrbutes = Extension.create({
  addGlobalAttributes() {
    return [
      {
        // Extend the following extensions
        types: [
          "div",

          // "heading",
          "span",
          "cp",
          "table",
          "tableRow",
          "tableCell",
        ],
        // … with those attributes
        attributes: {
          id: {
            default: null,
            parseHTML: (element) => element.getAttribute("id"),
            renderHTML: (attributes) => {
              if (!attributes.id) {
                return {};
              }
              return {
                id: attributes.id,
              };
            },
          },
          style: {
            default: null,
            parseHTML: (element) => element.getAttribute("style"),
            renderHTML: (attributes) => {
              if (!attributes.style) {
                return {};
              }
              return {
                style: attributes.style,
              };
            },
          },
          class: {
            default: null,
            parseHTML: (element) => element.getAttribute("class"),
            renderHTML: (attributes) => {
              if (!attributes.class) {
                return {};
              }
              return {
                class: attributes.class,
              };
            },
          },
        },
      },
    ];
  },
});

const SectionExtension = Node.create({
  name: "report-generator-section",
  content: "block*",
  group: "block",
  defining: true,
  parseHTML() {
    return [
      {
        tag: "report-generator-section",
      },
    ];
  },
  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute("id"),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }
          return {
            id: attributes.id,
          };
        },
      },
      style: {
        default: null,
        parseHTML: (element) => element.getAttribute("style"),
        renderHTML: (attributes) => {
          if (!attributes.style) {
            return {};
          }
          return {
            style: attributes.style,
          };
        },
      },
      class: {
        default: null,
        parseHTML: (element) => element.getAttribute("class"),
        renderHTML: (attributes) => {
          if (!attributes.class) {
            return {};
          }
          return {
            class: attributes.class,
          };
        },
      },
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "report-generator-section",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  // addKeyboardShortcuts() {
  //   return {
  //     Enter: () =>
  //       !this.editor.isActive("bulletList") &&
  //       !this.editor.isActive("orderedList")
  //         ? this.editor.commands.setHardBreak()
  //         : this.editor.commands.newlineInCode(),
  //     "Shift-Enter": () => this.editor.commands.setHardBreak(),
  //     // Backspace: () => {
  //     //   this.editor.commands.deleteRange();
  //     // },
  //   };
  // },
});

const MenuBar = ({
  editor,
  onChange,
  isEditMode,
  onPdf,
  onWord,
  preview,
  isFull,
  onChangeFull,
  hideEquation,
  isSaveButtonEnabled,
  onSave,
  data,
  type,
  hideSwitchBtn,
  saveLoading,
  setContentUpdatedObj,
}) => {
  if (!editor) {
    return null;
  }

  return (
    <div className="editor-menu-container">
      {!preview ? (
        <>
          <button
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={
              editor.isActive("bold")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Bold">
              <BoldOutlined />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={
              editor.isActive("italic")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Italic">
              <ItalicOutlined />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={
              editor.isActive("strike")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Strike Through">
              {" "}
              <StrikethroughOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={
              editor.isActive("underline")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Underline">
              <UnderlineOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              if (editor.isActive("tableCell")) {
                console.log(
                  "Is Table Cell active",
                  editor.getAttributes("tableCell")
                );
                if (editor.getAttributes("tableCell")?.style) {
                  editor.chain().focus().toggleTableCellSingleUnderLine().run();
                } else {
                  editor
                    .chain()
                    .focus()
                    .toggleTableCellSingleUnderLine("border-bottom:1px solid")
                    .run();
                }
              }
            }}
            className={
              editor.isActive("underline")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Table Underline">
              <TableUnderlineIcon
                style={{
                  width: "16px",
                  height: "16px",
                  position: "relative",
                  top: "2px",
                }}
              />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().toggleDoubleUnderline().run()}
            className={
              editor.isActive("double-underline")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Double Underline">
              <img
                src={doubleUnderlineIcon}
                alt="double-underline"
                style={{ width: "19px", height: "19px", marginBottom: "3px" }}
              />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              if (editor.isActive("tableCell")) {
                console.log(
                  "Is Table Cell active",
                  editor.getAttributes("tableCell")
                );
                if (editor.getAttributes("tableCell")?.style) {
                  editor.chain().focus().toggleTableCellDoubleUnderLine().run();
                } else {
                  editor
                    .chain()
                    .focus()
                    .toggleTableCellDoubleUnderLine("border-bottom:4px double")
                    .run();
                }
              }
            }}
            className={
              editor.isActive("double-underline")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Table Double Underline">
              <TableDoubleUnderlineIcon
                style={{
                  width: "16px",
                  height: "16px",
                  position: "relative",
                  top: "2px",
                }}
              />
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().setParagraph().run()}
            className={
              editor.isActive("paragraph")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Paragraph">P</Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className={
              editor.isActive("heading", { level: 1 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading One">
              H<sup>1</sup>
            </Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive("heading", { level: 2 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading Two">
              H<sup>2</sup>
            </Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive("heading", { level: 3 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading Three">
              H<sup>3</sup>
            </Tooltip>
          </button>
          <button
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 4 }).run()
            }
            className={
              editor.isActive("heading", { level: 4 })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Heading Four">
              H<sup>4</sup>
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().toggleHighlight().run()}
            className={
              editor.isActive("highlight")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Marker">
              <HighlightOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().setTextAlign("left").run();
            }}
            className={
              editor.isActive({ textAlign: "left" })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Left Align">
              <AlignLeftOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().unsetTextAlign().run();
              editor.chain().focus().setTextAlign("right").run();
            }}
            className={
              editor.isActive({ textAlign: "right" })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Right Align">
              <AlignRightOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => {
              editor.chain().focus().unsetTextAlign().run();
              editor.chain().focus().setTextAlign("center").run();
            }}
            className={
              editor.isActive({ textAlign: "center" })
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Center Align">
              <AlignCenterOutlined />
            </Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().toggleBlockquote().run()}
            className={
              editor.isActive("blockquote")
                ? "is-active editor-menu-item-container"
                : "editor-menu-item-container"
            }
          >
            <Tooltip title="Quotes">"</Tooltip>
          </button>

          <button
            onClick={() => editor.chain().focus().undo().run()}
            className="editor-menu-item-container"
            disabled={!editor.can().undo()}
          >
            <Tooltip title="Undo">
              <UndoOutlined />
            </Tooltip>
          </button>

          <button onClick={onChangeFull} className="editor-menu-item-container">
            {isFull ? (
              <Tooltip title="Exit Full Screen">
                <FullscreenExitOutlined />
              </Tooltip>
            ) : (
              <Tooltip title="Full Screen">
                <FullscreenOutlined />
              </Tooltip>
            )}
          </button>
          <button
            onClick={() => {
              editor
                .chain()
                .focus()
                .insertTable({ rows: 3, cols: 3, withHeadersRow: false })
                .run();
            }}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Table">
              <CreateTableIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addColumnBefore().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Column Before">
              <BeforeColumnIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Column After">
              <AfterColumnIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().deleteColumn().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Delete Column">
              <DeleteColumnIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addRowBefore().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Row Before">
              <BeforeRowIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().addRowAfter().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Add Row After">
              <AfterRowIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().deleteRow().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Delete Row">
              <DeleteRowIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().mergeCells().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Merge Cells">
              <MergeCellIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().splitCell().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Split Cell">
              <SplitCellIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
          <button
            onClick={() => editor.chain().focus().deleteTable().run()}
            className="editor-menu-item-container"
          >
            <Tooltip title="Delete Table">
              <DeleteTableIcon style={{ width: "16px", height: "16px" }} />
            </Tooltip>
          </button>
        </>
      ) : null}
      {onPdf ? (
        <button
          onClick={async () => {
            onPdf();
          }}
          className="editor-menu-item-container"
        >
          <Tooltip title="Download PDF">
            <FilePdfOutlined style={{ position: "relative", bottom: "2px" }} />
          </Tooltip>
        </button>
      ) : null}
      {onWord ? (
        <button
          onClick={async () => {
            // const converted = htmlDocx.asBlob(data, {orientation: 'landscape', margins: {top: 720}})
            // saveAs(converted, 'doc.docx')
            onPdf(true, onWord);
            // onWord();
          }}
          className="editor-menu-item-container"
        >
          <Tooltip title="Download Word">
            <FileWordOutlined style={{ position: "relative", bottom: "2px" }} />
          </Tooltip>
        </button>
      ) : null}
      {!preview ? (
        <div
          style={{
            flex: "1 1 auto",
            display: "flex",
            // flexDirection: "row-reverse",
            marginTop: "1em",
            alignItems: "center",
          }}
        >
          <div style={{ flex: "1 1 ", color: "rgb(105, 111, 140)" }}>
            {!hideEquation && (
              <span>
                Type <b>#</b> to insert equations
              </span>
            )}
          </div>
          {isSaveButtonEnabled && isEditMode && (
            <ComponentButton
              title="Save"
              onClick={() => {
                onSave(type, data, (response) => {
                  onChange("save");
                  setContentUpdatedObj({});
                });
              }}
              style={{ marginRight: "12px" }}
              loading={saveLoading}
            />
          )}
          {!hideSwitchBtn && (
            <Switch
              checked={isEditMode}
              onChange={(e) => onChange("switch", e)}
              size="small"
              // checkedChildren="Edit"
              unCheckedChildren="Edit"
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

const MultiInstanceEditor = ({
  data,
  setData,
  type,
  onSwitch,
  onSave,
  isSaveButtonEnabled,
  previewData,
  onPdf,
  onWord,
  preview,
  hideEquation,
  defaultEnable,
  hideSwitchBtn,
  sections,
  setContentUpdatedObj,
  contentUpdatedObj,
  onDeleteSection,
  equationDataObj,
  saveLoading,
  accountObj,
}) => {
  const [isEditMode, setIsEditMode] = useState(defaultEnable);
  const [isFull, setIsFull] = useState(false);
  const [isEdited, setIsEdited] = useState(false);
  const [openDiscard, setOpenDiscard] = useState(false);

  const [editorList, setEditorList] = useState([]);

  const router = useRouter();
  const { query } = router;

  const [open, setOpen] = useState(false);
  const [selectedEditor, setSelectedEditor] = useState(null);

  const [isDeleteSectionDialogOpened, setIsDeleteSectionDialogOpened] =
    useState("");

  const getCommandList = () => {
    let list = [];
    Object.keys(accountObj)?.forEach((accountType) => {
      const accountData = accountObj[accountType];
      Object.keys(accountData)?.forEach((accountKey) => {
        list.push({
          title: accountKey,
          category: accountType,
          command: ({ editor, range }) => {
            editor
              .chain()
              .focus()
              .deleteRange(range)
              .insertContent(accountKey)
              .run();
          },
        });
      });
    });
    return list;
  };

  const createNewEditor = () => {
    return new Editor(
      {
        extensions: [
          StarterKit,
          TextAlign.configure({
            types: ["heading", "paragraph"],
          }),

          Highlight,
          Underline.configure({
            style: "border-bottom:1px solid;text-decoration:none",
          }),
          Table.configure({
            resizable: true,
          }),
          TableRow,
          // TableHeader,

          CustomTableHeader,
          // TableCell,
          CustomTableCell,
          // SectionExtension,
          // CustomAttrbutes,
          // CustomDiv,
          CustomParagraph,

          Equation.configure({
            HTMLAttributes: {
              class: "equationNode",
              obj: equationDataObj,
            },
            suggestion: {
              render: () => {
                let reactRenderer;
                console.log("On start rendered", reactRenderer);
                return {
                  onStart: (props) => {
                    reactRenderer = new ReactRenderer(MentionList, {
                      props,
                      editor: props.editor,
                      // equations: equations,
                    });

                    console.log("On Start Mention renderer", reactRenderer);
                  },

                  onUpdate(props) {
                    reactRenderer?.updateProps(props);
                  },

                  onKeyDown(props) {
                    if (props.event.key === "Escape") {
                      reactRenderer?.destroy();
                      return true;
                    }

                    return reactRenderer?.ref?.onKeyDown(props);
                  },

                  onExit() {
                    console.log("On Exit Renderer", reactRenderer);
                    if (reactRenderer) {
                      reactRenderer.destroy();
                    }
                  },
                };
              },
            },
          }),
          DoubleUnderline.configure({
            style: "border-bottom:4px double;text-decoration:none",
          }),
          SlashCommands.configure({
            commands: getCommandList(),
            // commands: [
            //   {
            //     title: "Deposits Paid",
            //     category: "Balance Sheet Parent Account",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .insertContent("Deposits Paid")
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Heading 1",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .setNode("heading", { level: 1 })
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Heading 2",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .setNode("heading", { level: 2 })
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Heading 3",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .setNode("heading", { level: 3 })
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Bulleted List",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .toggleBulletList()
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Numbered List",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .toggleOrderedList()
            //         .run();
            //     },
            //   },
            //   {
            //     title: "To-do List",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .toggleTaskList()
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Quote",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .toggleBlockquote()
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Divider",
            //     command: ({ editor, range }) => {
            //       editor
            //         .chain()
            //         .focus()
            //         .deleteRange(range)
            //         .setHorizontalRule()
            //         .run();
            //     },
            //   },
            //   {
            //     title: "Table",

            //     command: ({ editor, range }) => {
            //       editor.chain().focus().deleteRange(range).insertTable().run();
            //     },
            //   },
            //   {
            //     title: "Image",

            //     command: ({ editor, range }) => {
            //       // openImageResizableModal(range);
            //     },
            //   },
            //   {
            //     title: "Video",
            //     // isBasicVersion: isBasicVersion,
            //     command: ({ editor, range }) => {
            //       // openVideoResizableModal(range);
            //     },
            //   },

            // ],
            component: CommandsList,
          }),
        ],
        onCreate({ editor }) {
          // The editor is ready.
          console.log("On Create Editor", editor.getHTML());
        },
        onUpdate({ editor }) {
          const data = editor.getHTML();
          const dataInText = editor.getText();
          const dataInJSON = editor.getJSON();
          setIsEdited(true);
          console.log("Updated Editor", dataInJSON);

          contentUpdatedObj[editor?.options?.id] = {
            html: data,
            name:
              editor?.options?.name === "Notes" ||
              editor?.options?.name === "Text"
                ? dataInJSON?.content?.[0]?.content?.[0]?.text
                : editor?.options?.name,
          };
          setContentUpdatedObj(contentUpdatedObj);
          // if (setData) {
          //   setData(data);
          // }
        },
        onFocus({ editor }) {
          setSelectedEditor(editor);
        },
      },
      [isEditMode, accountObj]
    );
  };

  useEffect(() => {
    let updatedEditorList = [];
    // setEditorList([]);

    console.log("I am getting called 1");
    if (sections?.length && !editorList?.length) {
      let updatedList = [];
      sections?.forEach((section, index) => {
        let editorInt = editorList[index];
        if (!editorInt) {
          editorInt = createNewEditor();
        }
        let { from, to } = editorInt.state.selection;

        editorInt.setOptions({
          editable: false,
          id: sections?.[index]?.id,
          name: sections?.[index]?.data?.name,
          visibleIndex: section?.visibleIndex,
        });
        editorInt.extensionManager.extensions.find(
          (extension) => extension.name === "equation"
        ).options.HTMLAttributes.isReadOnly = true;
        editorInt.commands.setContent(sections?.[index]?.data?.html, false, {
          preserveWhitespace: "full",
        });

        editorInt.commands.setTextSelection({ from, to });
        updatedList.push(editorInt);
      });
      setEditorList([...updatedList]);
      // const promises = sections?.map(async (section) => {
      //   const sectionEditor = createNewEditor();

      //   let { from, to } = sectionEditor.state.selection;
      //   // if (!editorList?.length) {
      //   sectionEditor.setOptions({
      //     editable: false,
      //     id: section?.id,
      //     name: section?.data?.name,
      //     visibleIndex: section?.visibleIndex,
      //   });
      //   sectionEditor.extensionManager.extensions.find(
      //     (extension) => extension.name === "equation"
      //   ).options.HTMLAttributes.isReadOnly = true;
      //   const item = await api.get(
      //     `/report-generators/${section?.id}?mode=preview`
      //   );

      //   if (item) {
      //     console.log("Response Preview", item?.data?.data);
      //     sectionEditor.commands.setContent(item?.data?.data?.html, false, {
      //       preserveWhitespace: "full",
      //     });

      //     sectionEditor.commands.setTextSelection({ from, to });
      //     updatedEditorList.push(sectionEditor);
      //   }
      // });
      // Promise.all(promises).then((results) => {
      //   const finalUpdatedList = orderBy(
      //     updatedEditorList,
      //     ["options.visibleIndex"],
      //     ["asc"]
      //   );
      //   console.log("Promise Results", finalUpdatedList?.[0]?.getJSON());
      //   setEditorList([...finalUpdatedList]);
      // });
    }
  }, [sections?.length]);

  useEffect(() => {
    console.log("I am getting called 2");
    if (editorList?.length) {
      // if (isEditMode) {
      let updatedList = [];
      sections?.forEach((section, index) => {
        let editorInt = editorList[index];
        if (!editorInt) {
          editorInt = createNewEditor();
        }
        let { from, to } = editorInt.state.selection;

        editorInt.setOptions({
          editable: isEditMode ? true : false,
          id: sections?.[index]?.id,
          name: sections?.[index]?.data?.name,
          visibleIndex: section?.visibleIndex,
        });
        if (isEditMode) {
          editorInt.extensionManager.extensions.find(
            (extension) => extension.name === "equation"
          ).options.HTMLAttributes.isReadOnly = false;
        } else {
          editorInt.extensionManager.extensions.find(
            (extension) => extension.name === "equation"
          ).options.HTMLAttributes.isReadOnly = true;
        }

        console.log(
          "sections?.[index]?.data?.html",
          sections?.[index]?.data?.html
        );
        editorInt.commands.setContent(sections?.[index]?.data?.html, false, {
          preserveWhitespace: "full",
        });

        editorInt.commands.setTextSelection({ from, to });
        updatedList.push(editorInt);
      });
      setEditorList([...updatedList]);
      // }
      // else {
      //   let updatedList = [];
      //   sections?.forEach((section, index) => {
      //     let editorInt = editorList[index];
      //     if (!editorInt) {
      //       editorInt = createNewEditor();
      //     }
      //     let { from, to } = editorInt.state.selection;

      //     editorInt.setOptions({
      //       editable: false,
      //       id: sections?.[index]?.id,
      //       name: sections?.[index]?.data?.name,
      //       visibleIndex: section?.visibleIndex,
      //     });
      //     editorInt.extensionManager.extensions.find(
      //       (extension) => extension.name === "equation"
      //     ).options.HTMLAttributes.isReadOnly = true;
      //     editorInt.commands.setContent(sections?.[index]?.data?.html, false, {
      //       preserveWhitespace: "full",
      //     });

      //     editorInt.commands.setTextSelection({ from, to });
      //     updatedList.push(editorInt);
      //   });
      //   setEditorList([...updatedList]);

      //   // let updatedList = [];
      //   // const promises = sections?.map(async (section, index) => {
      //   //   let editorInt = editorList[index];
      //   //   if (!editorInt) {
      //   //     editorInt = createNewEditor();
      //   //   }

      //   //   let { from, to } = editorInt.state.selection;
      //   //   // if (!editorList?.length) {
      //   //   editorInt.setOptions({
      //   //     editable: false,
      //   //     id: section?.id,
      //   //     name: section?.data?.name,
      //   //     visibleIndex: section?.visibleIndex,
      //   //   });
      //   //   editorInt.extensionManager.extensions.find(
      //   //     (extension) => extension.name === "equation"
      //   //   ).options.HTMLAttributes.isReadOnly = true;
      //   //   const item = await api.get(
      //   //     `/report-generators/${section?.id}?mode=preview`
      //   //   );

      //   //   if (item) {
      //   //     console.log("Response Preview", item?.data?.data);
      //   //     editorInt.commands.setContent(item?.data?.data?.html, false, {
      //   //       preserveWhitespace: "full",
      //   //     });

      //   //     editorInt.commands.setTextSelection({ from, to });
      //   //     updatedList.push(editorInt);
      //   //   }
      //   // });
      //   // Promise.all(promises).then((results) => {
      //   //   const finalUpdatedList = orderBy(
      //   //     updatedList,
      //   //     ["options.visibleIndex"],
      //   //     ["asc"]
      //   //   );
      //   //   console.log("Promise Results", finalUpdatedList?.[0]?.getJSON());
      //   //   setEditorList([...finalUpdatedList]);
      //   // });
      // }
    }
  }, [isEditMode, sections?.length, JSON.stringify(sections)]);

  // useEffect(() => {
  //   if (isEditMode) {
  //     let editorList = [];
  //     if (sections?.length) {
  //       sections?.forEach((section) => {
  //         const sectionEditor = createNewEditor();
  //         let { from, to } = sectionEditor.state.selection;
  //         sectionEditor.setOptions({ editable: true });
  //         sectionEditor.extensionManager.extensions.find(
  //           (extension) => extension.name === "equation"
  //         ).options.HTMLAttributes.isReadOnly = false;
  //         sectionEditor.commands.setContent(section?.data?.html, false, {
  //           preserveWhitespace: "full",
  //         });
  //         sectionEditor.commands.setTextSelection({ from, to });

  //         editorList.push(sectionEditor);
  //         console.log("Section Editor123 Edit", sectionEditor.getJSON());
  //       });

  //       setEditorList([...editorList]);
  //     }
  //   }
  // }, [isEditMode]);

  // useEffect(() => {
  //   if (!editor) return;
  //   let { from, to } = editor.state.selection;
  //   if (isEditMode) {
  //     editor.setOptions({ editable: true });
  //     editor.extensionManager.extensions.find(
  //       (extension) => extension.name === "equation"
  //     ).options.HTMLAttributes.isReadOnly = false;
  //     editor.commands.setContent(data, false, {
  //       preserveWhitespace: "full",
  //     });
  //   } else {
  //     editor.setOptions({ editable: false });
  //     editor.extensionManager.extensions.find(
  //       (extension) => extension.name === "equation"
  //     ).options.HTMLAttributes.isReadOnly = true;
  //     editor.commands.setContent(previewData, false, {
  //       preserveWhitespace: "full",
  //     });
  //   }

  //   editor.commands.setTextSelection({ from, to });
  // }, [editor, data, isEditMode, previewData]);

  // const onChangesSave = (type, toggle) => {
  //   if (type === "switch" && !toggle && onSave && setData && previewData) {
  //     if (isEdited) {
  //       setOpenDiscard(true);
  //     } else {
  //       setIsEditMode(!isEditMode);
  //     }
  //   } else {
  //     setIsEditMode(!isEditMode);
  //     // setEditorList([]);
  //   }
  // };

  const onChangesSave = (type, toggle) => {
    if (type === "switch" && !toggle) {
      if (isEdited) {
        setOpenDiscard(true);
      } else {
        setIsEditMode(!isEditMode);
      }
    } else {
      setIsEditMode(!isEditMode);
    }
  };

  return (
    <>
      <div
        className={`editor-container ${isFull ? "editor-container--full" : ""}`}
      >
        <MenuBar
          preview={preview}
          editor={selectedEditor || editorList?.[0]}
          onPdf={onPdf}
          onWord={onWord}
          onChange={(type, toggle) => {
            onSwitch && onSwitch();
            // editor.setEditable(isEditMode);
            console.log("on change calling", toggle);
            onChangesSave(type, toggle);
          }}
          isFull={isFull}
          hideEquation={hideEquation}
          onChangeFull={() => {
            setIsFull(!isFull);
          }}
          isEditMode={isEditMode}
          onSave={onSave}
          data={data}
          type={type}
          isSaveButtonEnabled={isSaveButtonEnabled}
          hideSwitchBtn={hideSwitchBtn}
          saveLoading={saveLoading}
          setContentUpdatedObj={setContentUpdatedObj}
        />

        <div className="multi-instance-section-container">
          {editorList?.length !== 0 &&
            editorList?.map((editorInt, index) => {
              return (
                <div id={sections?.[index]?.id}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div style={{ flex: "1 1 " }}>
                      <span className="section-editor-label">
                        {sections?.[index]?.data?.name}
                      </span>
                    </div>

                    <TrashIcon
                      style={{ cursor: "pointer", marginRight: "10px" }}
                      onClick={() => {
                        setIsDeleteSectionDialogOpened(sections?.[index]?.id);
                      }}
                    />
                  </div>

                  <div
                    style={{
                      border: "1px dashed #ccc",
                      padding: "10px",
                      marginBottom: "20px",
                      marginTop: "5px",
                    }}
                  >
                    <EditorContent
                      className="multi-editor-content"
                      editor={editorInt}
                    />
                  </div>
                  {isDeleteSectionDialogOpened === sections?.[index]?.id && (
                    <DeleteTemplateItemModal
                      open={!!isDeleteSectionDialogOpened}
                      handleClose={() => {
                        setIsDeleteSectionDialogOpened("");
                      }}
                      deleteWarningModalName={`${sections?.[index]?.data?.name} section`}
                      confirmDelete={() => {
                        onDeleteSection(sections?.[index]?.id, index);
                        setIsDeleteSectionDialogOpened("");
                      }}
                    />
                  )}
                </div>
              );
            })}
        </div>
        {openDiscard && onSave && (
          <SaveDiscardModal
            open={isEditMode}
            onSave={() => {
              setOpenDiscard(false);
              setIsEdited(false);
              onSave(type, data, (response) => {
                onChangesSave(type, false);
              });
            }}
            handleClose={() => {
              setIsEdited(false);
              setData(previewData);
              setOpenDiscard(false);
              setIsEditMode(false);
            }}
          />
        )}
      </div>
      {/* {open && (
        <ShowEquationData open={open} setOpen={setOpen} equation="currency" />
      )} */}
    </>
  );
};

export default MultiInstanceEditor;
