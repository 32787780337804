import React, { useState, useEffect } from "react";
import {
  CaretDownOutlined,
  CaretUpOutlined,
  DownOutlined,
  HolderOutlined,
  UpOutlined,
} from "@ant-design/icons";
import DraftItem from "../../components/FinancialReport/DraftItem";
import RearrangeExpandableItem from "./RearrangeExpandedItem";
import { ReactComponent as DragIcon } from "../../assets/svg-icons/DragIcon.svg";
import { Center } from "devextreme-react/map";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import DraftItem from "./DraftItem";

const RearrangeExpandableGroup = ({ item, previewList, setPreviewData }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  console.log("Rearrange Item", item);

  // const onDragEnd = (e) => {
  //   console.log("On Drag End", e);
  //   let sourceIndex = e?.source?.index;
  //   let destinationIndex = e?.destination?.index;
  //   if (destinationIndex !== sourceIndex) {
  //     const copyList = [...item.children];
  //     if (sourceIndex > destinationIndex) {
  //       copyList.splice(destinationIndex, 0, item.children[sourceIndex]);
  //       copyList.splice(sourceIndex + 1, 1);
  //     } else if (sourceIndex < destinationIndex) {
  //       copyList.splice(destinationIndex + 1, 0, item.children[sourceIndex]);
  //       copyList.splice(sourceIndex, 1);
  //     }
  //     previewList.map((previewCategory) => {
  //       if (previewCategory?.id === item?.id) {
  //         previewCategory.children = copyList;
  //       }
  //     });

  //     console.log("Updated Preview List", previewList);
  //     setPreviewData([...previewList]);

  //   }
  // };
  return (
    <div>
      <div
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
        style={{
          display: "flex",
          alignItems: "center",
          cursor: "pointer",
          marginBottom: "15px",
          marginTop: "5px",
        }}
      >
        {isExpanded && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "3px",
            }}
          >
            <CaretUpOutlined style={{ width: "10px", height: "10px" }} />
          </div>
        )}
        {!isExpanded && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              paddingBottom: "3px",
            }}
          >
            <CaretDownOutlined style={{ width: "10px", height: "10px" }} />
          </div>
        )}
        <div style={{ display: "flex", rowGap: "15px", alignItems: "center" }}>
          <div
            style={{
              paddingLeft: "15px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <DragIcon />
          </div>
          <div style={{ paddingLeft: "15px", fontWeight: "bold" }}>
            {item?.name ? <div>{item?.name}</div> : <div>N/A</div>}
          </div>
        </div>
      </div>
      {isExpanded && (
        <>
          <Droppable
            droppableId={item?.id}
            direction="vertical"
            ignoreContainerClipping={true}
            type="subItems"
          >
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className="dropp-able-box"
              >
                {item?.children?.map((subItem, subIndex) => {
                  return (
                    <Draggable
                      key={subItem.id}
                      draggableId={subItem.id}
                      index={subIndex}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          item={JSON.stringify(subItem)}
                        >
                          <RearrangeExpandableItem item={subItem} />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              </div>
            )}
          </Droppable>
        </>
      )}
    </div>
  );
};

export default RearrangeExpandableGroup;
