import { useState } from "react";
import ComponentPopup from "../../atoms/ComponentPopup";
import {
  FileOutlined,
  EllipsisOutlined,
  CopyOutlined,
  CheckCircleFilled,
  DeleteOutlined,
} from "@ant-design/icons";

import PopupItem from "../../atoms/ComponentPopup/PopupItem";
import { ReactComponent as CopyIcon } from "../../assets/svg-icons/copyIcon.svg";
import SaveReportModal from "../../sub-components/SaveReportModal";
import {
  fetchReportTemplates,
  updateIsTemplateStatus,
} from "../../api-hooks/report";
import { useDispatch } from "react-redux";
import { setReportTemplates } from "../../store/report";
import { notification } from "antd";

const TemplateItem = ({ template, onSelect }) => {
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [isCopyReport, setIsCopyReport] = useState(false);
  const dispatch = useDispatch();
  return (
    <div className="report-template-item">
      <div
        style={{ flex: "1 1 auto" }}
        onClick={() => {
          onSelect(template?.id, template?.name);
        }}
      >
        {" "}
        {template?.name}
      </div>

      <ComponentPopup
        trigger={
          <div
            className={"report-template-item-menu"}
            onClick={() => {
              setIsPopupOpened(true);
            }}
          >
            <EllipsisOutlined />
          </div>
        }
        handleClose={() => {
          console.log("On Handle close");
          setIsPopupOpened(false);
        }}
        title="SELECT ACTION"
        open={isPopupOpened}
      >
        <div>
          <PopupItem
            name="Clone"
            Icon={CopyIcon}
            onClick={(e) => {
              setIsCopyReport(true);
              setIsPopupOpened(false);
            }}
          />

          <PopupItem
            name="Remove"
            Icon={DeleteOutlined}
            isDelete
            onClick={() => {
              updateIsTemplateStatus(
                {
                  id: template?.id,
                  isTemplate: false,
                },
                (response) => {
                  if (response?.id) {
                    notification.success({
                      message: "Template Unmarked Successfully!",
                    });
                    fetchReportTemplates((response) => {
                      if (response) {
                        dispatch(setReportTemplates(response));
                        setIsPopupOpened(false);
                      }
                    });
                  }
                }
              );
            }}
          />
        </div>
      </ComponentPopup>
      {isCopyReport && (
        <SaveReportModal
          open={isCopyReport}
          handleClose={() => {
            setIsCopyReport(false);
          }}
          selectedItem={{ ...template }}
        />
      )}
    </div>
  );
};

export default TemplateItem;
