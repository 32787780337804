import React, { useState } from "react";
import ComponentInput from "../../atoms/ComponentInput";
import Editor from "../../sub-components/Editor";
import "./NoteTemplate.less";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentDropdown from "../../atoms/ComponentDropdown";
// import

const NoteCreateNewTemplate = (props) => {
  const {
    newItemType,
    setNewSectionTitle,
    newSectionTitle,
    setNewVersion,
    newVersion,
    setNewTextData,
    newTextData,
    addNewNoteTemplate,
    closeCreateNewTemplate,
    isNewCreateSubSection,
    setNewSubSectionTitle,
    newSubSectionTitle,
    subSectionRequiredName,
    onAddSubSection,
    selectedIndustry,
    industries,
  } = props;
  console.log("subSectionRequiredName", subSectionRequiredName.name);

  const getIndustryDropdown = () => {
    let list = [];
    industries?.forEach((industry) => {
      list.push({
        label: industry?.name,
        value: industry?.id,
      });
    });
    return list;
  };
  return (
    <div style={{ width: "100%" }}>
      <div
        style={{ paddingTop: "32px", textTransform: "capitalize" }}
        className={"saved-cards-heading"}
      >
        {isNewCreateSubSection && <>Create {newItemType} Sub Section</>}
        {!isNewCreateSubSection && <>Create {newItemType}</>}
      </div>
      <div className="note-edit-template" style={{ paddingRight: "32px" }}>
        {!isNewCreateSubSection && (
          <>
            <div className="note-template-title">Industry</div>
            <ComponentDropdown
              disabled
              style={{ background: "#E6E8F0", color: "#C1C4D6", width: "100%" }}
              optionList={getIndustryDropdown()}
              value={selectedIndustry}
              // handleChange={(value) => {
              //   setSelectedTemplate(value);
              // }}
            />
          </>
        )}

        <div
          className="note-template-title"
          style={{ paddingTop: !isNewCreateSubSection ? "20px" : "0px" }}
        >
          Section
        </div>
        {isNewCreateSubSection && (
          // <ComponentInput
          //   placeholder="Section Title"
          //   value={subSectionRequiredName.name}
          //   style={{
          //     background: "#E6E8F0",
          //     color: "#C1C4D6",
          //   }}
          // />
          <ComponentDropdown
            disabled
            style={{ background: "#E6E8F0", color: "#C1C4D6", width: "100%" }}
            // optionList={getTemplatesDropdown()}
            value={subSectionRequiredName.name}
            // handleChange={(value) => {
            //   setSelectedTemplate(value);
            // }}
          />
        )}
        {!isNewCreateSubSection && (
          <ComponentInput
            placeholder="Section Title"
            value={newSectionTitle}
            onChange={(value) => {
              setNewSectionTitle(value);
            }}
          />
        )}
        {isNewCreateSubSection && (
          <>
            <div className="note-template-title" style={{ paddingTop: "20px" }}>
              Sub-Section
            </div>
            <ComponentInput
              placeholder="Section Title"
              value={newSubSectionTitle}
              onChange={(value) => {
                setNewSubSectionTitle(value);
              }}
            />
          </>
        )}
        <div className="note-template-title" style={{ paddingTop: "20px" }}>
          Main Body
        </div>
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          <ComponentInput
            placeholder="Enter Version"
            value={newVersion}
            onChange={(value) => {
              setNewVersion(value);
            }}
          />
        </div> */}
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "20px",
          }}
        >
          <span className="note-type">{newItemType}</span>
        </div>
        <Editor
          data={newTextData}
          setData={setNewTextData}
          hideSwitchBtn
          defaultEnable
          type="coverPage"
          hideEquation
          previewData={newTextData}
        />
      </div>

      <div
        className="footer-submit-section"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "32px",
        }}
      >
        <ComponentButton
          title="Cancel"
          onClick={() => {
            closeCreateNewTemplate();
          }}
          style={{
            background: "#ffffff",
            color: "#696F8C",
            border: "1px solid #D8DAE5",
          }}
        />
        {!isNewCreateSubSection && (
          <ComponentButton
            title="Confirm"
            onClick={() => {
              addNewNoteTemplate({
                name: newSectionTitle,
                type: newItemType,
                versionName: newVersion,
                newTextData: newTextData,
              });
            }}
          />
        )}
        {isNewCreateSubSection && (
          <ComponentButton
            title="Confirm"
            onClick={() => {
              onAddSubSection(
                subSectionRequiredName?.id,
                subSectionRequiredName,
                subSectionRequiredName?.index,
                {
                  name: newSubSectionTitle,
                  versionName: newVersion,
                  newTextData: newTextData,
                }
              );
            }}
          />
        )}
      </div>
    </div>
  );
};

export default NoteCreateNewTemplate;
