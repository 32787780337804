import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { fetchComapnyWithReportsHook } from "../../api-hooks/company";
import {
  createFinancialYearHook,
  deleteFinancialYearHook,
  fetchYearsHook,
} from "../../api-hooks/year";
import ComponentButton from "../../atoms/ComponentButton";
import ComponentDropdown from "../../atoms/ComponentDropdown";
import ComponentDropdownPopup from "../../atoms/ComponentDropdownPopup";
import ComponentSearch from "../../atoms/ComponentSearch";
import ContentCard from "../../atoms/ContentCard";

import { CREATE_FINANCIAL_REPORT_URL, useRouter } from "../../routes";
import { setCompaniesWithReports } from "../../store/company";
import { setYears } from "../../store/year";
import LeftControl from "../../sub-components/LeftControl";
import MainContainer from "../../sub-components/MainContainer";
import PreviousReportModal from "../../sub-components/PreviousReportModal";
import RightControl from "../../sub-components/RightControl";
import SaveReportModal from "../../sub-components/SaveReportModal";
import ExpandableGroup from "./ExpandableGroup";

import PreviewPDF from "../../sub-components/PreviewPDF";
import "./FinancialReport.less";
import {
  duplicateReportHook,
  fetchReportTemplates,
} from "../../api-hooks/report";
import { ReactComponent as PreviewPDFIcon } from "../../assets/svg-icons/previewIcon.svg";
import { Tooltip } from "antd";
import { changePdfCollapsedState } from "../../store/user";
import { updateFinancialYearHook } from "../../api-hooks/year";
import { ReactComponent as ExpandIcon } from "../../assets/svg-icons/expandIcon.svg";
import { ReactComponent as CollapseIcon } from "../../assets/svg-icons/collapseIcon.svg";
import { Spin } from "antd";
import ComponentTabs from "../../atoms/ComponentTabs";
import { setReportTemplates } from "../../store/report";
import TemplateItem from "./TemplateItem";
import DeleteTemplateItemModal from "../../sub-components/DeleteTemplateItemModal";

const getDropDown = (itemList) => {
  let list = [];
  list.push({
    name: "All",
    id: "all",
  });
  list.push(...itemList);
  return list;
};

const FinancialReport = ({}) => {
  const [selectedReport, setSelectedReport] = useState("");
  const dispatch = useDispatch();
  const router = useRouter();
  const history = useHistory();
  const [refreshKey, setRefreshKey] = useState("");
  const [isPreviousDialogOpened, setIsPreviousDialogOpened] = useState(false);
  const [isSaveReportModalOpened, setIsSaveReportModalOpened] = useState(false);
  const [selectedTab, setSelectedTab] = useState("my-report");
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const isCollapsed = useSelector((state) => state.user.isPdfCollapsed);
  const [selectedYear, setSelectedYear] = useState(null);
  const [showReportEdit, setShowReportEdit] = useState(true);
  const [companyData, setCompanyData] = useState([]);
  const [expandAll, setExpandAll] = useState(true);

  const years = useSelector((state) => state.year.years);

  const companiesWithReports = useSelector(
    (state) => state.company.companiesReports
  );

  const reports = useSelector((state) => state.report.reports);

  const reportTemplates = useSelector((state) => state.report.reportTemplates);
  const [deleteWarningModal, setDeleteWarningModal] = useState(false);
  const [type, setType] = useState();

  const onDuplicate = (reportId, name) => {
    console.log("Duplicate Report Id", reportId);
    duplicateReportHook({ reportId, name }, (response) => {
      console.log("Duplicate Report Response", response);
      history.push(`/financial-report/create?reportId=${response?.id}&step=1`);
    });

    // router.push(`/templateConfig?create=true&duplicateId=${reportName}`, {
    //   shallow: true,
    // });
  };
  const openDeleteWarningModal = (year) => {
    setType(year);
    setDeleteWarningModal(true);
  };
  const closeOpenWarningModal = () => {
    setDeleteWarningModal(false);
  };
  const deleteItemCalled = () => {
    onDeleteYear(type);
  };
  console.log("companyData", companyData);
  const onAddFinancialYear = (financialYear) => {
    if (financialYear && financialYear.trim() !== "") {
      createFinancialYearHook(financialYear, (response) => {
        const newYearList = [...years];
        newYearList.push(response);
        dispatch(setYears(newYearList));
      });
    }
  };

  useEffect(() => {
    fetchCompanyWithReports();
  }, []);

  const fetchCompanyWithReports = () => {
    fetchComapnyWithReportsHook((response) => {
      if (response) {
        dispatch(setCompaniesWithReports(response));
        setCompanyData(response);
      }
    });
    fetchReportTemplates((response) => {
      if (response) {
        dispatch(setReportTemplates(response));
      }
    });
  };

  useEffect(() => {
    if (years?.length && !selectedYear) {
      setSelectedYear({
        name: "All",
        id: "all",
      });
    }
  }, [years?.length]);

  const setIsCollapsed = (value) => {
    dispatch(changePdfCollapsedState(value));
  };

  const updateFiscalyear = (id, value, callback) => {
    // call patch function
    updateFinancialYearHook(
      {
        name: value,
        id: id,
      },
      (response) => {
        console.log("Equation Updated created", response);
        fetchYearsHook((allYearResponse) => {
          dispatch(setYears([...allYearResponse]));

          console.log("Response Year", response);
          if (selectedYear?.id === response?.id) {
            setSelectedYear(response);
          }
          callback();
        });
      }
    );
  };
  const onDeleteYear = (year) => {
    deleteFinancialYearHook(
      {
        id: year,
      },
      (response) => {
        fetchYearsHook((allYearResponse) => {
          dispatch(setYears([...allYearResponse]));
        });
      }
    );
    setDeleteWarningModal(false);
  };

  const onFilterByYear = (selectedYear) => {
    if (selectedYear && selectedYear?.id === "all") {
      setCompanyData([...companiesWithReports]);
    } else {
      const updatedList = [];
      companiesWithReports.map((item) => {
        const reports = item?.reports;
        const filterReport = reports.filter(
          (report) => report?.fiscalYear?.id === selectedYear?.id
        );
        updatedList.push({
          ...item,
          reports: filterReport,
        });
      });
      setCompanyData([...updatedList]);
    }
  };

  const onSearchData = (searchValue = "") => {
    if (searchValue) {
      const updatedList = [];
      companiesWithReports &&
        companiesWithReports?.map((item, index) => {
          if (item?.name?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
            return updatedList.push(item);
          }
          if (item?.reports) {
            let reportList = [];
            item?.reports?.map((subItems) => {
              if (
                subItems?.name
                  ?.toLowerCase()
                  ?.includes(searchValue?.toLowerCase())
              ) {
                reportList.push(subItems);
              }
            });
            if (reportList && reportList?.length > 0) {
              const itemData = {
                ...item,
                reports: reportList,
              };
              updatedList.push(itemData);
            }
          }
        });
      setCompanyData([...updatedList]);
    } else {
      setCompanyData(companiesWithReports);
    }
  };

  return (
    <>
      <MainContainer>
        <div className="finacial-report-container financial-height-setter">
          <LeftControl
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
            className="financial-design"
          >
            <ContentCard
              style={{ padding: 32, height: "100%" }}
              bodyStyle={{ padding: 0 }}
              size="large"
            >
              <div className={"saved-cards-heading"}>Saved Reports</div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                {/* <Link to={CREATE_FINANCIAL_REPORT_URL}> */}
                <ComponentButton
                  title="New Reports"
                  icon={<PlusOutlined />}
                  onClick={() => {
                    setIsSaveReportModalOpened(true);
                  }}
                  // onClick={() => {
                  //   router.push("/templateConfig?create=true", { shallow: true });
                  // }}
                />
                {/* </Link> */}

                {/* <ComponentButton
                title="Use Previous Reports"
                style={{ marginLeft: "24px" }}
                onClick={() => {
                  setIsPreviousDialogOpened(true);
                }}
              /> */}
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "29px",
                }}
                className="finacial-report-search-container"
              >
                <ComponentDropdownPopup
                  title="Select financial year"
                  onSelect={(item) => {
                    setSelectedYear(item);
                    onFilterByYear(item);
                  }}
                  selectedItem={selectedYear}
                  list={getDropDown(years)}
                  addText="Add as new financial year"
                  onDelete={openDeleteWarningModal}
                  onAdd={onAddFinancialYear}
                  showReportEdit={showReportEdit}
                  updateData={updateFiscalyear}
                />

                <ComponentSearch
                  placeholder="Search company or report name"
                  onSearch={(value, event) => {
                    onSearchData(value);
                  }}
                  onChange={(e) => {
                    onSearchData(e.target.value);
                  }}
                  allowClear
                  style={{ width: "100%", marginLeft: "15px" }}
                />
              </div>
              <div style={{ marginBottom: "12px", marginTop: "12px" }}>
                <ComponentTabs
                  tabList={[
                    {
                      label: "My Reports",
                      value: "my-report",
                    },
                    {
                      label: "Templates",
                      value: "templates",
                    },
                  ]}
                  selectedValue={selectedTab}
                  handleTabChange={(e) => {
                    setSelectedTab(e.target.value);
                  }}
                />
              </div>
              {selectedTab === "my-report" && (
                <>
                  <div
                    style={{
                      color: "#4bb354",
                      textAlign: "end",
                      marginTop: "12px",
                      marginBottom: "12px",
                    }}
                  >
                    {expandAll ? (
                      <div
                        onClick={() => {
                          setExpandAll(!expandAll);
                        }}
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          maxWidth: "max-content",
                          cursor: "pointer",
                          marginLeft: "auto",
                        }}
                      >
                        <CollapseIcon />
                        Collapse All
                      </div>
                    ) : (
                      <div
                        onClick={() => {
                          setExpandAll(!expandAll);
                        }}
                        style={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          maxWidth: "max-content",
                          cursor: "pointer",
                          marginLeft: "auto",
                        }}
                      >
                        <ExpandIcon />
                        Expand All
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      overflow: "auto",
                      height: "calc(100vh - 325px)",
                    }}
                  >
                    {companyData?.map((item, index) => {
                      const onSelect = (reportId, name) => {
                        setSelectedReport(name);
                        setRefreshKey(Math.random());
                        history.push(
                          `${router?.pathname}?reportId=${reportId}`
                        );
                      };
                      return (
                        <ExpandableGroup
                          item={item}
                          key={index}
                          onSelect={onSelect}
                          expandAll={expandAll}
                          fetchCompanyWithReports={fetchCompanyWithReports}
                        />
                      );
                    })}
                  </div>
                </>
              )}
              {selectedTab === "templates" && (
                <div>
                  {reportTemplates?.length > 0 &&
                    reportTemplates?.map((reportTemplate) => {
                      const onSelect = (reportId, name) => {
                        setSelectedReport(name);
                        setRefreshKey(Math.random());
                        history.push(
                          `${router?.pathname}?reportId=${reportId}`
                        );
                      };
                      return (
                        <TemplateItem
                          template={reportTemplate}
                          onSelect={onSelect}
                        />
                      );
                    })}
                </div>
              )}
            </ContentCard>
            {isPreviousDialogOpened && (
              <PreviousReportModal
                open={isPreviousDialogOpened}
                onDuplicate={onDuplicate}
                reportList={reports}
                handleClose={() => {
                  setIsPreviousDialogOpened(false);
                }}
              />
            )}
            {isSaveReportModalOpened && (
              <SaveReportModal
                open={isSaveReportModalOpened}
                handleClose={() => {
                  setIsSaveReportModalOpened(false);
                }}
                showReportEdit={showReportEdit}
                // duplicateId={duplicateId}
                // setReportList={setReportList}
                // reportList={reportList}
              />
            )}

            {/* <Card bordered={false} size="small" style={{ padding: 12, height: }}></Card> */}
          </LeftControl>
          <RightControl
            isPreview
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          >
            <ContentCard
              style={{ padding: "0px 32px 32px", height: "100%" }}
              bodyStyle={{
                padding: 0,
                height: "inherit",
                display: "flex",
                flexDirection: "column",
              }}
              size="large"
            >
              {selectedReport && (
                <div className={"report-preview-heading"}>
                  <div style={{ flex: "1 1 auto" }}>{selectedReport}</div>
                  <Tooltip placement="left" title="Collpase Preview">
                    <div
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setIsCollapsed(!isCollapsed);
                      }}
                    >
                      <PreviewPDFIcon
                        style={{ width: "16px", height: "16px" }}
                      />
                    </div>
                  </Tooltip>
                </div>
              )}
              {selectedReport && (
                <div style={{ height: "inherit" }}>
                  <PreviewPDF refreshKey={refreshKey} />
                </div>
              )}
              {!selectedReport && (
                <div
                  style={{
                    height: "inherit",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Select a report to preview
                </div>
              )}
            </ContentCard>
          </RightControl>
        </div>
        {/* {loader && (
        <div className="loader-spin">
          <Spin />
        </div>
      )} */}
      </MainContainer>
      {deleteWarningModal && (
        <DeleteTemplateItemModal
          open={deleteWarningModal}
          handleClose={closeOpenWarningModal}
          confirmDelete={deleteItemCalled}
          deleteWarningModalName={""}
        />
      )}
    </>
  );
};

export default FinancialReport;
