import ComponentModal from "../../atoms/ComponentModal";
import ComponentInput from "../../atoms/ComponentInput";

import "./DeleteReportModal.less";
import { deleteReportHook } from "../../api-hooks/report";
import { notification } from "antd";
import { fetchComapnyWithReportsHook } from "../../api-hooks/company";
import { setCompaniesWithReports } from "../../store/company";
import { useDispatch } from "react-redux";

const DeleteReportModal = ({
  open,
  handleClose,
  name,
  reportId,
  fetchCompanyWithReports,
}) => {
  const dispatch = useDispatch();
  const onDeleteReport = () => {
    deleteReportHook(
      {
        reportId,
      },
      (response) => {
        console.log("Report Deleted Successfully", response);
        notification.success({
          message: "Report Deleted Successfully",
        });
        fetchCompanyWithReports();
        handleClose();
      }
    );
  };
  return (
    <ComponentModal
      open={open}
      handleClose={handleClose}
      backText="Cancel"
      submitText="Confirm Delete"
      title="Are you sure you want to delete this report?"
      handleOk={onDeleteReport}
    >
      <div className={"saved-delete-report-text-title"}>
        “{name}” will be deleted and cannot be restored
      </div>
      <div className={"saved-delete-report-text-subtitle"}>
        <div style={{ marginBottom: "10px" }}>Type "delete" to confirm</div>
        <ComponentInput style={{ minWidth: "216px", width: "fit-content" }} />
      </div>
    </ComponentModal>
  );
};

export default DeleteReportModal;
